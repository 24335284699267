import React from 'react'
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table,Pagination } from 'reactstrap'

export const TableAgreement = (props) => {

    const {
        handleChangeRange, handleChangePercentage, handleDeleteRange, handleAddRange,
        list_agrement_test, handleDeleteTest, handleChangePrice,
        number_format,handleCant,check_sanitary,is_micro,
        goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
        prevPage, nextPage, totalRecords, resultCount,
       handleTest,id_agreement,handleDeleteTestCreate
    } = props;

    console.log(id_agreement);

    return (

        <Col sm="12">
            <Row>
                <Table striped className='table-sm'>
                <thead className="">
                    <tr className='border-bottom-info'>
                        <th >Código</th>
                        <th >Descripción</th>
                        <th >Precio Público</th>
                        {/* <th >Precio Mínimo de Venta</th> */}
                        <th ></th>
                        <th > Valido apartir de</th>
                        <th > </th>
                        {check_sanitary==true?<th ></th>:""}
                        <th></th>
                        <th >Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list_agrement_test.length > 0 && list_agrement_test.map(_test => {
                            if (_test.is_curve) {
                                return (
                                    <>
                                        <tr key={`aTrtestCurve-${_test.id_profile}`}>
                                            <td style={{ "vertical-align": "middle" }} rowSpan={_test.listCurves.length + 1}>
                                                {_test.code}
                                            </td>
                                            <td style={{ "vertical-align": "middle" }} colSpan={7}>
                                                <b>{_test.name}</b>
                                            </td>
                                            <td style={{ "vertical-align": "middle" }} rowSpan={_test.listCurves.length + 1}>
                                                <Button outline color='danger' className='btn btn-xs' onClick={() => id_agreement > 0 ?   (handleDeleteTest(_test.id_exam, _test.id_profile)) : (_test.id_exam, _test.id_profile)}>
                                                    <i className='icofont icofont-bin'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                        {
                                            _test.listCurves.length > 0 && _test.listCurves.map((_curve, _indexCurve) => {
                                                return (
                                                    <tr key={`curveTr-${_test.id_test}-subId-${_indexCurve}`} className="border-bottom-info">
                                                        <td style={{ "vertical-align": "middle" }}>
                                                            {_test.name + " "}
                                                            <br />
                                                            <b> {_curve.number_sampling + " " + " Tiempos"} </b>
                                                        </td>
                                                        <td style={{ "vertical-align": "middle" }}><b>${number_format(_curve.price, 2)}</b></td>
                                                        <td style={{ "vertical-align": "middle" }}><b>${number_format(_test.production_cost, 2)}</b></td>
                                                        <td style={{ "vertical-align": "middle" }} colSpan={4}>
                                                            {
                                                                _curve.listAgreementTestRanges.length > 0 && _curve.listAgreementTestRanges.map((_ranges, _indexRanges) => {
                                                                    return (
                                                                        <Row key={`trRange-${_indexRanges}`}>
                                                                            <Col md="2 p-1">
                                                                                <Label>Desde</Label>
                                                                                <Input type='number' autoComplete='off' name='min' value={_ranges.min}
                                                                                    className={_ranges.alertClass === "" ? 'form-control form-control-sm input-air-primary AgreementPicker' : 'form-control form-control-sm input-air-primary text-danger AgreementPicker'}
                                                                                    onChange={(e) => handleChangeRange(e, _ranges.id_range, _test.is_curve, null, _test.id_profile, _curve.id_profile_curve_price,)}
                                                                                />
                                                                            </Col>
                                                                            <Col md="2 p-1">
                                                                                <Label>Hasta</Label>
                                                                                <Input type='number' autoComplete='off' name='max' value={_ranges.max}
                                                                                    className={_ranges.alertClass === "" ? 'form-control form-control-sm input-air-primary AgreementPicker' : 'form-control form-control-sm input-air-primary text-danger AgreementPicker'}
                                                                                    onChange={(e) => handleChangeRange(e, _ranges.id_range, _test.is_curve, null, _test.id_profile, _curve.id_profile_curve_price,)}
                                                                                />
                                                                            </Col>
                                                                            <Col md="3 p-1">
                                                                                <Label>Aplicar descuento <b> <center>% </center>  </b></Label>
                                                                                <FormGroup className='p-1'>
                                                                                    <InputGroup size='sm'>
                                                                                        <Input type='number' autoComplete='off' name='percentage' value={_ranges.percentage}
                                                                                            className={_ranges.alertClass === "" ? "form-control form-control-sm input-air-primary AgreementDiscount" : "form-control form-control-sm input-air-primary text-danger AgreementDiscount"}
                                                                                            onChange={(e) => handleChangePercentage(e, _ranges.id_range, null, _test.id_profile, _curve.id_profile_curve_price)}
                                                                                        />
                                                                                        <InputGroupAddon addonType='prepend' onClick={() => handleDeleteRange(_ranges.id_range, _indexRanges, null, _test.id_profile, _curve.id_profile_curve_price)}>
                                                                                            <InputGroupText><i className="icofont icofont-ui-close danger cancellExam" ></i></InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                    </InputGroup>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2 p-1 text-center">
                                                                                <Label>Descuento</Label>
                                                                                <p className='AgreementLabel'>$ {number_format(_ranges.discountPesos, 2)}</p>
                                                                            </Col>
                                                                            <Col md="3 p-1">
                                                                                <Label>Valor de venta</Label>
                                                                                <FormGroup className='p-1'>
                                                                                    <br />
                                                                                    <InputGroup size='sm'>
                                                                                        <InputGroupAddon color='cancellExam2' className='cancellExam2' addonType='prepend'>
                                                                                            <InputGroupText>$</InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input type='number' autoComplete='off' className={_ranges.alertClass === "" ? "" : 'form-control form-control-sm input-air-primary text-danger AgreementDiscount'}
                                                                                            value={_ranges.sale} onChange={(e) => handleChangePrice(e, _ranges.id_range, null, _test.id_profile, _curve.id_profile_curve_price)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                            <Row>
                                                                <Col md="5 p-l-0">
                                                                    <Button className="btn btn-pill btn-xs" color="secondary" onClick={() => handleAddRange(null, _test.id_profile, _curve.id_profile_curve_price)}><i className="icofont icofont-plus"></i> Intervalo</Button>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <tr key={`aTestTr-${_test.id_test}`} className='border-bottom-info'>
                                            <td style={{ verticalAlign: "middle" }}>
                                                {_test.code}
                                            </td>
                                            <td style={{ verticalAlign: "middle" }}>
                                                {_test.name}
                                            </td>
                                            <td style={{ verticalAlign: "middle" }} className='text-center'>
                                                $ {number_format(_test.price, 2)}
                                            </td>
                                            <td style={{ verticalAlign: "middle" }} className='text-center'>
                                                $ {number_format(_test.production_cost, 2)}
                                            </td>
                                            <td style={{ "vertical-align": "middle" }} colSpan={4}>
                                                {
                                                    _test.listAgreementTestRanges.length > 0 && _test.listAgreementTestRanges.map((_range, _indRange) => {
                                                        return (
                                                            <Row key={`tdRow-${_range.id_range}`}>
                                                                <Col md="2 p-1">
                                                                    <Label>Desde</Label>
                                                                    <Input type="number" autoComplete='off'
                                                                        className={_range.alertClass === "" ? 'form-control form-control-sm input-air-primary AgreementPicker' : 'form-control form-control-sm input-air-primary text-danger AgreementPicker'}
                                                                        name="min" value={_range.min} onChange={(e) => handleChangeRange(e, _range.id_range, _test.is_curve, _test.id_exam, _test.id_profile, null)}
                                                                    />
                                                                </Col>
                                                                <Col md="2 p-1">
                                                                    <Label>Hasta</Label>
                                                                    <Input type="number" autoComplete='off'
                                                                        className={_range.alertClass === "" ? 'form-control form-control-sm input-air-primary AgreementPicker' : 'form-control form-control-sm input-air-primary text-danger AgreementPicker'}
                                                                        name="max" value={_range.max} onChange={(e) => handleChangeRange(e, _range.id_range, _test.is_curve, _test.id_exam, _test.id_profile, null)}
                                                                    />
                                                                </Col>
                                                                <Col md="3 p-1">
                                                                    <Label>Aplicar descuento <b> <center>% </center>  </b></Label>
                                                                    <FormGroup className='p-1'>
                                                                        <InputGroup size="sm">
                                                                            <Input type="number" autoComplete="off"
                                                                                className={_range.alertClass === "" ? "form-control form-control-sm input-air-primary AgreementDiscount" : "form-control form-control-sm input-air-primary text-danger AgreementDiscount"}
                                                                                name="percentage" value={_range.percentage} onChange={(e) => handleChangePercentage(e, _range.id_range, _test.id_exam, _test.id_profile, null)}
                                                                            />
                                                                            <InputGroupAddon addonType="prepend" onClick={() => handleDeleteRange(_range.id_range, _indRange, _test.id_exam, _test.id_profile, null)}>
                                                                                <InputGroupText><i className="icofont icofont-ui-close danger cancellExam" ></i></InputGroupText>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="2 p-1 text-center">
                                                                    <Label>Descuento</Label>
                                                                    <p className='AgreementLabel'>$ {number_format(_range.discountPesos, 2)}</p>
                                                                </Col>
                                                                <Col md="3 p-1">
                                                                    <Label>Valor de Venta </Label>
                                                                    <FormGroup className='p-1'> <br />
                                                                        <InputGroup size="sm">
                                                                            <InputGroupAddon color='cancellExam2 ' className='cancellExam' addonType="prepend" >
                                                                                <InputGroupText>$</InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input type="number" autoComplete="off" className={_range.alertClass === "" ? "" : "form-control form-control-sm input-air-primary text-danger AgreementDiscount"} name="sale"
                                                                                value={_range.sale} onChange={(e) => handleChangePrice(e, _range.id_range, _test.id_exam, _test.id_profile)}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                                <Row>
                                                    <Col md="5 p-l-0">
                                                        <Button className='btn btn-pill btn-xs' color='secondary' onClick={() => handleAddRange(_test.id_exam, _test.id_profile)}><i className='icofont icofont-plus'></i> Intervalo</Button>
                                                    </Col>
                                                </Row>
                                            </td>
                                            {is_micro==true?
                                            <td>
                                                <Col md="12 p-1">
                                                        <Label>Cantidad</Label>
                                                        <FormGroup className='p-1'>
                                                            <br />
                                                            <Input type='number' autoComplete='off' name='cant_sample' value={ _test.cant_sample}
                                                                min={0}
                                                                className='form-control form-control-sm input-air-primary'
                                                                onChange={(e) => handleCant(e,_test.id_exam, _test.id_profile)}
                                                            />
                                                        </FormGroup>
                                                </Col>
                                            </td>:""}
                                            
                                            <td style={{ verticalAlign: "middle" }}>
                                                <Button outline color='danger' className='btn btn-xs' onClick={() => id_agreement > 0 ? (handleTest(_test.id_agreement_test)) : (handleDeleteTestCreate(_test.id_exam, _test.id_profile))}>
                                                    <i className='icofont icofont-bin'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }
                        })
                    }
                </tbody>
            </Table>
            <Table>
                <tbody>
                    <tr>
                        <td style={{ width: "300px" }}>
                            <p>
                                Mostrando { actualPage } a { resultCount } de { totalRecords } resultados
                            </p>
                        </td>
                        <td>
                            <Pagination aria-label='...' style={{ justifyContent: "center" }}>
                                <ul className='pagination pagination-primary'>
                                    {
                                        actualPage === 1 ?
                                            <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                            : <li style={{ cursor: "pointer" }} className='page-item' onClick={goPreviousPage} ><span className='page-link'>Anterior</span></li>
                                    }
                                    {
                                        actualPage > 1 &&
                                        <>
                                            <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goPreviousPage(1)} ><span className='page-link'>1</span></li>
                                            {
                                                actualPage > 2 && 
                                                <>
                                                    <li className="page-item"><span className='page-link'>...</span></li>
                                                    <li style={{ cursor: "pointer" }} onClick={() => goToPage(actualPage -1)} className='page-item'><span className='page-link'>{ actualPage - 1 }</span></li>
                                                </>
                                            }
                                        </>
                                    }
                                    <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{actualPage}</span></li>
                                    {
                                        actualPage < totalPages && 
                                        <>
                                            {
                                                actualPage < (totalPages - 1) && 
                                                <>
                                                    <li style={{ cursor:'pointer' }} onClick={() => goToPage(actualPage + 1)} className='page-item'><span className='page-link'>{ actualPage + 1 }</span></li>
                                                    <li className='page-item'><span className='page-link'>...</span></li>
                                                </>
                                            }
                                            <li style={{ cursor: 'pointer' }} onClick={() => goToPage(totalPages)} className='page-item'><span className='page-link'>{ totalPages }</span></li>
                                        </>
                                    }
                                    {
                                        totalPages === actualPage ?
                                            <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                            :<li style={{ cursor: "pointer" }} className='page-item primary' onClick={goNextPage}><span className='page-link primary'>Siguiente</span></li>
                                    }
                                </ul>
                            </Pagination>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Row>
    </Col> 
    )
}

import React, { Fragment, useState } from 'react';
import { Input, Card, CardBody, Container, Row, Col, Button, FormGroup, Label, 
List, Table, Badge, CardHeader, Collapse, Form, Tooltip,ButtonGroup } from 'reactstrap';
import { RibbonDayWork } from '../../../constant';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import Select from 'react-select';
import { useWorkOrderDay } from '../../../hooks/workOrderDay/useWorkOrderDay';
import { ModalViewWorkOrder } from '../../../components/ViewWorkOrder/ModalViewWorkOrder';
import { ModalCheckSpecimen } from '../../../components/ViewWorkOrder/ModalCheckSpecimen';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import '../../../hooks/componentMultiSelect/MultiSelectDemo.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { MultiSelect } from 'primereact/multiselect';
import 'animate.css';
import { ModalTrazabilidadSample } from './ModalTrazabilidadSample';
import './styleWorkDay.css';
import { CadenaMicrosanitaria } from './CadenaMicrosanitaria';




export const IndexWorkOrderDay = () => {

    const {
        totalWorkOrderUrgent, totalWorkOrderCritic, totalWorkOrderNormal, totalWorkOrder, changeCheckbox, selectedSections, sectiondefault,
        mDateRange1, mDateRange2, mSelectBranch, nim,
        branches,
        handleInputChange, handleSelectValues, handleListExam,
        getWorkOrders, loading,
        workOrders,
        modal, modalTitle, method, toggle,
        listExam, listProfile,
        handlePickerValues,
        modalCheckSpecimen, toggleCheckSpecimen, handleModalCheckSpecimen, loadingSpecimen,
        navigateConfigPrices, navigateCaptureResults,
        navigateUpdate, printSticker, printTicket,
        changeFiltersRangeNim, getNimsFromRange,
        viewFormFilters, handleViewFilters,
        treeviewRef, itemsTree, onRenderItem, changeTree, savePendingMuestra, enable_tracking_toma,
        itemsTree2, treeviewRef2, handleModalRefusedSample, onRenderItem2, changeTreeRefused,
        enableViewTest, handleCloseViewTest, saveRefusedSample, validationsSG, validationsS,
        handleSelectValuesR, id_return_reason, reason, name_patient, gender,
        //trazabilidad sample
        modalFullRef, modalProps, close, handleTrazabilidad,
        dataSample, dataProcess2, dataProcess3, dataTrackingReception,
        namePatient, idGender, dataSection, dataID,
        //status
        handleSelectValuesPrinter, statusOrder, mSelectStatus,
        resetLocalStorage,printStickerMicro,
                //type microsanitaria
                typeModalMicro,modalMicro,loadingMicro,toggleCadena,handleCadenaMicro,
                arrayMic,micDetail,dataMic,exportToExcel,
                listExams,mExam,
        //*type service
        mSelectTypeService,dataTypeService

    } = useWorkOrderDay();

    const [filters, setFilters] = useState(true);
    const [filterDate, setFilterDate] = useState(false);
    const [filtersRangeNim, setFiltersRangeNim] = useState(false);
    const [myButtonValue, setMyButtonValue] = useState("");
    let validHopitalInterfaced = localStorage.getItem('interfaced');

    console.log(validHopitalInterfaced);
    console.log("gaurdas algo");


    const txtsection = localStorage.getItem('TextAS');


    const changeFilters = (typeFilter) => {
        switch (typeFilter) {
            case "date":
                setFilters(!filters);
                setFilterDate(false);
                setFiltersRangeNim(false);
                break;
            case "search":
                setFilters(false);
                setFilterDate(!filterDate);
                setFiltersRangeNim(false);

                break;
            case "range":
                setFilters(false);
                setFilterDate(false);
                setFiltersRangeNim(!filtersRangeNim);

                break;
            default:
                break;
        }
    }

    return (
        <Fragment>
            <Container fluid={true} className='p-t-0 p-b-0 p-r-0' >  
                <Row>
                    <Col      className={!viewFormFilters ? "animate__animated animate__slideOutLeft d-none p-t-0 p-b-0" : "default-according style-1 faq-accordion job-accordion position-relative p-t-0 p-b-0"}
  id="accordionoc" xs="12" sm="6" md="6" lg="3" xl="3"  >
                        <Row className="position-sticky" style={{ top: 140 }}>
                            <Col xl="12">
                                <Card>
                                    <CardHeader className='bg-primary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("date")}
                                                data-target="#collapseicon" aria-expanded={filters} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i> &nbsp; &nbsp; &nbsp; &nbsp;<label className='labelFilter'>Filtros de busqueda</label></Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filters}>
                                        <CardBody className="p-2 p-t-0">
                                            <Form onSubmit={(e) => getWorkOrders(e, false, true)}>
                                                <Row>
                                                    <Col md="12" className="p-initial">
                                                        <Label className="labelFilter">Fecha de admisión</Label>
                                                        <Row className="m-0">
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="6" className='p-l-0 '>
                                                                <FormGroup className="labelFilter">
                                                                    <Datetime
                                                                        inputProps={{
                                                                            placeholder: 'YYYY-MM-DD',
                                                                            name: "workFrom",
                                                                            autoComplete: "false",
                                                                            className: "form-control digits input-air-primary form-control-sm ",
                                                                        }}
                                                                        timeFormat={false}
                                                                        dateFormat="YYYY-MM-DD"
                                                                        value={mDateRange1}
                                                                        locale="es"
                                                                        initialValue={new Date()}
                                                                        onChange={(e) => handlePickerValues(e, "mDateRange1")}
                                                                        closeOnSelect={true}
                                                                        
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="6" className='p-l-0 '>
                                                                <FormGroup >
                                                                    <Datetime
                                                                        inputProps={{
                                                                            placeholder: 'YYYY-MM-DD',
                                                                            name: "workFrom",
                                                                            autoComplete: "false",
                                                                            className: "form-control digits input-air-primary form-control-sm ",
                                                                            size: "sm"
                                                                        }}
                                                                        timeFormat={false}
                                                                        dateFormat={"YYYY-MM-DD"}
                                                                        locale="es"
                                                                        value={mDateRange2}
                                                                        initialValue={new Date()}
                                                                        onChange={(e) => handlePickerValues(e, "mDateRange2")}
                                                                        closeOnSelect={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* no mover este imput por la clase que lleva para que sea visible y tranparente en las cards*/}
                                                            <Datetime
                                                                inputProps={{
                                                                    placeholder: 'YYYY-MM-DD',
                                                                    name: "workFrom",
                                                                    autoComplete: "false",
                                                                    className: "form-control digits input-air-primary form-control-sm ",
                                                                    size: "sm"
                                                                }}
                                                                timeFormat={false}
                                                                dateFormat={"YYYY-MM-DD"}
                                                                locale="es"
                                                                initialValue={new Date()}
                                                                closeOnSelect={true}
                                                                className="rdtPicker"
                                                            />
                                                        </Row>
                                                    </Col>
                                                    {
                                                          validHopitalInterfaced === "True" &&<Col sm="12" md="12" lg="12">
                                                          <FormGroup>
                                                              <Label className='col-form-label'>Tipo de servicio</Label>
                                                              <Select
                                                                  name="mSelectTypeService"
                                                                  value={mSelectTypeService}
                                                                  isClearable={true}
                                                                  placeholder="Selecciona una opción"
                                                                  options={dataTypeService}
                                                                  className="input-air-primary input-sm"
                                                                  onChange={(e) => handleSelectValues(e, "mSelectTypeService")}
                                                              />
                                                          </FormGroup>
                                                      </Col>
                                                    }
                                                    <Col className="m-0">   
                                                            <Label className=" labelFilter">Secciones</Label>
                                                            <MultiSelect
                                                                className="basic-multi-select input-air-primary form-control-sm "
                                                                value={selectedSections}
                                                                options={sectiondefault}
                                                                onChange={(e) => changeCheckbox(e.value)}
                                                                optionLabel="name"
                                                                placeholder={"Selecciona una " + txtsection}
                                                                maxSelectedLabels={3}
                                                                selectedItemsLabel="Secciones Agregadas"
                                                            />
                                                        
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>Exámanes</Label>
                                                            <Select
                                                                name="mExam"
                                                                value={mExam}
                                                                isClearable={true}
                                                                placeholder="Selecciona una opción"
                                                                options={listExams}
                                                                className="input-air-primary input-sm"
                                                                onChange={(e) => handleSelectValues(e, "mExam")}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12 m-0">
                                                        <Label className="labelFilter ">Sucursales<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                        <FormGroup>
                                                            <Select
                                                                name="mSelectBranch"
                                                                isClearable={true}
                                                                placeholder="Selecciona una sucursal"
                                                                noOptionsMessage="Sin resultados"
                                                                options={branches}
                                                                onChange={(e) => handleSelectValues(e, 'mSelectBranch')}
                                                                value={mSelectBranch}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                  

                                                    <Col sm="12" md="12" lg="12">
                                                        <Label className="labelFilter ">Estatus</Label>
                                                        <Select
                                                            name="mSelectStatus"
                                                            isClearable={true}
                                                            placeholder="Selecciona un estatus"
                                                            noOptionsMessage="Sin resultados"
                                                            options={statusOrder}
                                                            onChange={(e) => handleSelectValues(e, 'mSelectStatus')}
                                                            value={mSelectStatus}
                                                            className="b-r-9"
                                                        />
                                                    </Col>
                                                    <Col>

                                                    </Col>
                                                    <Col md="12">
                                                    <Button
                                                            type="submit"
                                                            outline
                                                            color="primary"
                                                            onClick={(e) => getWorkOrders(e, false, true, "Filtrar")}
                                                            className={loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1"}
                                                        >
                                                            Filtrar
                                                        </Button>
                                                        <Button type="button" onClick={resetLocalStorage} outline  color='danger' className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")}>
                                                            Restablecer
                                                            </Button>
                                                        <Button
                                                            type="submit"
                                                            outline
                                                            color="success"
                                                            onClick={(e) => getWorkOrders(e, false, true, "Interfazados")}
                                                            className={loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1"}
                                                        >
                                                            Interfazados
                                                        </Button>
                                                     
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("range")}
                                                data-target="#collapseicon" aria-expanded={filtersRangeNim} aria-controls="collapseicon"><i className="fa fa-filter fIconFilter"></i>&nbsp; &nbsp; &nbsp; &nbsp; <label className='labelNim'>Filtro de rango de nims</label>   </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filtersRangeNim}>
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='labelIndications' >Fecha de admisión<span className='f-16' style={{ color: "#0079C7" }}>*</span></label>
                                                    <FormGroup className="">
                                                        <Datetime
                                                            inputProps={{
                                                                placeholder: 'YYYY-MM-DD',
                                                                name: "workFrom",
                                                                autoComplete: "false",
                                                                className: "form-control digits input-air-primary form-control",
                                                            }}
                                                            timeFormat={false}
                                                            dateFormat="YYYY-MM-DD"
                                                            // value={mDateRange1}
                                                            locale="es"
                                                            initialValue={new Date()}
                                                            onChange={(e) => changeFiltersRangeNim(e, "date_range_nim")}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Label className="labelFilter">Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Select
                                                            name="mSelectBranch"
                                                            isClearable={true}
                                                            placeholder="Selecciona una sucursal"
                                                            noOptionsMessage="Sin resultados"
                                                            options={branches}
                                                            onChange={(e) => changeFiltersRangeNim(e, 'branch_range_nim')}
                                                        // value={mSelectBranch}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                                                    <Label className="labelFilter">Inicio<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Input type='number' className='form-control form-control-sm input-air-primary'
                                                            name="init_range_nim"
                                                            // value={nim}
                                                            onChange={(e) => changeFiltersRangeNim(e.target.value, "init_range_nim")} />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                                                    <Label className="labelFilter">Fin<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                    <FormGroup>
                                                        <Input type='number' className='form-control form-control-sm input-air-primary'
                                                            name="end_range_nim"
                                                            // value={nim}
                                                            onChange={(e) => changeFiltersRangeNim(e.target.value, "end_range_nim")} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Button type="button" onClick={getNimsFromRange} outline color="primary" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} > Buscar</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card>
                                    <CardHeader className='bg-secondary'>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => changeFilters("search")}
                                                data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon"><i className="icon-search fIcon"></i>&nbsp; &nbsp; &nbsp; &nbsp; <label className='labelNim'>Busqueda por NIM o Nombre</label>   </Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filterDate}>
                                        <CardBody className="p-3">
                                            <Form onSubmit={(e) => getWorkOrders(e, true)}>
                                                <Row>
                                                    <Col md="12 m-0">
                                                        <FormGroup className="col-md-12">
                                                            <Label className="col-form-label">Ingresa el NIM  o Nombre a buscar<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>

                                                            <Input className='form-control form-control-sm input-air-primary' name="nim" value={nim} onChange={handleInputChange} />
                                                        </FormGroup>
                                                        <Col md="12">
                                                            <Button type="submit" outline color="primary" className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} > Buscar</Button>
                                                        </Col>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="12" md={!viewFormFilters ? "12" : "9"} lg={!viewFormFilters ? "12" : "9"} >
                     
                        <Row>
                            <Col sm="12" md="12" xl="12">
                                <Card className=''>
                                    <CardBody className="p-1">
                                        <Row>
                                     <Col sm="3" className='p-l-0 p-r-0'>
                                    <div className='ecommerce-widgets media '>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2"> <br />
                                            <div className='ecommerce-box light-bg-primary' >
                                                <img src={require("../../../assets/images/loginHelenLabs/WorkOrderDay/AdmisionesCriticas.svg")} style={{ width: "50%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
                                            <label className='labelIndications f-16'>Críticas</label> <br />
                                            <label className='f-20 txt-danger'>{totalWorkOrderCritic}</label><br />
                                        </Col>
                                    </div>
                                </Col>
                                <Col sm="3" className='p-l-0 p-r-0' >
                                    <div className='ecommerce-widgets media'>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2"> <br />
                                            <div className='ecommerce-box light-bg-primary' >
                                                <img src={require("../../../assets/images/loginHelenLabs/WorkOrderDay/AdmisionesUrgentes.svg")} style={{ width: "50%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
                                            <label className='labelIndications f-16'>Urgentes</label> <br />
                                            <label className='f-20 txt-warning'>{totalWorkOrderUrgent}</label><br />
                                        </Col>
                                    </div>
                                </Col>
                                <Col sm="3" className='p-l-0 p-r-0'>
                                    <div className='ecommerce-widgets media'>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2"> <br />
                                            <div className='ecommerce-box light-bg-primary' >
                                                <img src={require("../../../assets/images/loginHelenLabs/WorkOrderDay/AdmisionesNormales.svg")} style={{ width: "50%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
                                            <label className='labelIndications f-16'>Normales</label> <br />
                                            <label className='f-20 txt-primary'>{totalWorkOrderNormal}</label><br />
                                        </Col>
                                    </div>
                                </Col>
                                <Col sm="3" className='p-l-0 p-r-0' >
                                    <div className='ecommerce-widgets media'>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2"> <br />
                                            <div className='ecommerce-box light-bg-primary' >
                                                <img src={require("../../../assets/images/loginHelenLabs/WorkOrderDay/AdmisionesCriticas.svg")} style={{ filter: "invert(100%) sepia(100%) saturate(1000%) hue-rotate(186deg)", width: "50%" }} alt="" />
                                            </div>
                                        </Col>
                                        <Col sm="8" style={{ textAlign: "-webkit-center" }} > <br />
                                            <label className='labelIndications f-16'>Total de Admisiones</label> <br />
                                            <label className='f-20 txt-secondary'>{totalWorkOrder}</label><br />
                                        </Col>
                                    </div>
                                </Col>

                            </Row>
                                        

                                        <Row>
                                            <Col md="12"   >
                                                <div className="table-responsive">
                                                    {
                                                        workOrders.length === undefined
                                                            ?
                                                            <div style={{ textAlign: "-webkit-center" }}>
                                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No se ha registrado una Orden de Trabajo aún.</p>
                                                            </div>
                                                            :
                                                            <Table striped >
                                                                <thead className=" text-center">
                                                                    <tr className='border-bottom-info'>
                                                                        <th scope="col" >#</th>
                                                                        <th scope="col" >NIM</th>
                                                                        <th scope="col" >F.admisión</th>
                                                                        <th scope="col" >{txtsection}</th>
                                                                        <th scope="col" >Paciente</th>
                                                                        <th scope="col" >Sucursal</th>
                                                                        <th scope="col" >Tipo de ingreso</th>
                                                                        <th scope="col" className={enable_tracking_toma == true || enable_tracking_toma == "True" ? "d-none" : "d-none"}  >Trazabilidad</th>
                                                                        <th scope="col" >Muestras</th>
                                                                        <th scope="col" >E.T</th>
                                                                        <th scope="col" >C.C</th>
                                                                        <th scope="col" >Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="f-13 text-center">
                                                                    {
                                                                        loading === true
                                                                            ?
                                                                            <tr>
                                                                                <td colSpan={10} className="text-center">
                                                                                    <Col md="12" className='text-center'>
                                                                                        <Row className='mt-2'>
                                                                                            <Col sm="12" md="12" lg="12" className='text-center'>
                                                                                                <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className='mt-2'>
                                                                                            <Col sm="3" md="3" lg="4" className='text-center'></Col>
                                                                                            <Col sm="2" md="2" lg="2" className='text-left p-r-0'> <br />
                                                                                                <p className='f-14 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
                                                                                            </Col>
                                                                                            <Col sm="1" md="1" lg="1" className=' p-l-0'>
                                                                                                <br />
                                                                                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
                                                                                            </Col>
                                                                                            <Col sm="1" md="1" lg="1" className=' p-l-0'>
                                                                                                <br />
                                                                                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-12"></i></p>
                                                                                            </Col>
                                                                                            <Col sm="1" md="1" lg="1" className='p-l-0'>
                                                                                                <br />
                                                                                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
                                                                                            </Col>
                                                                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                                                        </Row>
                                                                                    </Col>

                                                                                </td>
                                                                            </tr>
                                                                            :
                                                                            workOrders.length >= 1 &&
                                                                            workOrders.map((item, index) => {
                                                                                console.log("info",item);
                                                                                return (
                                                                                    <tr key={index} className="border-bottom-info">
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">{(index + 1)}</td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            <Button color={`${item._critical === 1 ? "danger" : (item._urgent === 1 ? "warning" : "info")}`} className={`btn btn-pill btn-xs`}
                                                                                               // disabled={item.id_status === 20 ? true : false}
                                                                                                id={`tooltipRelaese-${item.id_work_order[index]}`}   >
                                                                                                <h6
                                                                                                    onClick={item.id_status !== 20 ? () => navigateCaptureResults(item.nim) : ''}
                                                                                                    className="m-0 f-w-100"
                                                                                                >
                                                                                                    <b>{item.nim}</b>
                                                                                                </h6>
                                                                                            </Button>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            <div  >
                                                                                                {item.admision_date}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            <div >
                                                                                                <List type="unstyled"  >
                                                                                                    {
                                                                                                        item.listSections.length >= 1 &&
                                                                                                        item.listSections.map((item, index) => {
                                                                                                            return (
                                                                                                                <li key={index}>{item.name_section}</li>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </List>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            <div >
                                                                                                {item.is_micro === true ? "N/A" : item.name_patient}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                                                                            <div >
                                                                                                {item.name_branch}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            <div >
                                                                                                {item.income_type}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className={enable_tracking_toma == "True" ? "d-none " : "d-none"}  >
                                                                                            <div >
                                                                                                <button
                                                                                                    id={`tooltip-navigates-${index}`}
                                                                                                    color=""
                                                                                                    className="f-12 pointer btnTrazabilidad"
                                                                                                    ref={modalFullRef}
                                                                                                    onClick={() => handleTrazabilidad(item.id_work_order, item.nim, item.name_patient, item.gender)}
                                                                                                >
                                                                                                    <i className="fa fa-truck iconEdit"></i>
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-navigates-${index}`}
                                                                                                        dataText={'Trazabilidad'}
                                                                                                    />
                                                                                                </button>
                                                                                            </div>

                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle" >
                                                                                            <div className={item.id_status === 20 ? "d-none" : ""}>
                                                                                                <Button
                                                                                                    id={`tooltip-specimen-${index}`}
                                                                                                    color={`${item._wanting === true ? "danger" : "info"}`}
                                                                                                    onClick={() => handleModalCheckSpecimen(item.id_work_order, item.name_patient, item.gender)}
                                                                                                    className="btn btn-danger btn-xs">
                                                                                                    <i className="icofont icofont-warning"></i>&nbsp;
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-specimen-${index}`}
                                                                                                        dataText={'Muestras'}
                                                                                                    />
                                                                                                    {item.cont_check_specimen}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            <div className={item.id_status === 20 ? "d-none" : ""}>
                                                                                                <Badge
                                                                                                    id={`tooltip-list-${index}`}
                                                                                                    color="secondary"
                                                                                                    style={{ width: "35px" }}
                                                                                                    onClick={() => handleListExam(item.nim, item.id_brach, item.id_work_order)}
                                                                                                ><i className="fa fa-flask f-12"></i>
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-list-${index}`}
                                                                                                        dataText={'Pendientes'}
                                                                                                    />
                                                                                                    {/* &nbsp; {item.listExam.length + item.listProfile.length}</Badge> */}
                                                                                                </Badge>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            {
                                                                                                item.is_micro === true ? 
                                                                                                <Badge
                                                                                                id={`tooltip-Mic-${index}`}
                                                                                                color="success"
                                                                                                style={{ width: "35px" }}
                                                                                                onClick={() => handleCadenaMicro(item.nim, item.id_brach, item.id_work_order)}

                                                                                            ><i className="icofont icofont-eye-alt"></i>
                                                                                                <ToolTips
                                                                                                    placement="top"
                                                                                                    dataTarget={`tooltip-Mic-${index}`}
                                                                                                    dataText={'  Cadena de Custodia'}
                                                                                                />
                                                                                            
                                                                                            </Badge>
                                                                                            :
                                                                                            ""
                                                                                                
                                                                                            }
                                                                                      
                                                                                      </td>
                                                                                        <td className="pt-1 pb-1 pl-2 pr-2 align-middle">
                                                                                            {
                                                                                                item.id_status === 20 ? 
                                                                                                <div>
                                                                                                      <Badge style={{ width: "80px" }} color='danger'>Cancelada</Badge> 

                                                                                                </div>
                                                                                                :
                                                                                                <div >
                                                                                                <Badge
                                                                                                    id={`tooltip-reentry-${index}`}
                                                                                                    color=""
                                                                                                    style={{ backgroundColor: "#01839d" }}
                                                                                                    className='f-12 pointer'
                                                                                                    onClick={() => navigateUpdate(item.id_work_order)}
                                                                                                >
                                                                                                    <i className="fa fa-pencil-square-o iconEdit"></i>
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-reentry-${index}`}
                                                                                                        dataText="Editar ingreso"
                                                                                                    />
                                                                                                </Badge>
                                                                                                <Badge
                                                                                                    id={`tooltip-ticket-${index}`}
                                                                                                    color="secondary"
                                                                                                    className='f-12 pointer'
                                                                                                    onClick={() => printTicket(item.id_work_order)}
                                                                                                >
                                                                                                    <i className="fa fa-ticket"></i>
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-ticket-${index}`}
                                                                                                        dataText="Reimprimir ticket"
                                                                                                    />
                                                                                                </Badge>
                                                                                                <Badge
                                                                                                    id={`tooltip-sticker-${index}`}
                                                                                                    color=""
                                                                                                    style={{ backgroundColor: "#016392" }}
                                                                                                    className='f-12 pointer'
                                                                                                    onClick={item.is_micro==true?() => printStickerMicro(item.id_work_order):() => printSticker(item.id_work_order)}
                                                                                                >
                                                                                                    <i className="fa fa-barcode iconEdit"></i>
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-sticker-${index}`}
                                                                                                        dataText="Etiquetas"
                                                                                                    />
                                                                                                </Badge>
                                                                                                <Badge
                                                                                                    id={`tooltip-navigate-${index}`}
                                                                                                    color=""
                                                                                                    style={{ backgroundColor: "#059bb4" }}
                                                                                                    className="f-12 pointer"
                                                                                                    onClick={() => navigateConfigPrices(item.id_work_order)}>
                                                                                                    <i className="icofont icofont-arrow-right iconEdit"></i>
                                                                                                    <ToolTips
                                                                                                        placement="top"
                                                                                                        dataTarget={`tooltip-navigate-${index}`}
                                                                                                        dataText={'Detalle de la Orden'}
                                                                                                    />
                                                                                                </Badge>
                                                                                            </div>

                                                                                            }
                                                                                           
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                    }
                                                                </tbody>
                                                            </Table>

                                                    }
                                                    <br /> <br />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                    
                </Row>
            </Container> <br /> <br /> <br />
            <ModalViewWorkOrder
                {
                ...{
                    modal, toggle, modalTitle, method,
                    listExam, listProfile
                }
                }
            />

            <ModalCheckSpecimen
                {
                ...{
                    modalCheckSpecimen, toggleCheckSpecimen, loadingSpecimen,
                    treeviewRef, itemsTree, onRenderItem, changeTree, savePendingMuestra, enable_tracking_toma,
                    itemsTree2, treeviewRef2, handleModalRefusedSample, onRenderItem2, changeTreeRefused,
                    enableViewTest, handleCloseViewTest, saveRefusedSample, validationsSG, validationsS,
                    handleSelectValuesR, id_return_reason, reason, name_patient, gender
                }
                }
            />
              <CadenaMicrosanitaria
                {
                ...{
                    typeModalMicro,modalMicro,loadingMicro,toggleCadena,arrayMic,micDetail,dataMic,exportToExcel
                   
                }
                }
            />



            <ModalTrazabilidadSample
                {...modalProps}
                close={close}
                dataSample={dataSample}
                dataSendProcess2={dataProcess2}
                dataSendProcess3={dataProcess3}
                dataTrackingRecepetion={dataTrackingReception}
                namePatient={namePatient}
                idGender={idGender}
                dataSection={dataSection}
                dataID={dataID}
                treeviewRef={treeviewRef}
                itemsTree={itemsTree}
            >
            </ModalTrazabilidadSample>
        </Fragment >
    )
}
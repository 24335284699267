import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import moment from 'moment';
import { useForm } from '../forms/useForm';
import { ConfigServer } from '../../data/config';
import { useModal } from "react-morphing-modal";
import * as XLSX from 'xlsx';



export const useWorkOrderDay = () => {
    //variables local storage
    let id_branch = localStorage.getItem('branchId');
    let enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
    let token = localStorage.getItem("token");
    const history = useHistory();
    const sectionsDefault = [];

    useEffect(() => {
        getBranches();
        getPrinterPoints();
        getTypeService();
        setLocalStorageValues();
        getSections().then(resp => {
            getWorkOrdersDay(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), id_branch,0, false, false, true, resp, true, false, '','',false);
        });
    }, [])

    
    

    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [workOrderId, setWorkOrderId] = useState(0);
    //TOOLTIP
    const [tooltip, setTooltip] = useState(false);
    const [tooltipRelease, setTooltipRelease] = useState(false);
    const toggleTooltip = () => setTooltip(!tooltip);
    const toggleTooltipRelease = () => setTooltipRelease(!tooltipRelease);
    //filter
    const [viewFormFilters, setViewFormFilters] = useState(true);
    //Branches and printers
    const [branches, setBranches] = useState([]);
    const [printerPoints, setPrinterPoints] = useState([]);
    //Sections
    const [sections, setSections] = useState([]);
    const [sectiondefault, setSectiondefault] = useState(null);
    const [selectedSections, setselectedSections] = useState(null);
    //detail and indicadores
    const [totalWorkOrderUrgent, setTotalWorkOrderUrgent] = useState(0);
    const [totalWorkOrderCritic, setTotalWorkOrderCritic] = useState(0);
    const [totalWorkOrderNormal, setTotalWorkOrderNormal] = useState(0);
    const [totalWorkOrder, setTotalWorkOrder] = useState(0);
    const [workOrders, setWorkOrders] = useState([]);
    //muestra Toma
    const [modalCheckSpecimen, setModalCheckSpecimen] = useState(false);
    const [itemsTree, setItemsTree] = useState([]);
    const treeviewRef = useRef(null);
    const [dataPending, setDataPending] = useState([]);
    //Refused Muestra Manual
    const [enableViewTest, setEnableViewTest] = useState(false);
    const [dataRefused, setDataRefused] = useState([]);
    const [itemsTree2, setItemsTree2] = useState([]);
    const treeviewRef2 = useRef(null);
    const [reason, setReason] = useState([]);
    const [name_patient, setName_patient] = useState("");
    const [gender, setGender] = useState("");

    //state listExam
    const [method, setMethod] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [listExam, setListExam] = useState([]);
    const [listProfile, setListProfile] = useState([]);

    //list trazabilidad sample
    const [key, setKey] = useState(0);
    const [data, setData] = useState(0)
    const [dataSample, setDataSample] = useState([]);
    const [dataProcess2, setDataProcess2] = useState([]);
    const [dataProcess3, setDataProcess3] = useState([]);
    const [dataAnalitica, setDataAnalitica] = useState([]);
    const [setloadingSample, setSetloadingSample] = useState(false);
    const [dataTrackingReception, setDataTrackingReception] = useState([]);
    const [namePatient, setNamePatient] = useState("");
    const [idGender, setIdGender] = useState(0);
    const [dataSection, setDataSection] = useState([]);
    const [dataID, setDataID] = useState([]);
    const [listExams, setListExams] = useState([])

    //* SELECTED TYPE SERVICE
    let validHopitalInterfaced = localStorage.getItem('interfaced');
    const [dataTypeService, setDataTypeService] = useState([]);



    //#region Form
    const [filterRangeNims, setFilterRangeNims] = useState({
        date_range_nim: new moment().format('YYYY/MM/DD'),
        branch_range_nim: "",
        init_range_nim: "0",
        end_range_nim: "1"
    });

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        mDateRange1: moment().format("YYYY-MM-DD"),
        mDateRange2: moment().format("YYYY-MM-DD"),
        mSelectBranch: 0,
        nim: "",
        mSelectStatus: 0,
        mExam: 0,
        mSelectTypeService: 0,

    });
    const [formPrinter, handleInputChangePrinter, resetPrinter, handleUpdateValuesPrinter, handleSelectValuesPrinter] = useForm({
        id_printer_point: 0
    });

    const { id_printer_point } = formPrinter;

    const {
        mDateRange1, mDateRange2, mSelectBranch, nim, mSelectStatus,mExam,mSelectTypeService
    } = formValues;

    const [formR, handleInputChangeR, , handleUpdateValuesR, handleSelectValuesR] = useForm({
        id_return_reason: 0,
    });

    const { id_return_reason } = formR;
    //#endregion Form
    const handleViewFilters = () => {
        setViewFormFilters(!viewFormFilters);
    }

    const toggleCheckSpecimen = () => {
        setModalCheckSpecimen(!modalCheckSpecimen);
    }

    const toggle = () => {
        setModal(!modal);
    }

    const handleModalRefusedSample = (id_work_order) => {
        setEnableViewTest(true);
    }

    const handleCloseViewTest = () => {
        setEnableViewTest(!enableViewTest);
        handleUpdateValuesR(
            {
                id_return_reason: 0,
            }
        )
        setvalidationsSG(false)
    }

    useEffect(() => {
        if (branches.length > 0) {
            let _find = branches.find(x => x.value == parseInt(id_branch))
            console.log(_find);
            handleUpdateValues({
                ...formValues,
                mSelectBranch: _find
            });
        }
    }, [branches]);

    //status
    const [statusOrder, setstatusOrder] = useState([
        {
            value: 0,
            label: "Sin Estatus"
        },
        {
            value: 1,
            label: "abierta"
        },
        {
            value: 2,
            label: "progeso"
        },

        {
            value: 4,
            label: "validado"
        },
        {
            value: 5,
            label: "parcial"
        },
        {
            value: 6,
            label: "liberado"
        },
        {
            value: 7,
            label: "enviado"
        },
        {
            value: 8,
            label: "entregado"
        },
        {
            value: 9,
            label: "entregado-enviado"
        },
        {
            value: 10,
            label: "autorizado"
        },

        {
            value: 13,
            label: "impreso"
        },
        {
            value: 17,
            label: "desvalidado"
        },
        {
            value: 20,
            label: "cancelada"
        },

    ]);


    //#region printer and branches
    const getPrinterPoints = async () => {
        let _listTemp = await handleRequest("GET", "PrinterPoints", "Impresion");
        if (_listTemp !== null && _listTemp.length > 0) {
            setPrinterPoints(_listTemp);
            handleUpdateValuesPrinter({
                id_printer_point: _listTemp[0].id_printer_point
            })
        }
    }

    const getBranches = async () => {
        let _listTemp = await handleRequest("GET", "Branch/List", "Sucursal");
        if (_listTemp !== null && _listTemp.length > 0) {
            let _newBranches = [];
            _listTemp.forEach((_obj) => {
                let _validB =_obj.code + "-" + _obj.name
                _newBranches.push({
                    value: _obj.id_branch,
                    label: _validB
                });
            });
            setBranches(_newBranches);
        }
    }

    const getReason = async () => {

        let _listTmp = await handleRequest("GET", `ReturnReason/List?is_sample=true&is_work_order=false&is_tracking=false&is_send=false`, 'refused');
        if (_listTmp !== null) {
            let _list = [];
            _listTmp.forEach((_obj, i) => {
                _list.push({
                    value: _obj.id_return_reason,
                    label: _obj.reason
                });
            });
            setReason([..._list]);
        }
    }
    //#endregion printer and branches

    //#region Sections 
    const getSections = async () => {
        let _listTemp = await handleRequest("GET", "Section/List/false", "Secciones");

        if (_listTemp !== null && _listTemp.length > 0) {
            let _secciones_vinculadas = JSON.parse(localStorage.getItem("sections"));
            let _secciones_guardadas = localStorage.getItem("filter_sections");
            if (_secciones_guardadas != '[]') {
                _secciones_guardadas = JSON.parse(_secciones_guardadas);
            }
            let secciones = [];
            let sectionmultiselect = [];
            let sectiondefault = [];
            _listTemp.forEach(({ id_section, name }) => {
                _secciones_vinculadas.forEach(function (element, index, _secciones_vinculadas) {
                    if (element == id_section) {
                        let checkAux = false;
                        if (_secciones_guardadas != '[]') {
                            let validacion = _secciones_guardadas.find(x => x.id_section === id_section);
                            if (validacion !== undefined) {
                                checkAux = true;
                                sectiondefault.push(
                                    { name: name, code: id_section  },
                                )
                            } else {
                                checkAux = false;
                            }
                        } else if (_secciones_guardadas == "[]") {
                            sectiondefault.push(
                                { name: name, code: id_section },
                            )
                            checkAux = true;
                        }
                        secciones.push({
                            id_section: id_section,
                            name: name,
                            check: checkAux,
                            label: name,
                            value: id_section
                        })
                        sectionmultiselect.push(
                            { name: name, code: id_section },
                        )
                    }
                });
            });
            setSections([...secciones]);
            setSectiondefault(sectionmultiselect);
            setselectedSections(sectiondefault);
            if (_secciones_guardadas == "[]") {
                localStorage.setItem("filter_sections", JSON.stringify(secciones));
            }
            return secciones;
        }
    }

    console.log(selectedSections);
    console.log("--");

    const changeCheckbox = (e) => {
        console.log(e);
        console.log(e.code);
        let _array = sections;
        let sectionfind = [];
        _array.map(_obj => {
            console.log(_obj.id_section);
            if (e.some(x => x.code === _obj.id_section)) {
                sectionfind.push(
                    { name: _obj.name, code: _obj.id_section },
                )
                getFilterExams(_obj.id_section)

            }
            return _obj;
        });
        _array.map(_obj => {
            console.log("---");
            console.log(_obj.id_section);
            if (e.some(x => x.code === _obj.id_section)) {
                _obj.check = true;
            } else {
                _obj.check = false;
            }
            return _obj;
        });
        let filter_sections = _array.filter(x => x.check === true);
        localStorage.setItem("filter_sections", JSON.stringify(filter_sections));
        localStorage.setItem("sectionfind", JSON.stringify(filter_sections));
        setselectedSections(sectionfind);
        setSections(_array);
    }
    //#endregion Sections

    //#region WorkOrders
    const getWorkOrders = (e, search = false, boton = false, buttonValue = "",_valid = false) => {
        e.preventDefault();
        console.log("entras");
        let query = "";
        if (!search) {
            let _idBranch = 0;
            let _idExam = 0;
            let _type_service = 0;


            console.log(formValues.mExam);
            console.log("pasas");

            if (formValues.mExam != undefined  && formValues.mExam !== null) {
                console.log(formValues.mExam);
                console.log("1");
                if (formValues.mExam.value !== 0) {
                    console.log("pasa");
                    _idExam = mExam.value;
                }
                else {
                    console.log("2");
                    _idExam = 0;
                }
                
            }


            if (formValues.mSelectBranch != undefined && formValues.mSelectBranch !== null) {
                console.log(formValues.mSelectBranch)
                if (formValues.mSelectBranch.value !== 0) {
                    _idBranch = mSelectBranch.value;
                }
                else {
                    _idBranch = 0;
                }
            }
            else {
                _idBranch = 0;
            }

            if (mSelectStatus !== 0) {
                query += `?&id_estatus_order=${mSelectStatus.value}`;
            }

            
            if (buttonValue === 'Interfazados') {
                console.log("pasas");
                query += `?id_estatus_order=${0}&only_interface_unchecked=${true}`;
                getWorkOrdersDay(formValues.mDateRange1, formValues.mDateRange2, (_idBranch !== undefined ? _idBranch : 0), query, false, search, false, null, false, boton, buttonValue);
            }else{
                console.log("no pasas");
                getWorkOrdersDay(formValues.mDateRange1, formValues.mDateRange2, (_idBranch !== undefined ? _idBranch : 0), query, false, search, false, null, false, boton,_idExam);
            }

            // if (validHopitalInterfaced === "True") {
            //         if (formValues.mSelectTypeService.value !== 0 ) {
            //             query += `?id_estatus_order=0&ids_work_order_type_service=${mSelectTypeService.value}`;
            //             getWorkOrdersDay(formValues.mDateRange1, formValues.mDateRange2, (_idBranch !== undefined ? _idBranch : 0), query, false, search, false, null, false, boton,buttonValue, _valid);           
            //         }                
            // }

           
        } else {
            console.log("wilson no pasa aqui");
            if (nim.length <= 0) {
                alert("Debes ingresar minimo un caracter para buscar");
                return;
            }
            let _idBranch = 0;
            getWorkOrdersDay(formValues.mDateRange1, formValues.mDateRange2, (_idBranch !== undefined ? _idBranch : 0), query, false, search, false, null, false, boton,_valid);
        }
    }

    console.log(mExam);
    console.log();

    const setLocalStorageValues = async() => {
        var local_request = localStorage.getItem('request_workorder_listworkorder');
           
        if(local_request != null){
            var request = JSON.parse(local_request);
            console.log(request);
            console.log("que hace");
            handleUpdateValues({
                ...formValues,
                mSelectBranch: request.id_branch,
                mDateRange1: request.date1,
                mDateRange2: request.date2,
                mExam: request.gatos ?? 0
            });            
        }
    }

    const resetLocalStorage = async() => {
        console.log("entra aqui");
        localStorage.removeItem('request_workorder_listworkorder');
        localStorage.removeItem('current_work_orders')
        handleUpdateValues({
            ...formValues,
            mSelectBranch: parseInt(localStorage.getItem('branchId')),
            mDateRange1: moment().format("YYYY-MM-DD"),
            mDateRange2: moment().format("YYYY-MM-DD"),
            mExam:0,
            mSelectTypeService: 0,
        });
        window.location.reload();
        
        
    }





    const [arrayMic, setArrayMic] = useState([])
    const [micDetail, setMicDetail] = useState([])

    const getWorkOrdersDay = async (date1, date2, id_branch, query, is_critic = false, search = false, first = false, arraySections = null, allSections = false, boton = false,gatos,type_service = false) => {
        let _formulario = (allSections) ? arraySections : (arraySections === null ? sections.filter(x => x.check === true) : arraySections.filter(x => x.check === true));
   console.log(type_service);
   console.log("+++++++++++++");
       let _validnew = mExam.value
       console.log(_validnew);
        let _valid =gatos
console.log("--");
        console.log(_valid);

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(_formulario)
        };
        setLoading(true);
        if (date1._d !== undefined) {
            date1 = moment(date1._d).format("YYYY-MM-DD");
        }
        else {
            date1 = moment(date1).format("YYYY-MM-DD");
        }

        if (date2._d !== undefined) {
            date2 = moment(date2._d).format("YYYY-MM-DD");
        }
        else {
            date2 = moment(date2).format("YYYY-MM-DD");
        }
        var _respuesta = {};
        let _alterUrl = "";

        if (is_critic === true) {
            if (id_branch !== null) {
                if (id_branch.value !== undefined) {
                    _alterUrl = `?id_branch=${id_branch.value}`;
                }
            }
            if (nim !== "") {
                _alterUrl = `?nim=${nim}`;

                requestOptions = {
                    ...requestOptions,
                    method: 'GET'
                }
            }
            _respuesta = await sendRequest(requestOptions, `WorkOrder/ListDetailUrgentCriticTest/${date1}/${date2}${_alterUrl}`);
        }
        else if (search === false) {

            let vaild = (query == 0 || query == undefined) ? "" : query;
            
            var local_request = localStorage.getItem('request_workorder_listworkorder');
            console.log(local_request);
            console.log(boton);
            console.log("----------");
           
            if(local_request == null || boton  ){
                console.log(_validnew);
                console.log("Caso 1");
                console.log("--");

                if (   _validnew === 0 || _validnew === undefined ) {
                    
                    console.log("mango 1");
                    if (validHopitalInterfaced === "True") {
                        if (formValues.mSelectTypeService.value > 0 ) {


                   
                            console.log(formValues.mSelectTypeService.value);
                            console.log("aqui deberia pasar");
                            console.log("los monos");
                            _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${date1},${date2},${id_branch}?id_estatus_order=0&ids_work_order_type_service=${formValues.mSelectTypeService.value}` + vaild );

    
                            
                        }
                        else {
                            _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${date1},${date2},${id_branch}` + vaild);
                        }
                        
                    }
                    else {
                        _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${date1},${date2},${id_branch}` + vaild);


                    }


                    
                }
                else {
                    console.log("mango 2");

                    _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${date1},${date2},${id_branch}?ids_exams=${gatos}` + vaild );


                }
            
                 

            }

            else{
                console.log(_validnew);


                console.log("Caso 2");
                console.log(gatos);
                console.log(_validnew);
                var request = JSON.parse(local_request);
                console.log(request.gatos);
                console.log(request);
                requestOptions.body = request.body;
                id_branch = request.id_branch;
                date1 = request.date1;
                date2 = request.date2;
                gatos = request.gatos;
                _formulario = JSON.parse(request.body)

                if (gatos === "" || gatos === undefined ) {
                    console.log("mango 3");

                    _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${request.date1},${request.date2},${request.id_branch}` + request.vaild);
 
                }

                if (gatos > 0) {
                    if (request.gatos === undefined || request.gatos === "" ) {
                        console.log("pasas");
                        console.log("mango 4");


                        _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${date1},${date2},${id_branch}?ids_exams=${gatos}` + vaild );

                        console.log(_respuesta);
                    }
                    else {
                        console.log("mango 5");

                 _respuesta = await sendRequest(requestOptions, `WorkOrder/ListWorkOrder/${request.date1},${request.date2},${request.id_branch}?ids_exams=${request.gatos}` + request.vaild );


                    }
                }

            
            }
        } else {
            requestOptions = {
                method: 'GET'
            }
            _respuesta = await sendRequest(requestOptions, `WorkOrder/SearchWorkOrderFromNim/${nim}`);
        }

        console.log(_respuesta);

        if (_respuesta.code === 200) {
            
            var nims = _respuesta.data.map(wo => {
                return wo.nim
            });

            localStorage.setItem('current_work_orders', JSON.stringify(nims));

            if (search === false){
                console.log("pasas");
            
                let request_localstorage = {
                    date1: date1,
                    date2: date2,
                    id_branch: id_branch,
                    vaild:  (query == 0 ? "" : query),
                    gatos: gatos,
                    body: JSON.stringify(_formulario)
                };
                console.log(request_localstorage);
                console.log("que guardas");
    
                localStorage.setItem('request_workorder_listworkorder', JSON.stringify(request_localstorage));
                console.log(request_localstorage);
            }

            

            if (_respuesta.data.length >= 1) {
                let _twou = 0;
                let _twoc = 0;
                let _twon = 0;
                let _two = 0;

                _respuesta.data.map(_find => {
                    let _wanting = false;
                    let _urgent = 0;
                    let _critical = 0;
                    let _validador = [];

                    if (_find.listProfile.length > 0) {
                        if (_find.listProfile[0].listExam.length > 0) {
                            _find.listProfile[0].listExam.forEach(({ check_specimen, id_specimen_origin }) => {
                                if (!check_specimen) {
                                    _wanting = true;
                                }
                                if (_validador.find(x => x.id === id_specimen_origin) === undefined) {
                                    _validador.push({
                                        id: id_specimen_origin,
                                    })
                                }
                            });
                        }
                    }
                    if (_find.listExam.length > 0) {
                        _find.listExam.map(({ check_specimen, id_specimen_origin, is_urgent, is_critic }) => {
                            if (!check_specimen) {
                                _wanting = true;
                            }
                            if (_validador.find(x => x.id === id_specimen_origin) === undefined) {
                                _validador.push({
                                    id: id_specimen_origin,
                                })
                            }
                            if (is_urgent) {
                                _urgent = 1;
                            }
                            if (is_critic) {
                                _critical = 2;
                            }
                        });
                    }
                    if (_urgent) {
                        _twou += 1;
                    } else if (_critical) {
                        _twoc += 1;
                    } else {
                        _twon += 1;
                    }
                    _find._wanting = _wanting;
                    _find._urgent = _urgent;
                    _find._critical = _critical;
                    _find.cont_check_specimen = _validador.length;
                    return _find;
                });
                setTotalWorkOrderUrgent(_twou);
                setTotalWorkOrderCritic(_twoc);
                setTotalWorkOrderNormal(_twon);
                _two = _twou + _twoc + _twon;
                setTotalWorkOrder(_two);
                let criticos = _respuesta.data.filter(x => x._critical === 2);
                let urgentes = _respuesta.data.filter(x => x._urgent === 1);
                let normales = _respuesta.data.filter(x => x._critical === 0 && x._urgent === 0);
                let todos = criticos.concat(urgentes).concat(normales);
                setWorkOrders(todos);
            }
            else {
                if (!first) {
                    // createSweet("error", "info", "No existen resultados para su busqueda");
                }
                setWorkOrders({});
            }
            setLoading(false);
        }
        else if (_respuesta.code === 401) {
            validarSesion(history, _respuesta.code, getWorkOrdersDay);

        }
        else if (_respuesta.code === 409) {
            setWorkOrders({});
            // setLoading(false);
            createSweet("info", "info", "Consulta", _respuesta.data.msg);

        }
        else if (_respuesta.code === 500) {
            createSweet("error", "error", "Consulta", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Consulta", _respuesta.data.msg);
        }
    }
    //#endregion WorkOrders

    //#region List Exam and Method Navigate
    const handleListExam = async (id_workOrder, id_branch, id) => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `WorkOrder/DetailDashboard${id_branch}?nim=${id_workOrder}`);
        if (respuesta.code === 200) {
            let order = workOrders.find(w => w.id_work_order === parseInt(id) && w.id_brach === parseInt(id_branch));
            respuesta.data.workOrderDetailPatient.forEach(element => {
                if (element.listProfileWorkOrders.length >= 1) {
                    setListProfile(element.listProfileWorkOrders)
                }
                else {
                    setListProfile([]);
                }
            });
            if (order != null) {

                if (order.listExam.length >= 1) {
                    setListExam(order.listExam);
                }
                else {
                    setListExam([]);
                }
            }
            else {
                setListExam([]);
            }
            setMethod("list");
            setModalTitle("Estudios por realizar");
            toggle();
        }
    }

    const navigateConfigPrices = (id_work_order) => {
        history.push("../busqueda/" + id_work_order + "/detalle-orden");
    }

    function navigateCaptureResults(nim) {

        let work_order = workOrders.find(x => x.nim === nim);
        if (work_order.is_micro) {
            history.push(`../captura-resultados-sanitaria/${nim}`);
        } else {
            history.push(`../captura-resultados/${nim}`);
        }
    }

    const navigateUpdate = (workOrderId) => {
        let _value = "" + workOrderId + "";
        // _value = Buffer.from(_value).toString('base64');
        history.push(`../admisiones/${_value}/editar`);
    }
    //#endregion List Exam

    //#region Range Nim
    const changeFiltersRangeNim = (_value, type) => {
        let _data = filterRangeNims;
        switch (type) {
            case "date_range_nim":
                _data.date_range_nim = new moment(_value).format('YYYY/MM/DD');
                break;
            case "branch_range_nim":
                _data.branch_range_nim = _value.value;
                break;
            case "init_range_nim":
                _data.init_range_nim = _value;
                break;
            case "end_range_nim":
                _data.end_range_nim = _value;
                break;
            default:
                break;
        }
        setFilterRangeNims(_data);
    }

    const getNimsFromRange = async () => {

        if (filterRangeNims.branch_range_nim === "" || filterRangeNims.init_range_nim === "" || filterRangeNims.end_range_nim === "") {
            createSweet("warning", "warning", "Alerta", "Debes llenar todos los campos para poder filtrar por rango");
            return;
        }

        const _response = await handleRequest("POST", "WorkOrder/GetWorkOrdersFromRangeNim", "Range", filterRangeNims);

        if (_response !== null) {
            let _twou = 0;
            let _twoc = 0;
            let _twon = 0;
            let _two = 0;

            _response.map(element => {
                let validar_faltantes = false;
                let _urgent = 0;
                let _critical = 0;
                let _validador = [];

                if (element.listProfile.length > 0) {
                    if (element.listProfile[0].listExam.length > 0) {
                        element.listProfile[0].listExam.forEach(({ check_specimen, id_specimen_origin }) => {
                            if (!check_specimen) {
                                validar_faltantes = true;
                            }
                            if (_validador.find(x => x.id === id_specimen_origin) === undefined) {
                                _validador.push({
                                    id: id_specimen_origin,
                                })
                            }
                        });
                    }
                }
                if (element.listExam.length > 0) {
                    element.listExam.map(({ check_specimen, id_specimen_origin, is_urgent, is_critic }) => {
                        if (!check_specimen) {
                            validar_faltantes = true;
                        }
                        if (_validador.find(x => x.id === id_specimen_origin) === undefined) {
                            _validador.push({
                                id: id_specimen_origin,
                            })
                        }
                        if (is_urgent) {
                            _urgent = 1;
                        }
                        if (is_critic) {
                            _critical = 2;
                        }
                    });
                }
                if (_urgent) {
                    _twoc += 1;
                } else if (_critical) {
                    _twoc += 1;
                } else {
                    _twon += 1;
                }
                element.validar_faltantes = validar_faltantes;
                element._urgent = _urgent;
                element._critical = _critical;
                element.cont_check_specimen = _validador.length;
                return element;
            })
            setTotalWorkOrderUrgent(_twou);
            setTotalWorkOrderCritic(_twoc);
            setTotalWorkOrderNormal(_twon);
            _two = _twou + _twoc + _twon;
            setTotalWorkOrder(_two);
        }
        let criticos = _response.filter(x => x._critical === 2);
        let urgentes = _response.filter(x => x._urgent === 1);
        let normales = _response.filter(x => x._critical === 0 && x._urgent === 0);
        let todos = criticos.concat(urgentes).concat(normales);
        setWorkOrders(todos);
    }
    //#endregion Range Nim

    //#region check specimen 
    //handleEnable toma and Manual
    const handleModalCheckSpecimen = (_id_work_order, _name_patient, _gender) => {
        setWorkOrderId(_id_work_order);
        let _work_order = workOrders.find(x => x.id_work_order === _id_work_order);
        let _enable_toma = [];
        let _enable_manual = [];

        _enable_toma.push({
            "profiles": _work_order.listProfile,
            "exams": _work_order.listExam
        });
        let tree = createTree(_enable_toma);
        setItemsTree(tree);

        _enable_manual.push({
            "profiles": _work_order.listProfile,
            "exams": _work_order.listExam
        });
        let _tree = createTreeRefused(_enable_manual);
        setItemsTree2(_tree);
        toggleCheckSpecimen();
        getReason();
        setName_patient(_name_patient);
        setGender(_gender)
    }

    const createTree = (_arrayNuevo) => {
        let tree = [];

        _arrayNuevo.forEach(_find => {
            if (_find.exams.length > 0) { //exam
                _find.exams.forEach(_findE => {
                    tree.push({
                        "children": [],
                        "id": _findE.id_work_order_exam,
                        "state": _findE.check_specimen === true ? 1 : 2,
                        "text": _findE.name,
                        "isLeaf": _findE.check_specimen,
                        "refused_check_specimen": _findE.refused_check_specimen,
                        "check_forwarded": _findE.check_forwarded,
                        "is_send1": _findE.is_send1,
                        "is_send2": _findE.is_send2,
                        "is_send3": _findE.is_send3
                    })
                });
            }
            if (_find.profiles.length > 0) {    //profile
                _find.profiles.forEach(_find => {
                    let examenes_aux = [];
                    let id = true;
                    let _checkParent = 1;
                    let _result = "";
                    let _leaf = "";
                    let is_send1 = false;
                    let is_send2 = false;
                    let is_send3 = false;

                    _find.listExam.forEach(_exam => {
                        if (!_exam.check_specimen) {
                            _checkParent = 2;
                        }
                        examenes_aux.push({
                            "id": _exam.id_work_order_exam,
                            "isLeaf": _exam.check_specimen,
                            "state": _exam.check_specimen === true ? 1 : 2,
                            "text": _exam.name,
                            "refused_check_specimen": _exam.refused_check_specimen,
                            "check_forwarded": _exam.check_forwarded,
                            "is_send1": _exam.is_send1,
                            "is_send2": _exam.is_send2,
                            "is_send3": _exam.is_send3

                        })
                        id = _exam.check_specimen
                        _result = _exam.refused_check_specimen
                        _leaf = _exam.check_specimen
                        is_send1 = _exam.is_send1
                        is_send2 = _exam.is_send2
                        is_send3 = _exam.is_send3
                    });
                    tree.push({
                        "children": examenes_aux,
                        "state": _checkParent,
                        "text": _find.name,
                        "refused_check_specimen": _result,
                        "isLeaf": _leaf,
                        "is_send1": is_send1,
                        "is_send2": is_send2,
                        "is_send3": is_send3,
                    })
                });
            }
        });
        return tree;
    }

    const onRenderItem = (_find) => {
        return (
            <div className="treeview-item-example">
                {
                    _find.refused_check_specimen === true ?
                        <div> <br />
                            {
                                _find.isLeaf === false ?
                                    <span className='text-primary '>
                                        <i className="icofont icofont-doctor-alt f-20 "></i> <label className='f-14'>{_find.text}</label>
                                    </span>
                                    :
                                    <span className='text-WorkOrder'>
                                        <i className="icofont icofont-doctor-alt f-20 "></i> <label className='f-14'>{_find.text}</label>
                                    </span>
                            }
                        </div>
                        :
                        <div> <br />
                            {
                                _find.isLeaf === false ?
                                    <span className='text-danger '>
                                        <i className="icofont icofont-laboratory  f-20"></i> <label className='f-14'>{_find.text}</label>
                                    </span>
                                    :
                                    <span className=''>
                                        <i className="icofont icofont-laboratory  f-20"></i> <label className='f-14'>{_find.text}</label>
                                    </span>
                            }
                        </div>
                }
            </div>
        )
    }

    const changeTree = (e) => {
        changeStatus(e)
    }

    const changeStatus = async (e, id_work_order) => {
        const api = treeviewRef.current.api
        const items = api.getItems();
        let _pending = [];
        items.forEach(_findE => { //exams
            if (_findE.id !== undefined) {
                if (_findE.state === 1) {
                    _pending.push({
                        "id_work_order": workOrderId,
                        "id_work_order_exam": _findE.id,
                        "list_specimensToma": [
                            {
                                "check_specimen": true,
                                "refused_check_specimen": false,
                                "name": _findE.text
                            }
                        ]
                    });
                }
            }
            else {  //profiles
                _findE.children.forEach(ex => {
                    if (ex.state === 1) {
                        _pending.push({
                            "id_work_order": workOrderId,
                            "id_work_order_exam": ex.id,
                            "list_specimensToma": [
                                {
                                    "check_specimen": true,
                                    "refused_check_specimen": false,
                                    "name": ex.text
                                }
                            ]
                        });
                    }
                });
            }
        });
        setDataPending(_pending)
    }
    //Method POST
    const savePendingMuestra = async () => {

        if (validationSample()) {
            return;
        }
        const _response = await handleRequest("POST", "Sass/CheckSpecimenFlebotomia", "sample", dataPending);
        if (_response !== null) {
            let _newWorkOrders = workOrders
            let _validador = false;

            _newWorkOrders = _newWorkOrders.map(_finEP => {
                if (_finEP.id_work_order == workOrderId) {
                    if (_finEP.listProfile.length > 0) {
                        if (_finEP.listProfile[0].listExam.length > 0) {
                            _finEP.listProfile[0].listExam.map(_examP => {
                                _examP.refused_check_specimen = false;
                                dataPending.forEach(_obj => {
                                    _obj.list_specimensToma.forEach(_finEP => {
                                        if (_obj.id_work_order_exam === _examP.id_work_order_exam) {
                                            _examP.check_specimen = _finEP.check_specimen
                                        }
                                        if (!_finEP.check_specimen) {
                                            _validador = true;
                                        }
                                    });
                                });
                                return _examP;
                            });
                        }
                    }
                    if (_finEP.listExam.length > 0) {
                        _finEP.listExam.map(_examP => {
                            dataPending.forEach(_obj => {
                                _obj.list_specimensToma.forEach(_e => {
                                    if (_obj.id_work_order_exam === _examP.id_work_order_exam) {
                                        _examP.check_specimen = _e.check_specimen
                                    }
                                    if (!_e.check_specimen) {
                                        _validador = true;
                                    }
                                });
                            });
                            return _examP;
                        });
                    }
                    _finEP._wanting = _validador
                }
                return _finEP;
            });
            setWorkOrders(_newWorkOrders);
            createSweet("success", "success", "Muestra Pendiente", "Muestra Actualizada Correctamente");
        }
        toggleCheckSpecimen();
    }
    //#endregion specimen

    //#region refused sample 
    const createTreeRefused = (_arrayNuevo) => {
        let _tree = [];
        _arrayNuevo.forEach(_objEP => {
            if (_objEP.exams.length > 0) { //exam
                _objEP.exams.forEach(_examen => {
                    _tree.push({
                        "children": [],
                        "id": _examen.id_work_order_exam,
                        "state": 2,
                        "text": _examen.name,
                        "isLeaf": false,
                        "refused_check_specimen": _examen.refused_check_specimen
                    })
                });
            }
            if (_objEP.profiles.length > 0) {   //profile
                _objEP.profiles.forEach(_p => {
                    let examenes_aux = [];
                    let _stateSpecimen = "";
                    let checkParent = 1;
                    let _state = "";

                    _p.listExam.forEach(_exam => {
                        if (!_exam.check_specimen) {
                            checkParent = 2;
                        }
                        examenes_aux.push({
                            "id": _exam.id_work_order_exam,
                            "isLeaf": false,
                            "state": 2,
                            "text": _exam.name,
                            "refused_check_specimen": _exam.refused_check_specimen
                        })
                        _stateSpecimen = _exam.check_specimen
                        _state = _exam.refused_check_specimen
                    });
                    _tree.push({
                        "children": examenes_aux,
                        "state": 2,
                        "text": _p.name,
                        "isLeaf": _stateSpecimen,
                        "refused_check_specimen": _state
                    })
                });
            }
        });
        return _tree;
    }

    const changeTreeRefused = (e) => {
        changeStatusRefused(e)
    }

    const changeStatusRefused = async (e) => {

        const api = treeviewRef2.current.api
        const items = api.getItems();
        let _pendingRefused = [];

        items.forEach(_findEP => {
            if (_findEP.id !== undefined) {
                if (_findEP.state === 1) {
                    _pendingRefused.push({
                        "id_work_order": workOrderId,
                        "id_work_order_exam": _findEP.id,
                        "list_specimensToma": [
                            {
                                "check_specimen": false,
                                "refused_check_specimen": true,
                                "name": _findEP.text
                            }
                        ]
                    });
                }
            }
            else { //profiles
                _findEP.children.forEach(_fPerfil => {
                    if (_fPerfil.state === 1) {
                        _pendingRefused.push({
                            "id_work_order": workOrderId,
                            "id_work_order_exam": _fPerfil.id,
                            "list_specimensToma": [
                                {
                                    "check_specimen": false,
                                    "refused_check_specimen": true,
                                    "name": _fPerfil.text
                                }
                            ]
                        });
                    }
                })
            }
        });
        setDataRefused(_pendingRefused)
    }

    const saveRefusedSample = async () => {
        if (validationRefused()) {
            return;
        }
        const _response = await handleRequest("POST", "Sass/CheckSpecimenFlebotomia", "sample", dataRefused);
        if (_response !== null) {
            let _newWorkOrders = workOrders
            let _validador = true;

            _newWorkOrders = _newWorkOrders.map(_findEP => {
                if (_findEP.id_work_order == workOrderId) {
                    if (_findEP.listProfile.length > 0) {
                        if (_findEP.listProfile[0].listExam.length > 0) {
                            _findEP.listProfile[0].listExam.map(_examP => {
                                _examP.refused_check_specimen = true;
                                dataRefused.forEach(_obj => {
                                    _obj.list_specimensToma.forEach(_findEP => {
                                        if (_obj.id_work_order_exam === _examP.id_work_order_exam) {
                                            _examP.check_specimen = _findEP.check_specimen
                                        }
                                        if (!_findEP.check_specimen) {
                                            _validador = true;
                                        }
                                    });
                                });
                                return _examP;
                            });
                        }
                    }
                    if (_findEP.listExam.length > 0) {
                        _findEP.listExam.map(_examP => {
                            dataRefused.forEach(_obj => {
                                _obj.list_specimensToma.forEach(_e => {
                                    if (_obj.id_work_order_exam === _examP.id_work_order_exam) {
                                        _examP.check_specimen = _e.check_specimen
                                    }
                                    if (!_e.check_specimen) {
                                        _validador = true;
                                    }
                                });
                            });
                            return _examP;
                        });
                    }
                    _findEP._wanting = _validador
                }
                return _findEP;
            });
        }
        createSweet("success", "success", "Muestra Pendiente", "Muestra Rechazada Correctamente");
        saveRefusedGlobal();
        handleCloseViewTest();
        toggleCheckSpecimen();
    }

    const saveRefusedGlobal = async () => {
        let _arrayRefused = [];
        let _array = dataRefused;
        let _id_return_reason2 = formR.id_return_reason.value

        _array.forEach(_find => {
            _arrayRefused.push({
                "id_work_order_exam": _find.id_work_order_exam,
                "id_return_reason": _id_return_reason2,
                "origen": "Sin Toma"
            })
        });
        const _response = await handleRequest("POST", "Sass/CreateRefusedGlobal", "refused", _arrayRefused);
        if (_response !== null) {
        }
    }
    //#endregion rwfused sample

    //#region validations
    const [validationsSG, setvalidationsSG] = useState({
        dataRefused: false,
        id_return_reason: false
    });
    const [validationsS, setvalidationsS] = useState({
        dataPending: false,
    });
    //#endregion validations

    //#region validations
    const validationRefused = () => {
        let _statusValidation = false;
        let _newValidations = {};
        let _id_return_reason2 = formR.id_return_reason.value

        if (dataRefused.length === 0) {
            _newValidations = {
                ..._newValidations,
                dataRefused: true,
            };
            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                dataRefused: false,
            };
        }
        if (_id_return_reason2 === undefined) {
            _newValidations = {
                ..._newValidations,
                id_return_reason: true,
            };
            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_return_reason: false,
            };
        }
        setvalidationsSG(_newValidations);
        return _statusValidation;
    }

    const validationSample = () => {
        let _statusValidation = false;
        let _newValidations = {};

        if (dataPending.length === 0) {
            _newValidations = {
                ..._newValidations,
                dataPending: true,
            };
            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                dataPending: false,
            };
        }
        setvalidationsS(_newValidations);
        return _statusValidation;
    }
    //#endregion validations

    //#region ticket 
    const printTicket = async (id_work_order) => {
        if (id_work_order > 0) {
            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintTicket/" + id_work_order, {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }

    const printSticker = async (id_work_order) => {
        if (id_work_order > 0) {
            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintStickers/" + id_work_order, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "etiquetas.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }
   
    const printStickerMicro = async (id_workOrder) => {
        if (id_workOrder > 0) {
            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintStickersMicro/" + id_workOrder, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "etiquetas.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();
                })
        }
    }

    //#endregion ticket

    //#region trazabildad Sample
    const [state, setState] = useState(prevState => ({
        ...prevState,
        text: "",
        number: 0
    }));

    const modalFullRef = useRef(null);

    const { close, modalProps, open } = useModal({
        onClose: () => setKey(key => key + 1),
        background: "#fff"

    });

    const handleTrazabilidad = (_idW, _nim, _name, _gender) => {
        let _work_order = workOrders.find(x => x.id_work_order === _idW);
        let arrayNuevo = [];

        arrayNuevo.push({
            "profiles": _work_order.listProfile,
            "exams": _work_order.listExam
        })

        let tree = createTree(arrayNuevo);
        setItemsTree(tree);

        setData(_nim);
     //   handleGetSample(_nim);
      //  handleGetSendProcess2(_idW);
     //   handleGetSendProcess3(_idW);
      //  handleGetTrackingReception(_idW);
        setNamePatient(_name)
        setIdGender(_gender)
        open(modalFullRef);
    }

    //#region methods
    const handleGetSample = async (_nim) => {
        setSetloadingSample(false);

        let _listTmp = await handleRequest("GET", `Sample/DetailSample/${_nim}`, 'sample');
        if (_listTmp !== null) {
            setSetloadingSample(true);
            setDataSample(_listTmp);
        }
    }

    const handleGetSendProcess2 = async (_idW) => {

        let _listTmp = await handleRequest("GET", `Sample/DetailSendSample1/${_idW}`, 'sample');
        if (_listTmp !== null) {
            setDataProcess2(_listTmp)
        }
    }


    const handleGetSendProcess3 = async (_idW) => {

        let _listTmp = await handleRequest("GET", `Sample/DetailSendSample2/${_idW}`, 'sample');
        if (_listTmp !== null) {
            setDataProcess3(_listTmp)
        }
    }

    const handleGetTrackingReception = async (_idW) => {

        let _listTmp = await handleRequest("GET", `Sample/DetailtrackingReception/${_idW}`, 'sample');
        if (_listTmp !== null) {
            let nuevoObjeto = [];
            let indexObject = [];

            if (_listTmp.length >= 0) {
                _listTmp.forEach(x => {
                    if (!nuevoObjeto.hasOwnProperty(x.nameSection)) {
                        nuevoObjeto[x.nameSection] = {
                            secciones: []
                        }
                        indexObject.push(x.nameSection);
                    }
                    nuevoObjeto[x.nameSection].secciones.push({
                        nombre: x.nameExam,
                        proces: x.initFech
                    })
                });
                setDataSection(nuevoObjeto);
                setDataID(indexObject);
            }
            else {
                setDataSection([]);
                setDataID([]);
            }
            setDataTrackingReception(_listTmp)
        }
    }

    //#endregion methods
    //#endregion Trazabilidad Sample.

     //#region Microsanitaria 

    //*MODAL TYPE CADENA
    const [typeModalMicro, setTypeModalMicro] = useState("viewMicro");
    const [modalMicro, setModalMicro] = useState(false);
    const [loadingMicro, setLoadingMicro] = useState(false);
    const [dataMic, setDataMic] = useState([])
    

    const toggleCadena = () => {
        setModalMicro(!modalMicro);
    }

    const handleCadenaMicro = async (id_workOrder, id_branch, id) => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `WorkOrder/DetailDashboard${id_branch}?nim=${id_workOrder}`);

        let _nuevoObjeto = {};
        let _listExamsMic = [];

        if (respuesta.code === 200) {
            let order = workOrders.find(w => w.id_work_order === parseInt(id) && w.id_brach === parseInt(id_branch));
            respuesta.data.workOrderDetailPatient.forEach(element => {
                if (element.listProfileWorkOrders.length >= 1) {
                    setListProfile(element.listProfileWorkOrders)
                }
                else {
                    setListProfile([]);
                }
            });
            if (order != null) {

                order.listExam.forEach(_list => {
                                if (!_nuevoObjeto.hasOwnProperty('e-' + _list.id_exam)) {
                                    _nuevoObjeto['e-' + _list.id_exam] = {
                                        IdExam: _list.id_exam,
                                        ListExam: _list.send_details,

                                    };
                                }
                            });

              
            }

              for (let _prop in _nuevoObjeto) {
                    _listExamsMic.push({
                        idExam: _nuevoObjeto[_prop].IdExam,
                        amount: _nuevoObjeto[_prop].ListExam,
                    });
                }

                setDataMic(_listExamsMic)

            setTypeModalMicro("viewMicro");
            setModalTitle("Estudios por realizar");
            toggleCadena();

        }
    }

    function exportToExcel(_array) {
        let _reportMicro = [];

        _array.forEach(_find => {
            _reportMicro.push({
                "Folio": _find.amount.codeMic,
                "Condiciones": _find.amount.temperature_send,
                "Temperatura Corregida": _find.amount.temperature_corrected_send,
                "Cumple temperatura": _find.amount.comply_temperature_send,
                "Identificación": _find.amount.identification_send,
                "Contenedor": _find.amount.container_send,
                "Cantidad": _find.amount.quantity_send,
            })         
        });
      


        const worksheet = XLSX.utils.json_to_sheet(_reportMicro);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'Cadena_de_Custodia.xlsx';

        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            // Para Internet Explorer
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            // Para otros navegadores
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }
   
    //#endregion Microsanitaria

    // #region SELECTED SECTIONS

     //! SEARCH EXAMS AND SELECTED SECTIONS
     useEffect(() => {
        if (selectedSections === null) {
    
        }
        else {
            let _arraySections = [];
            
            selectedSections.forEach(_id => {
                _arraySections.push({
                    id:_id.code
                })
                getFilterExams( _arraySections)   
            });
        }

    }, [selectedSections !== null])


    //!SELECTED ARRAY SECTIONS
    const getFilterExams = async (id_state) => {
       
        let listTmp = await handleRequest("GET", `Exam/ListExams?ids_sections=${id_state}`, 'Busqueda');
        if (listTmp !== null && listTmp.length > 0) {
            let listTmpMunicipalities = [];
            let _id = 0

            listTmp.forEach(_municipality => {
                listTmpMunicipalities.push({
                    label: _municipality.name,
                    value: _municipality.id_exam,
                    _id:  _municipality.id_exam
                });
            });

            handleUpdateValues({
                ...formValues,
                mExam: _id
            });
        
            setListExams(listTmpMunicipalities);
        }
    }
    // #endregion

    // #region SELECTED TYPE SERVICES
    const getTypeService = async () => {
        let listTmp = await handleRequest("GET", "WorkOrderServiceType/List", 'Tipo de Servicio');

        if (listTmp !== null && listTmp.length > 0) {
            let listTmpTypeService = [];
            listTmp.forEach((_typeService) => {
                listTmpTypeService.push({
                    label: _typeService.name,
                    value: _typeService.id_work_order_type_service,
                })
            });
            setDataTypeService(listTmpTypeService)
           
        }
    }


    // #endregion


   







    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        totalWorkOrderUrgent, totalWorkOrderCritic, totalWorkOrderNormal, totalWorkOrder, sections, sectionsDefault, changeCheckbox,
        mDateRange1, mDateRange2, mSelectBranch, nim, branches,
        handleInputChange, handleSelectValues, handleListExam,
        getWorkOrders, loading, workOrders,
        modal, modalTitle, method, toggle,
        listExam, listProfile, handlePickerValues,
        modalCheckSpecimen, toggleCheckSpecimen, handleModalCheckSpecimen,
        printerPoints, handleInputChangePrinter, id_printer_point, navigateConfigPrices,
        navigateCaptureResults,
        toggleTooltip, toggleTooltipRelease, tooltip, tooltipRelease, navigateUpdate,
        selectedSections, sectiondefault, printSticker, printTicket,
        changeFiltersRangeNim, filterRangeNims, getNimsFromRange, handleViewFilters, viewFormFilters,
        //check specimen
        treeviewRef, itemsTree, onRenderItem, changeTree, savePendingMuestra, enable_tracking_toma,
        //refused specimen
        itemsTree2, treeviewRef2, handleModalRefusedSample, changeTreeRefused,
        enableViewTest, handleCloseViewTest, saveRefusedSample, validationsSG, validationsS,
        handleSelectValuesR, id_return_reason, reason, name_patient, gender,
        //trazabilidad sample
        modalFullRef, modalProps, close, handleTrazabilidad, data,
        dataSample, dataProcess2, dataProcess3, dataAnalitica, setloadingSample, dataTrackingReception,
        namePatient, idGender, dataSection, dataID,
        handleSelectValuesPrinter, statusOrder, mSelectStatus,
        resetLocalStorage,printStickerMicro,
        //type microsanitaria
        typeModalMicro,modalMicro,loadingMicro,toggleCadena,handleCadenaMicro,
        arrayMic,micDetail,dataMic,exportToExcel,
        listExams,mExam,
        //*type service
        mSelectTypeService,dataTypeService

    }
}
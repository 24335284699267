import React, { Fragment ,useState,useEffect } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Alert,Media,Nav, NavLink,Table  } from 'reactstrap'
import ApexCharts from 'react-apexcharts'
import { useBankingDeposits } from '../../hooks/reports/useBankingDeposits'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ToolTips } from '../../components/tooltips/ToolTips';
import ReactApexChart from 'react-apexcharts'



export const IndexBankingDeposits = () => {


  const {
        //*FILTER DAY
        start_date, end_date,handleInputChange,

        //*FORMVALUES DEPOSITS
        received,delivere,amount,observation, date_1,date_2,one,range,

        //*METHODS 
        GetReportDeposits,listMovements,indicatorAmount,indicatorCancell,indicatorPatient,indicatorAmountInvoice,
        GetReportPublic,listMovementsCalcule,

        //*CHART BANKING
        series,CurrentlyDeposits2,listMovementsInvoice,

        //* FORMAT NUMBERS
        number_format,

        //*LOADINGS
        loading,

        //*SAVE CALCULATE RECEIVED
        saveReceived,disabledSaveButton,
      
        //*VALIDATION
        validationsSG,

        //*ONCHANGE CALENDER
        date,onChange,validNotification,tileClassName,

       //*VIEW RECEIVED
       GetDeposits,listReceived,

       //*VIEW CREDIT, FULLY PAID
       Monthlysales,indicadorFullyPaid,series2,ChartPaymentConditions,
       ChartPaymentCancell,indicadorCredit,handleChangeTypeAction,type_action, listFilter,
       
       //*VIEW INVOICE PUBLIC GENERAL AND GLOBAL
       countPublic,countGeneral,dataFilter,GetFilterInvoices,

       //*FILTER PAY METHOD
       filterEfectivo, setFilterEfectivo,
       filterTC,setFilterTC,
       filterTD,setFilterTD,
       filterTransferencias, setFilterTransferencias,

       //*EXPORT EXCEL
       MakeExcelInvoices,
       
       //*FILTER COMPANY
       type_company,handleTypeCompany,totalDepositSend,

      //*DETAIL BRANCH
      listDetailBranch,brach,listDetailBranchInvoice,

      //*CHART BRACH
      ChartPaymentConditionsTable,

      //*SIZE CARDS
      sizeCard,toggleSize,
  
      //*CHART AND INFO DEPOSITS
   amountDesposit,arrayDeposits, ChartPaymentConditionsTable1

  } =useBankingDeposits();


  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    let sum = 0;
    brach.forEach((_find) => {
      if (_find.id_branch && listDetailBranch[_find.id_branch]) {
        listDetailBranch[_find.id_branch].forEach((item) => {
          sum += item.amount;
        });
      }
    });
    setTotalAmount(sum);
  }, [brach, listDetailBranch]);


     //*VALID SIZE
      let _valid = ChartPaymentConditions?.series.length === 1 ? 580 : 710
      let _valid2 = ChartPaymentCancell?.series.length === 1 ? 580 : 710

        //*ALERT
        const AlertItem = props => {
            const { item } = props;
            return (
                <Alert className="alert-dismissible"
                    color={item.alertcolor}
                    isOpen={true}
                    target={"Alert-" + item.id}
                >
                    {item.icon}
                    <p>{item.alerttxt}</p>
                </Alert>
            );
        }

    //*VALID CLASS PAYMETHOD
        //!label
        function getClassForPayMethod(id_pay_method) {
          switch (id_pay_method) {
            case 1:
              return "efectivo";
            case 2:
              return "tarjeta-credito";
            case 4:
              return "tarjeta-debito";
            case 8:
              return "transferencia-electronica";
            default:
              return "paypal-method"; 
          }
        }
        //!chart
        function getClassForPayMethod1(id_pay_method) {
          switch (id_pay_method) {
            case 1:
              return  "#4ad610";
            case 2:
              return "#ffc107";
            case 4:
              return '#007bff';
            case 8:
              return '#46AFE5';
            default:
              return "#183462"; 
          }
        }
    //*END VALID CLASS PAYMETHOD


      
      //*VALID AMOUNT CASH
        const totalRow = (
              <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
              <td ></td>
              <td className='labeltheadInvoice'>Total:</td>
              <td  className='labeltheadInvoice'>${number_format(totalAmount, 2)}</td>
              <td ></td>
          </tr> 
      );

      //*VALID AMOUNT INVOICE
      const totalRow2 = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td className='labeltheadInvoice'>Total:</td>
        <td  className='labeltheadInvoice'>${number_format(indicatorAmountInvoice, 2)}</td>
        <td ></td>
    </tr> 
    );



    console.log(totalAmount);



    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0 mb-5'>
              {
                // !MENU ACTION
              }
              <div className="customizer-links">
                  <Nav className="flex-column nac-pills">
                      <NavLink  onClick={() => handleChangeTypeAction("R")} >
                          <div className="settings"  >
                          <i className="fa fa-tags"></i>
                          </div>
                          <span>{"Recuperación y Creditos"}</span>
                      </NavLink>
                      <NavLink  onClick={() => handleChangeTypeAction("C")} >
                          <div className="settings" >
                          <i className="fa fa-times-circle"></i>
                          </div>
                          <span>{"Cancelaciones"}</span>
                      </NavLink>
                      <NavLink  onClick={() => handleChangeTypeAction("F")} >
                          <div className="settings" >
                          <i className="fa fa-file-pdf-o"></i>
                          </div>
                          <span>{"Detallado de Facturas"}</span>
                      </NavLink>
                      <NavLink onClick={() => handleChangeTypeAction("D")}  >
                          <div className="settings" >
                          <i className="fa fa-credit-card"></i>
                          </div>
                          <span>{"Generar Deposito"}</span>
                      </NavLink>
                      <NavLink onClick={() => handleChangeTypeAction("A")}  >
                          <div className="settings" >
                          <i className="fa fa-calendar"></i>
                          </div>
                          <span>{"Dashboard"}</span>
                      </NavLink>
                    
                  </Nav>
              </div>
              {
                //*DASHBOARD CASH AND INVOICE
              }
              <Row className={type_action === "A" ? 'dashboard-sec box-col-12' :  (["F", "R", "C", "D"].includes(type_action) ? 'd-none' : 'dashboard-sec box-col-12')} >
                <Col sm="12" style={{textAlign: "center"}}>
                    { 
                      loading &&    <i className="fa fa-spin fa-spinner font-primary f-46"></i>
                    }
                    {   //! CALCULATE PRICES
                      listMovementsCalcule.length > 0 ?
                      <Row>
                        {listMovementsCalcule.map((item, index) => (
                          <Col sm="2" key={index}>
                            <Card className='shadow' style={{ height: "70px" }} >
                          
                              <Row>
                                <Col sm="4" className='p-r-0 p-l-0' >
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                </Col>
                                <Col sm="8">
                                {item.pay_methods.map((payMethod, payIndex) => (
                                    <div key={payIndex}  >
                                      {(() => {
                                        switch (payMethod) {
                                          case 1:
                                            return <div>
                                              <label className='f-w-600 f-12 badge badge-light-secondary' >Efectivo</label> <br />
                                              <b className='f-14' >${number_format(item.totalAmount,2)}</b>
                                            </div>
                                          case 2:
                                            return <div>
                                            <label className='f-w-600 f-12 badge badge-light-secondary' >T.C</label> <br />
                                            <b className='f-14' >${number_format(item.totalAmount,2)}</b>
                                          </div>                     
                                          case 4:
                                            return <div>
                                            <label className='f-w-600 f-12 badge badge-light-secondary' >T.D</label> <br />
                                            <b className='f-14' >${number_format(item.totalAmount,2)}</b>
                                          </div>                       
                                          case 8:
                                            return <div>
                                            <label className='f-w-600 f-12 badge badge-light-secondary' >Tranferencia</label> <br />
                                            <b className='f-14' >${number_format(item.totalAmount,2)}</b>
                                          </div>           
                                                    default:
                                            return null;
                                        }
                                      })()}
                                    </div>
                                  ))}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      : null
                    }
                  </Col>
                  {
                    //* CHART EGRESS
                  }
                <Col xl="12 xl-100" >
                <Card className=" earning-card ">
                  <CardBody className="p-0">
                    <Row className="m-0">
                      <Col xl="3" className="">
                        <Row className="m-0 earning-card-invoice">
                        <Col xl="12" className="p-0 left_side_earning">
                            <Row>
                              <Col sm="4" >

                              <div id='btn-tooltip-filter-public' className={type_company === '1' ? 'typeInvoicelabel' : 'InvalidtypeCash'} onClick={() => handleTypeCompany("1")}  >
                                  <i className="fa fa-user f-20">
                                    <ToolTips
                                      placement="top"
                                      dataTarget='btn-tooltip-filter-public'
                                      dataText='Particular'
                                    />
                                  </i>
                                </div>
                              </Col>
                              <Col sm="4">
                              <div id='btn-tooltip-filter-company' className={type_company === '2' ? 'typeInvoicelabel' : 'InvalidtypeCash'} onClick={() => handleTypeCompany("2")}  >
                                  <i className="icofont icofont-industries-alt-4 f-20 f-20">
                                    <ToolTips
                                      placement="top"
                                      dataTarget='btn-tooltip-filter-company'
                                      dataText='Empresa'
                                    />
                                  </i>
                                </div>
                              </Col>
                              <Col sm="4">
                              <div id='btn-tooltip-filter-clean' className={type_company === null ? 'typeInvoicelabel' : 'InvalidtypeCash'} onClick={() => handleTypeCompany(null)}  >
                                  <i className="fa fa-plus-square-o f-20">
                                    <ToolTips
                                      placement="top"
                                      dataTarget='btn-tooltip-filter-clean'
                                      dataText='Todos'
                                    />
                                  </i>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <br />

                          <Col xl="12" className="p-0 left_side_earning">
                            <label className='txt-secondary f-14'>Fecha Inicio</label>
                            <br />
                            <Input type='date' className='border-0 mr-2' name='start_date' value={start_date} onChange={(e) => handleInputChange(e)}></Input>
                          </Col>
                          <Col xl="12" className="p-0 left_side_earning">
                          <label className='txt-secondary f-14'>Fecha Final</label>
                          <br />
                          <Input type='date'  className='border-0 mr-2' name='end_date' value={end_date} onChange={(e) => handleInputChange(e)}> </Input>
                          </Col>
                          <br />
                          <Col sm="12">
                              <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" onClick={GetReportDeposits}   >Buscar</a></Col>

                          </Col>
                        
          
                          <Col sm="12" className='border-top-deposits ' > <br />
                            <Row>
                            
                              <Col sm="2">
                              </Col>
                              <Col sm="10" className='p-r-0' >
                                <Col xl="12" md="12" sm="12" className="p-l-0">
                                  <div className="media p-0">
                                  <div className="media-left type-Card-invoice-Amount"><i className="fa fa-group"></i></div>
                                    <div className="media-body">
                                      <label className='label-invoice-Indications' >{"Ordenes Generadas"}</label> <br />
                                      <b className='f-14'>{indicatorPatient}</b>
                                    </div>
                                  </div>
                                </Col>
                              {/* <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" onClick={GetReportPublic}   >Calculado</a></Col> */}
                              </Col>
                            </Row>
                          </Col>
                          <br />
                        </Row>
                      </Col>
                      <Col xl="9" className="p-0">
                        <div className="chart-right p-1">
                          <Row className="m-0 p-tb p-0">
                            <Col xl="10" md="10" sm="10" className="col-12 p-0">
                            <div className="inner-top-left" style={{ display: 'flex' }}>
                                  <ul className="list-unstyled" style={{ marginRight: '75px' }}>
                                    <label className='f-w-600 f-12 badge badge-light-secondary' >Efectivo</label>
                                    {listMovements?.map((movement) => {
                                      if (movement.id_pay_method === 1) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-primary'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                    {listMovementsInvoice?.map((movement) => {
                                      if (movement.id_pay_method === 1) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-danger'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </ul>

                                  <ul className="list-unstyled" style={{ marginRight: '55px' }}>
                                    <label className='f-w-600 f-12 badge badge-light-secondary'>Tarjeta de Crédito</label>
                                    {listMovements?.map((movement) => {
                                      if (movement.id_pay_method === 2) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-primary'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                    {listMovementsInvoice?.map((movement) => {
                                      if (movement.id_pay_method === 2) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-danger'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </ul>

                                  <ul className="list-unstyled" style={{ marginRight: '55px' }}>
                                    <label className='f-w-600 f-12 badge badge-light-secondary'>Tarjeta de Débito</label>
                                    {listMovements?.map((movement) => {
                                      if (movement.id_pay_method === 4) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-primary'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                    {listMovementsInvoice?.map((movement) => {
                                      if (movement.id_pay_method === 4) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-danger'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </ul>

                                  <ul className="list-unstyled" style={{ marginRight: '55px' }}>
                                    <label className='f-w-600 f-12 badge badge-light-secondary'>Transferencia</label>
                                    {listMovements?.map((movement) => {
 
                      
                                      if (movement.id_pay_method === 8) {     
                                     //   const amountNew = movement.amount + amountDesposit;
                                 
                                        return (
                                          <div key={movement.id_pay_method} className='txt-primary'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                    {listMovementsInvoice?.map((movement) => {
                                      if (movement.id_pay_method === 8) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-danger'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </ul>

                                  <ul className="list-unstyled" style={{ marginRight: '15px' }}>
                                    <label className='f-w-600 f-12 badge badge-light-secondary'>Mercado Pago</label>
                                    {listMovements?.map((movement) => {
                                      if (movement.id_pay_method === 12) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-primary'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                    {listMovementsInvoice?.map((movement) => {
                                      if (movement.id_pay_method === 12) {
                                        return (
                                          <div key={movement.id_pay_method} className='txt-danger'>
                                            <b className='f-12'> ${number_format(movement.amount,2)}</b>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </ul>

                                </div>
                            </Col>
                            <Col xl="2" md="2" sm="12" className="col-12 p-0 justify-content-end">
                              <div className="inner-top-right">
                                <ul className="d-flex list-unstyled justify-content-end">
                                  <li>{"Cobrado"}</li>
                                  <li>{"Facturado"}</li>
                                </ul>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12" className='p-l-0 p-r-0' >
                              <CardBody className="p-0">
                                <div className="current-sale-container">
                                  {
                                <ApexCharts id="chart-currently" options={CurrentlyDeposits2.options} series={CurrentlyDeposits2.series} type='area' height={240} />
                                  }
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </div>
                        <Row className="border-top-deposits m-0">
                          <Col sm="6" md="3" lg="3"   className="p-l-0 p-r-0">
                            <div className="media p-0">
                            <div className="media-left media-invoice "><i className="fa fa-group"></i></div>
                              <div className="media-body">
                                <label className='label-invoice-Indications' >{"Facturas Generadas"}</label> 
                                <br />
                                <b className='f-14'>{indicatorCancell}</b>
                              </div>
                            </div>
                          </Col>
                          <Col sm="6" md="3" lg="3" className='p-l-0 p-r-0'  >
                            <div className="media p-0">
                            <div className="media-left "><i className="icofont icofont-cur-dollar"></i></div>
                              <div className="media-body">
                              <label className='label-invoice-Indications'>{"Ingreso Total"}</label> <br />
                              <b className='f-14'> ${number_format(totalAmount,2)}</b>
                              </div>
                            </div>
                          </Col>
                          <Col sm="6" md="3" lg="3" className="p-r-0">
                            <div className="media p-0">
                              <div className="media-left media-invoice"><i className="icofont icofont-cur-dollar"></i></div>
                              <div className="media-body">
                              <label className='label-invoice-Indications '>{"Total Facturado"}</label> <br />
                              <b className='f-14'> ${number_format(indicatorAmountInvoice,2)}</b>
                              </div>
                            </div>
                          </Col>
                          <Col  sm="6" md="3" lg="3" className="p-r-0 p-r-0">
                            <div className="media p-0">
                              <div className="media-left type-Card-invoice-Amount "><i className="icofont icofont-cur-dollar"></i></div>
                              <div className="media-body">
                              <label className='label-invoice-Indications'>{"Ingreso Total en cuenta"}</label> <br />
                              <b className='f-14'> ${number_format(totalDepositSend,2)}</b>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                </Col>
                {
                    //* END CHART EGRESS
                  }
                <br />
                {
                //*INDICATORS CASH FLOW AND INVOICES
                }
                {
                  //!TYPE CASH FLOW
                }
                <Col sm="12">
                <Row className='mt-2' >
                  <Col sm="12" md={sizeCard ? "12" : "6"} lg={sizeCard ? "6" : "12"} >
                    <Card className='shadow'>
                     <CardHeader className='text-center p-1 bg-primary'>
                      <Row>
                        <Col sm="11">
                        <h6 className='pt-1'>Ingreso Total</h6>

                        </Col>
                        <Col sm="1"> 
                        {sizeCard ? <i className="icofont icofont-ui-zoom-out f-20" onClick={toggleSize} ></i> : <i className="icofont icofont-ui-zoom-in f-20" onClick={toggleSize} ></i>}
                        </Col>
                      </Row>
                     </CardHeader>
                     <CardBody className='p-2' >
                     <Col sm="12" className='p-l-0 p-r-0'   >
                      <Row>
                      <Col sm="12"  lg={sizeCard ? "12" : "6"}  className='p-l-0 p-r-0' >
                      <Card className='shadow'  >
                        <CardHeader className='p-2 text-center'>
                                <h5 className='card-title f-14'>Resúmen por sucursales</h5>
                        </CardHeader>
                      <CardBody className='p-2' >
                        <div className='table-wrapper'>
                        <Table className='b-r-5 table table-sm'>
                          <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#033d5b" }}>
                                  <th className='labeltheadInvoice'>#</th>
                                  <th className='labeltheadInvoice'>Sucursal</th>
                                  <th className='labeltheadInvoice'>Metodo de pago</th>
                                  <th className='labeltheadInvoice'>Monto</th>
                              </tr>
                          </thead>
                          <tbody>
                              {brach?.map((_find, _key) => {
                                  return (
                                      <tr key={_key}>
                                          <td>{_key + 1}</td>
                                          <td>
                                              <i className="icofont icofont-building-alt"></i>
                                              <label className='label-branch-report'>{_find.name}</label>
                                          </td>
                                          <td style={{ padding: '10px' }}>
                                              {_find.id_branch && listDetailBranch[_find.id_branch] && listDetailBranch[_find.id_branch].map((item, index) => (
                                                  <div className={getClassForPayMethod(item.id_pay_method)} key={index} style={{ marginBottom: '10px' }}>
                                                      {(() => {
                                                          switch (item.id_pay_method) {
                                                              case 1:
                                                                  return "Efectivo";
                                                              case 2:
                                                                  return "Tarjeta de Crédito";
                                                              case 4:
                                                                  return "Tarjeta de Débito";
                                                              case 8:
                                                                  return "Transferencia Electronica";
                                                              default:
                                                                  return "Mercado Pago";
                                                          }
                                                      })()}
                                                  </div>
                                              ))}
                                          </td>
                                          <td>
                                              {_find.id_branch && listDetailBranch[_find.id_branch] && listDetailBranch[_find.id_branch].map((item, index) => (
                                                  <div className={getClassForPayMethod(item.id_pay_method)} key={index} style={{ marginBottom: '10px' }}>
                                                      ${number_format(item.amount, 2)}
                                                  </div>
                                              ))}
                                          </td>
                                      </tr>
                                  );
                              })}
                              {totalRow}
                          </tbody>
                      </Table>

                        </div>
                       
                      </CardBody>
                      </Card>

                      <br /> 
                      {
                        arrayDeposits?.length > 0 ?
                        <Col sm="12" className='p-l-0 p-r-0'>
                             <Card className='shadow'>
                        <CardHeader className='p-2 text-center'>
                                  <h5 className='card-title f-14'>Depositos Bancarios realizados</h5>
                          </CardHeader>
                          <CardBody className='p-2' >
                            <div className='' >
                            <Table className='b-r-5 table table-sm'>
                          <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#033d5b" }}>
                                  <th className='labeltheadInvoice'>#</th>
                                  <th className='labeltheadInvoice'>Usuario responsable</th>
                                  <th className='labeltheadInvoice'>Monto Depositado</th>
                                  <th className='labeltheadInvoice'>Comentario</th>
                              </tr>
                          </thead>
                          <tbody>
                          {arrayDeposits?.length > 0 && arrayDeposits?.map((_find, _key) => {
                              return (
                                  <tr key={_key}>
                                      <td>{_key + 1}</td>
                                      <td>{_find?.user_received ?? ""}</td>
                                      <td className='transferencia-electronica'>${number_format(_find?.amount, 2) ?? ""}</td>
                                      <td>{_find?.observation ?? ""}</td>
                                  </tr>
                              )
                          })}
                          <tr style={{ height: '20px' }}></tr>
                          <tr style={{ backgroundColor: 'rgb(0 26 40)' }}>
                          <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }}></td>
                          <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }} className='labeltheadInvoice'>Total:</td>
                          <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }} className='labeltheadInvoice'>${number_format(arrayDeposits.reduce((acc, curr) => acc + (curr?.amount ?? 0), 0), 2)}</td>
                          <td style={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }}></td>
                      </tr>
                      </tbody>
                      </Table>

                            </div>
                         
                          </CardBody>
                          <br /> 
                         <CardHeader className='p-2 text-center'>
                                  <h5 className='card-title f-14'>Indicador De Depositos Bancarios</h5>
                          </CardHeader> <br />
                          {arrayDeposits?.length > 0 && (
                          <Col sm="12"  style={{textAlign : "-webkit-center"}}>
                              {console.log("ChartPaymentConditionsTable1:", ChartPaymentConditionsTable1)}
                              <ReactApexChart 
                                   options={{
                                    ...ChartPaymentConditionsTable1?.options,
                                    legend: {
                                        show: true,
                                    },
                                    tooltip: {
                                        enabled: true,
                                    },
                                    labels: arrayDeposits.map(item => {
                                      const date = new Date(item.date_1); 
                                      const year = date.getFullYear(); 
                                      const month = String(date.getMonth() + 1).padStart(2, '0'); 
                                      const day = String(date.getDate()).padStart(2, '0'); 
                                      return `${year}-${month}-${day}`; 
                                  }),
                                
                                }}
                                  series={arrayDeposits.map(item => item.amount)}
                                  type='donut' 
                                  height={380} 
                                  width={380} 
                              />
                          </Col>
                          )}
  
                        </Card>

                      
                            </Col>
                            :
                            null
                          }
                         </Col>
                      <Col sm="12" lg={sizeCard ? "12" : "6"}   className='p-l-0 p-r-0' >
                        <CardHeader className='p-2 text-center'>
                              <h5 className='card-title f-14'>Indicador Global</h5>
                          </CardHeader>
                          <CardBody className='p-2' >
                            <Col sm="12" >
                              {
                                Object.values(listDetailBranch).map((branch, _key) => {
  
                                  //*VALID SIZE CHARTS
                                  let _calculateSize = branch.map(_find => _find.amount).length
  
                                  let _valid1 = _calculateSize === 4 ? 430 : 420
                                  let _valid2 = _calculateSize === 4 ? 430 : 420
                                  //*END VALID SIZE CHARTS
                                  
                                  let _findBranch = brach.find(info => info.id_branch === branch[0].id_branch);
                                  let _branchName = _findBranch ? _findBranch.name : 'Nombre de sucursal no encontrado';
  
                                  let _amountBranchs = branch.reduce((total, item) => total + item.amount, 0);
                                  let _find = [...new Set(branch.map(item => item.id_pay_method))];
  
                                  const labels = _find.map((_id_pay_method) => {
                                      let _namePayMethod;
                                    switch (_id_pay_method) {
                                      case 1:
                                        _namePayMethod = "Efectivo";
                                        break;
                                      case 2:
                                        _namePayMethod = "Tarjeta de Crédito";
                                        break;
                                      case 4:
                                        _namePayMethod = "Tarjeta de Débito";
                                        break;
                                      case 8:
                                        _namePayMethod = "Transferencia Electrónica";
                                        break;
                                      default:
                                        _namePayMethod = "Mercado Pago";
                                    }
                                    return {
                                      name: _namePayMethod,
                                      color: getClassForPayMethod1(_id_pay_method) 
                                    };
                                  });
  
                                  let chartOptions = {
                                      ...ChartPaymentConditionsTable.options,
                                      labels: labels.map(label => label.name),
                                      colors: labels.map(label => label.color),
                                      legend: {
                                        show: true, 
                                        position: 'right', 
                                      }
                                    };
                                return (
                                  <div key={_key}>
                                      <Col sm="12" style={{textAlign:"center"}} >
                                        <b className='f-16'  > Total:${number_format(_amountBranchs,2)} / {_branchName} </b>
                                        </Col>
                                        {
                                          //*COMPONENT CHART
                                        }
                                        <ReactApexChart
                                          options={chartOptions}
                                          series={branch.map(_find => _find.amount)}
                                          type='donut'
                                          height={_valid1}
                                          width={_valid2}
                                        />
                                  </div>
                                );
                              })}
                            </Col>
                          </CardBody>

                         
                      </Col>
                      </Row>
                     
                     </Col>
                     </CardBody>
                    </Card>
                  </Col>
                  {
                    //!TYPE INVOICES
                  }
                  <Col sm="12" md={sizeCard ? "12" : "6"} lg={sizeCard ? "6" : "12"}>
                    <Card className='shadow'  >
                     <CardHeader className='text-center p-1 bg-danger'>
                     <Row>
                        <Col sm="11">
                        <h6 className='pt-1'>Total Facturado</h6>

                        </Col>
                        <Col sm="1"> 
                        {sizeCard ? <i className="icofont icofont-ui-zoom-out f-20" onClick={toggleSize} ></i> : <i className="icofont icofont-ui-zoom-in f-20" onClick={toggleSize} ></i>}
                        </Col>
                      </Row>
                     </CardHeader>
                     <CardBody className='p-2'>
                      <Col sm="12"className='p-l-0 p-r-0'  >
                        <Row>
                          <Col  sm="12"  lg={sizeCard ? "12" : "6"}  className='p-l-0 p-r-0'>
                            <Card className='shadow'>
                              <CardHeader className='p-2 text-center'>
                                  <h5 className='card-title f-14'>Resúmen por sucursales</h5>
                              </CardHeader>
                              <CardBody className='p-2'>
                                <div className='table-wrapper'>
                                <Table className='b-r-5 table table-sm'>
                              <thead className='b-r-5'>
                                      <tr style={{ backgroundColor: "#033d5b" }}>
                                        <th className='labeltheadInvoice'>#</th>
                                        <th className='labeltheadInvoice'>Sucursal</th>
                                        <th className='labeltheadInvoice'>Metodo de pago</th>
                                        <th className='labeltheadInvoice'>Monto</th>
                                      </tr>
                                </thead>
                                <tbody>
                                {
                                brach?.map((_find, _key) => {
                                  return(
                                    <tr key={_key}>
                                      <td>{_key + 1}</td>
                                      <td><i class="icofont icofont-building-alt"></i>  <label className='label-branch-report'>{_find.name}</label> </td>
                                      <td style={{ padding: '10px' }}  >
                                      {_find.id_branch && listDetailBranchInvoice[_find.id_branch] && listDetailBranchInvoice[_find.id_branch].map((item, index) => (
                                        <div className={getClassForPayMethod(item.id_pay_method)}  key={index} style={{ marginBottom: '10px' }} >
                                          {(() => {
                                            switch (item.id_pay_method) {
                                              case 1:
                                                return "Efectivo";
                                                case 2:
                                                  return "Tarjeta de Crédito";
                                                  case 4:
                                                    return "Tarjeta de Débito";
                                                    case 8:
                                                      return "Transferencia Electronica";
                                              default:
                                                return "Mercado Pago";
                                            }
                                          })()}
                                        </div>
                                      ))}
                                    </td>
                                      <td>
                                        {_find.id_branch && listDetailBranchInvoice[_find.id_branch] && listDetailBranchInvoice[_find.id_branch].map((item, index) => (
                                          <div  className={getClassForPayMethod(item.id_pay_method)}  key={index} style={{ marginBottom: '10px' }} >
                                            ${number_format(item.amount,2)} 
                                          </div>
                                        ))}
                                      </td>
                                    </tr>
                                  );
                                })}
                                 {totalRow2}
                                </tbody>
                              </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>

                          


                          <Col sm="12" lg={sizeCard ? "12" : "6"}  className='p-l-0 p-r-0'>
                          <CardHeader className='p-2 text-center'>
                              <h5 className='card-title f-14'>Indicador Global</h5>
                          </CardHeader>
                          <CardBody className='p-2' >
                          <Col sm="12" >
                            {
                              Object.values(listDetailBranchInvoice).map((branch, _key) => {

                                //*VALID SIZE CHARTS
                                let _calculateSize = branch.map(_find => _find.amount).length

                                let _valid1 = _calculateSize === 4 ? 430 : 420
                                let _valid2 = _calculateSize === 4 ? 430 : 420
                                //*END VALID SIZE CHARTS

                                let _findBranch = brach.find(info => info.id_branch === branch[0].id_branch);
                                let _branchName = _findBranch ? _findBranch.name : 'Sucursal no encontrada';

                                let _amountBranchs = branch.reduce((total, item) => total + item.amount, 0);
                                let _find = [...new Set(branch.map(item => item.id_pay_method))];

                                //!type method payments

                                const labels = _find.map((_id_pay_method) => {
                                    let _namePayMethod;
                                  switch (_id_pay_method) {
                                    case 1:
                                      _namePayMethod = "Efectivo";
                                      break;
                                    case 2:
                                      _namePayMethod = "Tarjeta de Crédito";
                                      break;
                                    case 4:
                                      _namePayMethod = "Tarjeta de Débito";
                                      break;
                                    case 8:
                                      _namePayMethod = "Transferencia Electrónica";
                                      break;
                                    default:
                                      _namePayMethod = "Mercado Pago";
                                  }
                                  return {
                                    name: _namePayMethod,
                                    color: getClassForPayMethod1(_id_pay_method) 
                                  };
                                });

                                //!options chart donut
                                let chartOptions = {
                                    ...ChartPaymentConditionsTable.options,
                                    labels: labels.map(label => label.name),
                                    colors: labels.map(label => label.color),
                                    legend: {
                                      show: true, 
                                      position: 'right', 
                                    }
                                  };
                              return (
                                <div key={_key}>
                                    <Col sm="12" style={{textAlign:"center"}} >
                                      <b className='f-16'  > Total:${number_format(_amountBranchs,2)} / {_branchName} </b>
                                      </Col>
                                      {
                                        //*COMPONENT CHART
                                      }
                                      <ReactApexChart
                                        options={chartOptions}
                                        series={branch.map(_find => _find.amount)}
                                        type='donut'
                                        height={_valid1}
                                        width={_valid2}
                                      />
                                </div>
                              );
                            })}
                          </Col>
                          </CardBody>
                          </Col>
                        </Row>
                      </Col>
                     </CardBody>
                    </Card>
                  </Col>
                </Row>
                </Col>
              </Row>  
              {
                //*END DASHBOARD CASH AND INVOICE
              } 
              {
               //*SALDOS
              } 
              <Col sm="12" className={type_action === "R" ? '' : 'd-none'} >
                <Row>
                <Col  sm="12" md="6" lg="6">
                  <Row>
                      <Col sm="6" className='p-l-0' >  
                        <Card className="cardFully"  style={{ height: "120px" }} > 
                          <Col sm="12">
                            <Row>
                              <Col sm="3" className='p-l-0 p-r-0' > <br />
                              <img className="img-fluid" src={require("../../assets/images/dashboard/welcome.png")} alt="" />
                              </Col>
                              <Col sm="9">
                                <br />
                                <label className='f-16 labelFullyPaid' >Saldo Recuperado</label> <br />
                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content">
                                    <div className="progress-gradient-primary-fully-paid" role="progressbar" style={{width: "85%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="animate-circle"></span></div>
                                  </div>
                                  <br />
                                <label className='f-22 labelFullyPaid' >${number_format(indicadorFullyPaid,2)}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Col>
                      <Col sm="6" className='p-l-0' > 
                        <Card className="cardFully" style={{ height: "120px" }}> 
                        <Col sm="12">
                            <Row>
                              <Col sm="3" className='p-l-0 p-r-0' > <br />
                              <img className="img-fluid" src={require("../../assets/images/dashboard/welcome.png")} alt="" />
                              </Col>
                              <Col sm="9">
                              <br />
                                <label className='f-16 labelFullyPaid' >Credito Pendiente</label> <br />
                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content">
                                    <div className="progress-gradient-primary-fully-paid" role="progressbar" style={{width: "85%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="animate-circle"></span></div>
                                  </div>
                                  <br />
                                <label className='f-22 labelFullyPaid' >${number_format(indicadorCredit,2)}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="11">
                        <div className=""> <br /> <br />
                        {  
                          series2.length === 0 ? "" :  <ApexCharts id="spaline-chart" options={Monthlysales.options} series={Monthlysales.series} type='area' height={185} />
                        }
                      </div>
                      </Col>
                  
                    </Row>
                  </Col>
                  <Col  sm="12" md="6" lg="6">
                    <Card className='shadow' >
                    <CardHeader className='text-center p-1 bg-success'>
                      <h6 className='pt-1'>Metodos de recuperación</h6>
                    </CardHeader>
                      <br />
                      {
                        ChartPaymentConditions?.series.length ===  0 ?
                        <div style={{ textAlign: "-webkit-center" }}>
                        <p className='f-w-600 f-14 badge badge-light-primary'>Sin resultados.&nbsp; &nbsp; {start_date} &nbsp;   /&nbsp;   {end_date} </p> <br />

                        <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "62%" }} alt="" />
                      </div>
                      :
                      <ApexCharts options={ChartPaymentConditions?.options} series={ChartPaymentConditions?.series} type='donut' height={380} width={_valid} />


                      }

                    </Card>
                  <br />
                  </Col>
                </Row>          
              </Col>
              {
               //*END SALDOS
              } 
              {
                //*CREDIT
              }
              <Col sm="12" className={type_action === "C" ? '' : 'd-none'} >
                <Row>
                <Col sm="12" md="6" lg="6">
                  <Row>
                      <Col sm="6" className='p-l-0' >  
                        <Card className="cardFully"  style={{ height: "120px" }} > 
                          <Col sm="12">
                            <Row>
                              <Col sm="3" className='p-l-0 p-r-0' > <br />
                              <img className="img-fluid" src={require("../../assets/images/dashboard/welcome.png")} alt="" />
                              </Col>
                              <Col sm="9">
                                <br />
                                <label className='f-16 labelFullyPaid' >Facturas Globales</label> <br />
                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content">
                                    <div className="progress-gradient-primary-fully-paid" role="progressbar" style={{width: "85%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="animate-circle"></span></div>
                                  </div>
                                  <br />
                                <label className='f-22 labelFullyPaid' >${number_format(indicadorFullyPaid,2)}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Col>
                      <Col sm="6" className='p-l-0' > 
                        <Card className="cardFully" style={{ height: "120px" }}> 
                        <Col sm="12">
                            <Row>
                              <Col sm="3" className='p-l-0 p-r-0' > <br />
                              <img className="img-fluid" src={require("../../assets/images/dashboard/welcome.png")} alt="" />
                              </Col>
                              <Col sm="9">
                              <br />
                                <label className='f-16 labelFullyPaid' >Facturas solicitadas</label> <br />
                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content">
                                    <div className="progress-gradient-primary-fully-paid" role="progressbar" style={{width: "85%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="animate-circle"></span></div>
                                  </div>
                                  <br />
                                <label className='f-22 labelFullyPaid' >${number_format(0,2)}</label>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                    
                  </Col>
                <Col  sm="12" md="6" lg="6">
                    <Card className='shadow' >
                    <CardHeader className='text-center p-1 bg-danger'>
                        <h6 className='pt-1'>Facturas canceladas</h6>
                    </CardHeader>
                            <br /> 
                            {
                              ChartPaymentCancell?.series.length ===  0 ?
                              <div style={{ textAlign: "-webkit-center" }}>
                                  <p className='f-w-600 f-14 badge badge-light-primary'>Sin resultados.&nbsp; &nbsp; {start_date} &nbsp;   /&nbsp;   {end_date} </p> <br />

                                  <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "62%" }} alt="" />
                                </div>
                                :
                                <ApexCharts options={ChartPaymentCancell?.options} series={ChartPaymentCancell?.series} type='donut' height={380} width={_valid2} />


                            }
                    </Card>
                  <br />
                  </Col>
                            </Row>
              </Col>
              {
                //*END CREDIT
              }
              {
                //*INVOICES GLOBALES AND PATIENT
              }
              <Col sm="12" className={type_action === "F" ? "" : "d-none"} >
              <Container fluid={true}>
                <Row>
                  <Col xl="3" className="box-col-6 pr-0 file-spacing">
                    <div className="file-sidebar">
                      <Card>
                        <CardBody>
                          <hr />
                          <ul>
                            <li>
                              <Col sm="12" className="m-t-15">
                                <Row>
                                <Col xl="6" className="p-0 left_side_earning">
                                  <Input type='date' className='border-0 mr-2' name='start_date' value={start_date} onChange={(e) => handleInputChange(e)}></Input>
                               </Col>   
                          
                              <Col xl="6" className="p-0 left_side_earning">
                              <Input type='date'  className='border-0 mr-2' name='end_date' value={end_date} onChange={(e) => handleInputChange(e)}> </Input>
                              </Col> 
                                </Row>
                            </Col>
                            </li>
                            <li>
                            <div className="btn btn-outline-primary text-center p-t-0 p-b-0" onClick={GetReportDeposits} >Buscar</div>
                            <br />
                            <div className="btn btn-outline-primary text-center p-t-0 p-b-0" onClick={GetReportDeposits} >General</div>


                    </li>
                          </ul>
                          <hr />
                          <ul>
                            <li  >
                              <div className="pricing-plan">
                                <h6 >Facturas Generadas </h6>
                                <h5 className='txt-secondary' >${number_format(countPublic,2)}</h5>
                                <p>{"Pacientes que solicitaron su factura"}</p>
                               
                                <div className="btn btn-outline-primary btn-xs " onClick={() => GetFilterInvoices(1) }  ><i className="fa fa-eye"></i></div>
                                <img className="bg-img" src={require("../../assets/images/dashboard/folder.png")} alt="" />
                              </div>
                            </li>
                            <li >
                              <div className="pricing-plan">
                                <h6>Facturas Globales</h6>
                                <h5 className='generalPublic' >${number_format(countGeneral,2)}</h5>
                                <p> {"Pacientes que no solicitaron factura"}</p>
                                <div className="btn btn-outline-primary btn-xs " onClick={() => GetFilterInvoices(2) }  ><i className="fa fa-eye"></i></div>
                                <img className="bg-img" src={require("../../assets/images/dashboard/folder1.png")} alt="" />
                              </div>
                            </li>
                            <li>
                              <div className="pricing-plan"> 
                                <h6>Facturas Solicitadas </h6>
                                <h5>{0}</h5>
                                <p>{"Pacientes que solicitaron su factura pero aun no se genera"}</p>
                                <div className="btn btn-outline-primary btn-xs"><i className="fa fa-eye"></i></div>
                                <img className="bg-img" src={require("../../assets/images/dashboard/folder.png")} alt="" />
                              </div>
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                  <Col xl="9" md="12" className="box-col-12">
                    <div className="file-content">
                    {
                      dataFilter.length > 0 ?
                      <Row>
                        {dataFilter.map((item, index) => (

                          <Col xl="3" md="3" sm="5"  key={index}>
                            <Card className='shadow' style={{ height: "70px" }} >
                          
                              <Row>
                                <Col sm="4" className='p-r-0 p-l-0' >
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />
                                </Col>
                                <Col sm="8">
                                  <div>
                                      {
                                       item.id_pay_method === 1 ?
                                       <div>
                                       <label className='f-w-600 f-12 badge badge-light-secondary'>Efectivo</label> <br />
                                       <b className='f-14' >${number_format(item.total_i,2)}</b>
                                       </div>
                                      :
                                      (item.id_pay_method === 2 ) ? 
                                      <div>
                                      <label className='f-w-600 f-12 badge badge-light-secondary'>T.C</label> <br />
                                      <b className='f-14' >${number_format(item.total_i,2)}</b>
                                      </div>                                      :
                                      (item.id_pay_method === 4 ) ?
                                      <div>
                                      <label className='f-w-600 f-12 badge badge-light-secondary'>T.D</label> <br />
                                      <b className='f-14' >${number_format(item.total_i,2)}</b>
                                      </div>
                                      :
                                      (item.id_pay_method === 8 ) ? 
                                      <div>
                                      <label className='f-w-600 f-12 badge badge-light-secondary'>Transferencia </label> <br />
                                      <b className='f-14' >${number_format(item.total_i,2)}</b>
                                      </div>
                                      
                                       :
                                      <label className='f-w-600 f-12 badge badge-light-secondary'>Otro</label>
                                      }
                                  </div>               
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      : null
                    }
                      <Card>
                        <div className='text-right card-headerI '>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterEfectivo ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterEfectivo(!filterEfectivo) }} > Efectivo </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTC ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTC(!filterTC) }} > Tarjeta de Crédito </Label>
                              <Label  type="text" className={` f-w-600 f-12 badge badge${filterTD ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTD(!filterTD) }} > Tarjeta de Débito </Label>
                              <Label  type="text" className={`f-w-600 f-12 badge badge${filterTransferencias ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTransferencias(!filterTransferencias) }} > Transeferencia </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge-success`}  onClick={() => { MakeExcelInvoices(filterEfectivo) }}   style={{ cursor: "pointer" }}  >Descargar excel</Label>
                       </div>
                          <CardBody className="file-manager">
                            <h4 className="mb-3">Detallado de Facturas</h4>
                              <ul className="files">
                                {"Facturas generadas el dia:"}
                              </ul> 
                              <ul className="files"> 
                                           {listFilter !== null && (
                                            ( filterEfectivo || filterTC || filterTD  || filterTransferencias
                                            ? listFilter.filter(_filter => {
                                                const lastPaymentMethod = _filter.id_pay_method;
                                                return (
                                                  (filterEfectivo && lastPaymentMethod === 1) ||
                                                  (filterTC && lastPaymentMethod === 2) ||
                                                  (filterTD && lastPaymentMethod === 4) ||
                                                  (filterTransferencias && lastPaymentMethod === 8)
                                                );
                                                })
                                            : listFilter
                                            ).map((_find, _key) => (
                                              <div className='divkkk' > <br />
                                              <li className="file-boxI p-t-0" key={_key}>
                                                <div style={{textAlign : "right"}}>
                                                    <b htmlFor="">{_find.dateCreated}</b>
       
                                                    </div>
                                               <div className="file-top-1">
                                                 <Col sm="12" className='p-l-0' >
       
                                                   <Row>
                                                     <Col sm="6" className='display-invoice'  >   
                                                     {
                                                 _find.isGeneralPublic === true ? 
                                               
                                                    <i className="fa fa-users f-36 generalPublic">                                           
                                                     </i>
                                                     :
                                                     <i className="fa fa-user f-36 txt-secondary"></i>
                                               }
                                                     </Col>
                                                     <Col sm="6">
                                                     <Label type="text" className={`f-w-600 labelBadgeInvoice badge badge-${_find?.isGeneralPublic === false ? 'success' : "danger"}`}>
                                                     {_find?.serieFolio}
                                                 </Label>
                                                     </Col>
                                                   </Row>
                                                   <Row>
                                                     <Col sm="12">
                                                     <p style={{textAlign : "end"}} > <b>{"Total"} : </b>${_find.total_i}</p> 
       
                                                     </Col>
                                                   </Row>
                                                 </Col>
                                              </div>
                                               <div className="file-bottom">
                                                <Col sm="12">
                                                  <Row>
                                                    <Col sm="4">
                                                    {/* <i class="fa fa-folder f-26 txt-warning"></i> */}

                                                    </Col>
                                                    <Col sm="8">
                                                    <div style={{textAlign : "end"}} >
                                                       {
                                                      _find.id_pay_method === 1 ? <label className='f-w-600 f-12 badge badge-light-secondary'>Efectivo</label>
                                                       :
                                                       (_find.id_pay_method === 2 ) ? <label className='f-w-600 f-12 badge badge-light-secondary'>Tarjeta de Credito</label>
                                                       :
                                                       (_find.id_pay_method === 4 ) ? <label className='f-w-600 f-12 badge badge-light-secondary'>Tarjeta de Débito</label>
                                                       :
                                                       (_find.id_pay_method === 8 ) ? <label className='f-w-600 f-12 badge badge-light-secondary'>Tranferencia Electronica</label> :
                                                       <label className='f-w-600 f-12 badge badge-light-secondary'>Otro</label>
                                                       }
                                                    </div>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                               </div>
                                             </li> 
                                             </div>
                                            ))
                                        )}
                              
                                 
                              </ul>                        
                          </CardBody>
                      </Card>
                    </div>

                  </Col>
                </Row>
              </Container>
              </Col>
              {
                //*END INVOICES GLOBALES AND PATIENT
              }
              {
                //*CALENDAR DEPOSITS
              }
              <Row className={type_action === "D" ? '' : 'd-none'} >
              <Col sm="12">
                <Card>
                  <Row>
                    <Col  xl="8" md="8" sm="4">
                      <label htmlFor="">Generar Deposito Bancario</label>
                    </Col>
                    <Col xl="4" md="4" sm="6">
                      <Row>
                        <Col sm="6" >
                        <b>Con registro</b>  <i className="icofont icofont-notification f-22 notification-invoice"></i>
                        </Col>
                        <Col sm="6" >
                        <b>Sin registro</b> <i className="icofont icofont-notification f-22 notification-invoice-not "></i>
                        </Col>
                      </Row>
                    </Col>

                  </Row>
                </Card>
              </Col>
              <Col sm="12">
                <Row>
                  <Col xl="7" md="7" sm="12" >
                    <Card>
                    <CardHeader className='text-center p-1 bg-primary'>
                      <h6 className='pt-1'>Deposito </h6>
                    </CardHeader>
                    <CardBody className='p-2'>
                      {
                        validNotification === false ?
                        <Col sm="12" className={disabledSaveButton === true ? "disabled disableLabel": ""} >
                        <Row>  
                          <Col cm="4">
                          {
                            (validationsSG.amount || validationsSG.received || validationsSG.delivere )  &&
                            <AlertItem
                              item={
                                      {
                                      alerttxt: "Llene todos los campos requeridos",
                                      alertcolor: 'danger inverse',
                                      btnclose: 'default',
                                      icon: <i className="icon-alert"></i>
                                      }
                                    }
                                  />
                                }
                          </Col>
                              <Col sm="4" style={{textAlign : "end"}} className={range === true ? "d-none" : ""} >
                              </Col>
                              <Col sm="2" style={{textAlign : "end"}}>
                              <div className="checkbox checkbox-solid-primary pl-3">
                                  <Input id="solid1" type="checkbox" name='range' onChange={handleInputChange} checked={range}/>
                                  <Label for="solid1">¿Rangos?</Label>
                                </div>
                              </Col>
                            </Row>
                          <Row> <br /> <br /> <br />
                          <Col sm="12">
                          <Row>
                            <Col sm="6">
                            </Col>
                            <Col sm="3"  >
                              {  
                            range === true ?<Input type='date' className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? 'border-primary' : 'border-primary')}`} name='date_2' value={date_2} onChange={(e) => handleInputChange(e)}></Input>: ""
                              }
                            </Col>
                            <Col sm="3"  >  
                                {
                                one === true ? "" : <Input type='date' className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? 'border-primary' : 'border-primary')}`} name='date_1' value={date_1} onChange={(e) => handleInputChange(e)}></Input> 
                              }
                            </Col>
                          </Row>
                          </Col>
                            <Col sm="6"> <br />
                            <label className='f-14' >Quien recibio:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                            <Input type='text'   className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? 'border-danger' : 'border-primary')}`}  name='received' value={received} onChange={(e) => handleInputChange(e)}  ></Input>
                            </Col>
                            <Col sm="6"> <br />
                            <label className='f-14' >Quien entrego:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                            <Input type='text'  className={`form-control form-control-sm input-air-primary ${(validationsSG.delivere ? 'border-danger' : 'border-primary')}`} name='delivere' value={delivere} onChange={(e) => handleInputChange(e)} ></Input>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col sm="3"><label className='f-14' >Total a depositar:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label></Col>
                            <Col sm="5"><Input type='text' className={`form-control form-control-sm input-air-primary ${(validationsSG.amount ? 'border-danger' : 'border-primary')}`}  name='amount' value={amount} onChange={(e) => handleInputChange(e)} ></Input></Col>
                          </Row>
                          <br />
                          <Row>
                          <Col sm="3"><label className='f-14' >Observaciones:</label></Col>
                            <Col sm="5">
                              <textarea
                                  className='form-control form-control-sm input-air-primary border-primary'
                                  name="observation"
                                  rows="3"
                                  value={observation}
                                  onChange={handleInputChange}
                              >
                            </textarea>
                            </Col>
                          </Row>
                          <br /> <br />
                          <Row>
                            <Col sm="9" style={{textAlign:"right"}} >
                            </Col>
                            <Col sm="3" style={{textAlign:"right"}} >
                            <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" onClick={saveReceived} >Guardar</a></Col>
                            </Col>
                          </Row>
                        </Col>
                        : 
                        <Col sm="12">
                          {
                            listReceived?.map((_find) => {
                              return(
                                <div>
                                  <Col sm="12">
                                    <Row>
                                      <Col sm="3" style={{textAlign : "center"}}> <br /> <br />
                                      <Media body className="rounded-circle" style={{ width: "80%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")}  alt="" />
                                        <br />
                                        <label className='f-14' >Total Depositado:</label>

                                      <Input type='text' className={`form-control form-control-sm input-air-primary ${(validationsSG.amount ? 'border-danger' : 'border-primary')}`} disabled  name='amount' value={_find.amount} ></Input>
                                      </Col>
                                      <Col sm="9">
                                        <Row>
                                          <Col sm="5">
                                          </Col>
                                          <Col sm="4">
                                            <label htmlFor="">{_find.date_1}</label>

                                          </Col>
                                          <Col sm="4">
                                          </Col>
                                        </Row>
                                      <Row>
                                        
                                    <Col sm="6"> <br />
                                      <label className='f-14' >Quien recibio:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                                      <Input type='text'   className={`form-control form-control-sm input-air-primary ${(validationsSG.received ? 'border-danger' : 'border-primary ')}`} disabled  name='received' value={_find.user_received}  ></Input>
                                      </Col>
                                      <Col sm="6"> <br />
                                      <label className='f-14' >Quien entrego:<span className='f-20' style={{ color: "#0079C7" }}>*</span></label>
                                      <Input type='text'  className={`form-control form-control-sm input-air-primary ${(validationsSG.delivere ? 'border-danger' : 'border-primary')}`} name='delivere' disabled value={_find.user_delivere} ></Input>
                                    
                                    
                                      </Col>
                                        </Row> <br />
                                        <Row>
                                      <Col sm="6">
                                      <label className='f-14' >Observaciones:</label>
                                        <textarea
                                            className='form-control form-control-sm input-air-primary border-primary'
                                            name="observation"
                                            rows="3"
                                            value={_find.observation}
                                            disabled
                                        >
                                      </textarea>
                                      </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </div>
                              )
                            })
                          }
                      <br /> <br /> <br /> <br /> <br /> <br /> <br />
                        </Col>
                      } 
                    </CardBody>
                    </Card>
                  </Col>
                  <Col sxl="5" md="5" sm="12">
                    <Card>
                    <CardHeader className={validNotification === true ? 'text-center p-1 bg-success' : 'text-center p-1 bg-danger' }    >
                      <h6 className='pt-1'>Calendario</h6>
                    </CardHeader>
                    <CardBody className='p-2'>
                    <Row>
                      <Col sm="10" className='p-r-0' >
                        <Calendar
                          onChange={onChange}
                          value={date}
                          tileClassName={tileClassName}
                          className="p-t-0 p-b-0"
                        />
                      </Col>
                      <Col sm="2">
                      {
                        validNotification === true ? <i className="icofont icofont-notification f-32 notification-invoice"></i> :  <i className="icofont icofont-notification f-32 notification-invoice-not "></i>
                      }
                      <br /> <br /> <br /> <br />  <br /> <br /> <br /> <br /> <br />  <br /> <br /> <br /> <br /> <br /> <br /> 
                      <Button outline size='xs' color='primary'  onClick={GetDeposits} >Buscar</Button>
                      </Col>
                    </Row>
                    <br /> <br />                
                    </CardBody>

                    </Card>
                
                  </Col>
                </Row>
              </Col>
              </Row>
              {
                //*END CALENDAR DEPOSITS
              }
            </Container>
        </Fragment>
    )
}
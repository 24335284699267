import React from 'react'
import {Button, Modal, ModalBody, FormFeedback,Col, Form, Input, CardBody, Media } from 'reactstrap';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styleM.css';


export const ModalAutorization = (props) => {

    const {user_method, user_password_method,handleInputChangeMethod,modalAutorization,toggleModalAutorization,PreviewAutorization,handleCancelAutorization,name_User,configuration_role,handleSendNotificationInvoices} = props;
console.log(configuration_role);

    return (
        <Modal isOpen={modalAutorization} toggle={toggleModalAutorization} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                    {
                        configuration_role === "True" ?
                        <>
                         <Media><Media className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                <Media body>
                                    <h6 className="font-primary f-w-600">{name_User}</h6><span className="d-block"><span><i className="icofont icofont-user"> </i><span className="px-2">{"Usuario"}
                                    <Input
                                    type="text"
                                    name="user_method"
                                    value={user_method}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChangeMethod}
                                    className="form-control form-control-sm input-air-primary"
                                />
                                <FormFeedback>Escribe el usuario</FormFeedback>
                                   </span></span></span><span className="d-block"><span><i className="icofont icofont-key"></i><span className="px-2">{"Contraseña"}
                                   <Input
                                    type="password"
                                    name="user_password_method"
                                    value={user_password_method}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChangeMethod}
                                    className="form-control form-control-sm input-air-primary"
                                />
                                <FormFeedback>Escribe la contraseña</FormFeedback></span></span></span>
                                </Media>
                        </Media>
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                            <div className="btngroup">
                                <Button color="danger text-center" type="button" onClick={handleCancelAutorization}>{"Cancelar"}</Button>
                                <Button color="primary text-center" type="button" onClick={PreviewAutorization} >{"Autorizar"}</Button>  <ToastContainer />
                            </div> 
                        </Col>
                        </>
                        :
                        <Col sm="12" style={{textAlign : "center"}} className='p-l-0 p-r-0 '>
                          
                        <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "80%" }} alt="" />
                        <br />
                         <p className='f-w-600 f-14 badge badge-light-danger' >Contacte a Administracion.</p>
                         <br />
                         <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                            <div className="btngroup">
                                <Button color="danger text-center" type="button" onClick={handleSendNotificationInvoices}>{"Aceptar"}</Button>
                            </div> 
                        </Col>
                        </Col>
                    }
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}

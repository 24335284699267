import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import React from 'react';
import { Badge } from 'reactstrap';

export const useAntibiogram = () => {

    const history = useHistory();

    const [antibiograms, setAntibiograms] = useState([]);
    const [antibiotics, setAntibiotics] = useState([]);
    const [antibiogramId, setAntibiogramId] = useState(0);
    const [antibioticsSelected, setAntibioticsSelected] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        antibiogram: "",
    });

    const { antibiogram } = formValues;

    // const numberItems = 10;
    // const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(antibiograms.length, numberItems);

    // useEffect(() => {
    //     if (totalPageCount > 0) {
    //         let startItems = 0;
    //         let endItems = numberItems;

    //         let aux = numberItems * currentPage;

    //         endItems = aux;

    //         startItems = endItems - numberItems;
    //         let listAntibiograms = [];
    //         antibiograms.forEach((data, key) => {

    //             let posicion = key + 1;
    //             let visibleTable = false;

    //             if (posicion > startItems && posicion <= endItems) {
    //                 visibleTable = true;
    //             }

    //             listAntibiograms.push({
    //                 ...data,
    //                 visibleTable: visibleTable
    //             });
    //         });

    //         setAntibiograms(listAntibiograms);
    //     }
    // }, [totalPageCount, currentPage])

    const [validaciones, setValidaciones] = useState({
        antibiogram_valid: false,
    });

    const onChangeAntibiotics = (selected) => {
        // //console.log(selected);
        setAntibioticsSelected(selected)
    };

    const getAntibiograms = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Antibiogram/List");

        if (respuesta.code === 200) {
            let listAntibiograms = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;
           
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {
                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }
                    

                    listAntibiograms.push({
                        ...data,
                        no_antibiotics: data.antibiotics !== null ? <Badge color='primary' pill>{data.antibiotics.length}&nbsp;Antibioticos</Badge> : <Badge color='primary' pill>0&nbsp;Antibioticos</Badge>,
                        visibleTable:visibleTable
                    });
                });
                setAntibiograms(listAntibiograms);
                setAntibiogramsData(listAntibiograms);
            }
            else {
                setAntibiograms([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAntibiograms);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibiogramas", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Antibiogramas", respuesta.data.msg);
        }
    }

    const getAntibiotics = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Antibiotic/List");

        if (respuesta.code === 200) {
            let data = respuesta.data.map(x => {
                x.value = x.id_mic_antibiotic;
                x.label = x.antibiotic;
                return x;
            })
            setAntibiotics(data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAntibiotics);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibioticos", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Antibioticos", respuesta.data.msg);
        }
    }

    useEffect(() => {
        getAntibiograms();
        getAntibiotics();
    }, []);


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            antibiogram: "",
        });

        setValidaciones({
            antibiogram_valid: false,
        });
        setAntibioticsSelected([]);
        setAntibiogramId(0);
        setMethod("create");
        setModalTitle("Crear antibiograma");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }
        let data = {
            ...formValues,
            antibiotics: antibioticsSelected
        }
        // //console.log(data);
        // return;
        let mValues = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Antibiogram");

        if (respuesta.code === 200) {
            getAntibiograms();
            reset();
            createSweet("create", "success", "Exito!", "Antibiograma creado con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibiogramas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Antibiogramas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_antibiogram) => {
        let antibiogram = antibiograms.find(s => s.id_mic_antibiogram === parseInt(id_antibiogram));

        handleUpdateValues({
            antibiogram: antibiogram.antibiogram,
        });

        setValidaciones({
            antibiogram_valid: false,
        });

        let antibioticos = antibiogram.antibiotics === null ? [] : antibiogram.antibiotics.map(x => {
            x.value = x.id_mic_antibiotic;
            x.label = x.antibiotic;
            return x;
        })

        setAntibioticsSelected(antibioticos);

        setAntibiogramId(id_antibiogram);
        setMethod("update");
        setModalTitle("Actualización de antibiograma");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_mic_antibiogram: antibiogramId,
            antibiotics: antibioticsSelected,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Antibiogram/Update");

        if (respuesta.code === 200) {
            getAntibiograms();
            createSweet("create", "info", "Exito!", "Antibiograma actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibiogramas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Antibiogramas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_antibiogram) => {
        let antibiogram = antibiograms.find(s => s.id_mic_antibiogram === id_antibiogram);

        handleUpdateValues({
            antibiogram: antibiogram.antibiogram,
        });

        setAntibiogramId(id_antibiogram);
        setMethod("delete");
        setModalTitle("Eliminar antibiograma");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Antibiogram/${antibiogramId}`);
        if (respuesta.code === 200) {
            getAntibiograms();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Antibiograma eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibiogramas", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Antibiogramas", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};
        // antibiogram, gram, id_mic_antibiogram
        if (formValues.antibiogram === undefined || formValues.antibiogram.length < 1) {
            newValidations = {
                ...newValidations,
                antibiogram_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                antibiogram_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }


    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(antibiograms.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listantiBiograms = [];
        antibiograms.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listantiBiograms.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAntibiograms(listantiBiograms);

    }, [totalPageCount, currentPage]);







    const [antibiogramsData, setAntibiogramsData] = useState([]);
    const handleSearch = (e) => {

        let busqueda = e.target.value;
        let newAntibiogram = [];
        if (busqueda === "") {
            setAntibiograms([...antibiogramsData]);
        } else {
            antibiogramsData.forEach(element => {
                let cadena = element.antibiogram.toUpperCase();
                if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                    element.visibleTable = true;
                    newAntibiogram.push(element);
                }
            });
            setAntibiograms([...newAntibiogram]);
        }
    }
    return {
        antibiograms, antibiotics, method, validaciones, onChangeAntibiotics, antibioticsSelected,
        antibiogram,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch
    }

}

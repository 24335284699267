import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LostConnection } from '../../constant';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../hooks/tokens/useToken';



export const useTracking = () => {

    // setFocusInNim(true);

    const history = useHistory();
    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [typeModal, setTypeModal] = useState("nim")

    const [headerSelected, setHeaderSelected] = useState(null);
    const [userSelected, setUserSelected] = useState(null);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        nim: ""
    });

    const [typeModalLogin, setTypeModalLogin] = useState("fromCard");
    const [modal2, setModal2] = useState(false);

    const [typeModalList, setTypeModalList] = useState("listNim");
    const [modal3, setModal3] = useState(false);



    const { nim } = formValues;


    const [headers, setHeaders] = useState([]);
    const [users, setUsers] = useState([]);
    const [nimCards, setNimCards] = useState([]);


    const [rackPrevious, setRackPrevious] = useState(null);
    const [rackNext, setRackNext] = useState(null);

    const [rackInfo, setRackInfo] = useState(null);
    const [modalRack, setModalRack] = useState(false);
    const [arrayNims, setArrayNims] = useState([]);
    const [racks, setRacks] = useState([]);
    const [showText, setShowText] = useState(false);


    const toggleRack = () => {
        if (modalRack) {
            setFocusInNim(true);
            setRackInfo(null);
            setArrayNims([]);
            setChangeRackStatus(false);
        }
        setModalRack(!modalRack);
    }

    const [exams, setExams] = useState([]);
    const txtsection = localStorage.getItem('TextAS');

    const getExamsFromNim = async (e, type = "automatic", nimAux = "", activeModal = true) => {
        setFocusInUser(false);
        setFocusInNim(false);
        e !== null && e.preventDefault();

        var nimAuxMan = null;

        if (type === "manual") {
            let cards_aux = nimCards;
            nimAuxMan = nim.substring(0, 13);
            if (cards_aux.find(x => x.nim == nimAux) !== undefined) {
                setFocusInNim(true);
                setTypeModal("nim");
                toggle();
                initTracking(null, nimAux);
                return;
            }
        }

        let body = {
            "nim": type === "manual" ? nimAuxMan : nimAux,
            "id_section": headerSelected.id_section,
            "id_process": headerSelected.id_process,
            "id_user": userSelected.id_user,
            "id_branch": localStorage.getItem("branchId")
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };


        const respuesta = await sendRequest(requestOptions, "Tracking/GetExamsFromNim");

        if (respuesta.code === 200) {
            //console.log(respuesta);
            setModal(false);
            if (type === "manual") {
                if (respuesta.data.length > 0) {
                    setTimeout(() => {
                        setExams(respuesta.data);
                        setModal(true);
                        setTypeModal("exams");
                    }, 500);
                } else {
                    createSweet("error", "error", "Atención", "Este estudio no ha pasado por el módulo de envíos");
                }
            } else {

                if (respuesta.data.length > 0) {

                    if (parseInt(headerSelected.id_process) === 0) {
                        //console.log("aqui pasa.....");

                        setExams(respuesta.data);


                        nimAux = nim.substring(0, 13);
                        //console.log(nimAux = nim.substring(0, 13));
                        // if (cards_aux.find(x => x.nim == nimAux) !== undefined) {
                        setFocusInNim(true);
                        setTypeModal("exams");
                        toggle();
                        initTracking(null, nimAux);
                        return;

                    }


                    // }
                    setTimeout(() => {
                        setTypeModal("exams");
                        setExams(respuesta.data);
                        if (activeModal) {
                            if (parseInt(headerSelected.id_process) === 0) {
                                initTracking(null, type === "manual" ? nim : nimAux, false)
                                setFocusInNim(true);
                            } else {
                                setModal(true);
                                setTypeModal("exams");
                                setFocusInNim(true);
                            }
                        }

                        if (parseInt(headerSelected.id_process) === 2) {

                        }
                    }, 500);
                    setFocusInNim(true);
                }
                else {
                    setExams(respuesta.data);
                    setModal(false);
                    setFocusInNim(true);
                    createSweet("info", "info", "Atención", "Este NIM no cuenta con estudios en esta área");
                }
            }
        }
        else if (respuesta.code === 409) {
            createSweet("warning", "warning", "Atención", respuesta.data.msg);
        }
        else if (respuesta.code === 404) {
            createSweet("warning", "warning", "Atención", respuesta.data.msg);

        }

        return respuesta.data
    }

    useEffect(() => {
        let sectionProcessArray = localStorage.getItem("sectionProcessArray");
        setHeaders(JSON.parse(sectionProcessArray));
        setHeaderSelected(JSON.parse(sectionProcessArray)[0]);
        let id_user = localStorage.getItem("id");
        if (JSON.parse(sectionProcessArray)[0].enable_racks) {
            getTrackingsFromRacks(id_user, JSON.parse(sectionProcessArray)[0].id_section, JSON.parse(sectionProcessArray)[0].id_process);
        } else {
            getTrackings(id_user, JSON.parse(sectionProcessArray)[0].id_section, JSON.parse(sectionProcessArray)[0].id_process);
        }
    }, [])

    useEffect(() => {
        if (headerSelected !== null) {
            let idSection = headerSelected.id_section;
            let idProcess = headerSelected.id_process;

            let usersArray = localStorage.getItem("usersArray");
            usersArray = JSON.parse(usersArray);

            usersArray = usersArray.filter(x => {
                if (x.id_section === idSection && idProcess === x.id_process) {
                    return x;
                }
            })
            setUsers(usersArray);
            setUserSelected(usersArray[0])
            // getUsersFromSection(idSection);
        }
    }, [headerSelected])

    useEffect(() => {
        if (users.length > 0 && userSelected === null) {
            let id_user = localStorage.getItem("id");
            let usuario = users.find(x => x.id_user === parseInt(id_user));
            setUserSelected(usuario);
        }
    }, [users])


    useEffect(() => {
        if (userSelected !== null) {
            if (headerSelected.enable_racks) {
                getTrackingsFromRacks(userSelected.id_user, userSelected.id_section, userSelected.id_process);//wilson
            } else {
                getTrackings(userSelected.id_user, userSelected.id_section, userSelected.id_process);
                handleGetInformationnIM(userSelected.id_user, userSelected.id_process, userSelected.id_section)
            }
            getTrackings(userSelected.id_user, userSelected.id_section, userSelected.id_process);
        }
    }, [userSelected])


    const getTrackings = async (id_user, id_section, id_process) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, `Tracking/GetTrackings/${id_user}/${id_section}/${id_process}`);

        if (respuesta.code === 200) {
            let cartas = []

            respuesta.data.forEach(element => {
                let examsAux = element.exams.map(x => {
                    x.check = true;
                    x.name_exam = x.name;
                    return x;
                })
                let time = new Date(element.exams[0].init_time);
                let newCard = {
                    "nim": element.nim,
                    "exams": examsAux,
                    "time": time
                }
                cartas.push(newCard);
            });
            setNimCards(cartas);
        } else {

        }
    }


    const toggle = () => {
        setModal(!modal);
    }

    const handleModalNim = () => {
        setFocusInNim(false);
        setTypeModal("nim");
        toggle();




    }

    const changeUserSelected = (id_user) => {
        let usuarios = users;

        let usuario = usuarios.find(x => x.id_user === id_user);

        setUserSelected(usuario);



    }

    const changeHeaderSelected = (id_section, id_process) => {
        let cabeceras = headers;
        let header = cabeceras.find(x => x.id_section === id_section && x.id_process === id_process);
        setHeaderSelected(header);
    }

    const handleInputCheckboxChange = (e) => {
        let examenes = exams;
        let id_exam = parseInt(e.target.value);
        examenes.map(x => {
            if (x.id_exam === id_exam) {

                x.check = e.target.checked
            }
            return x;
        })
        setExams([...examenes]);
    }


    const initTracking = async (e, nimAux = "", validateExams = true, foceExecute = false) => {
        let list_not_exams = [];
        let examenes_aux = exams;
        if (!(exams.length > 0)) {
            //corregir esta parte


            await getExamsFromNim(null, "automatic", nimAux !== "" ? nimAux : nim, false).then(x => {//wilson2
                examenes_aux = x.filter(q => {
                    if (q.code_error !== null) {
                        q.check = false;
                    } else {
                        q.check = true;
                    }
                    return q;
                })
            });
        }
        examenes_aux.forEach(obj => {
            if (!obj.check) {
                list_not_exams.push(obj.id_exam)
            }
        });

        let body = {
            "id_tracking": 0,
            "nim": nimAux !== "" ? nimAux : nim,
            "id_user": userSelected.id_user,
            "id_process": headerSelected.id_process,
            "id_section": headerSelected.id_section,
            "id_branch": localStorage.getItem("branchId"),
            "minutes": null,
            "list_not_exams": list_not_exams,
            "validate_exams_pending": validateExams,
            "force_execute": foceExecute
        }
        // return;
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Tracking");//wilsongato
        if (respuesta.code === 200) {
            if (respuesta.data.code === 200) {
                if (respuesta.data.status === "INIT") {

                    let examenes = exams.filter(x => {
                        if (x.check) {
                            x.name_exam = x.name
                            return x;
                        }
                    })
                    let new_card = {
                        "nim": nim,
                        "exams": examenes,
                        "time": new Date()
                    }

                    let cards_aux = nimCards;
                    cards_aux.push(new_card);
                    setNimCards(cards_aux);
                    setModal(false); // toggle();
                    createSweet("info", "info", "Atención", respuesta.data.message);
                    //console.log("aqui pasa");

                    //  createSweet("", "success", "Éxito", "Se agrego correctamente");
                    //
                    // createSweett("", "Se agrego correctamente", "success");
                    setFocusInUser(false);
                    setFocusInNim(true);
                    setExams([]);
                    handleUpdateValues({
                        "nim": ""
                    });

                    //handleGetInformationnIM(userSelected.id_user, userSelected.id_process)

                } else if (respuesta.data.status === "TDM") {
                    //createSweet("", "success", "Éxito", "Se recibio la muestra correctamente");
                    createSweet("", "success", "Éxito", respuesta.data.message);

                    setTimeout(() => {
                        handleGetInformationnIM(userSelected.id_user, userSelected.id_process, userSelected.id_section)
                    }, 1000);


                    setFocusInUser(false);
                    setFocusInNim(true);
                    setExams([]);
                    handleUpdateValues({
                        "nim": ""
                    });
                    setModal(false); // toggle();

                }
                else {
                    let cards_aux = nimCards;
                    let nim_aux = nimAux !== "" ? nimAux : nim;
                    cards_aux = cards_aux.filter(x => x.nim !== nim_aux);
                    setNimCards(cards_aux);

                    createSweet("", "warning", "Atención", respuesta.data.message);


                    // createSweett("", "Finalizo correctamente", "success");

                    setFocusInUser(false);
                    setFocusInNim(true);
                    setExams([]);
                    handleUpdateValues({
                        "nim": ""
                    });
                    setModal(false); // toggle();
                    //handleGetInformationnIM(userSelected.id_user, userSelected.id_process)


                }
            } else if (respuesta.data.code === 409) {//wilson
                createSweet("info", "info", "Atención", respuesta.data.message);
                setFocusInNim(true);
            }
            else if (respuesta.data.code === 405) {
                setTypeModal("pending");
                setModal(true); // toggle();
                setFocusInNim(true);

            }

        }

        else if (respuesta.code === 404) {
            createSweet("warning", "warning", "Atención", respuesta.data.msg);
        }
    }



    function cerrarModalNim() {
        setExams([]);
        setFocusInNim(true);
        toggle();
    }

    const [scanUserValue, setScanUserValue] = useState('')
    const [focusInUser, setFocusInUser] = useState(false);

    function userAutoFocus() {
        if (focusInUser) {
            document.getElementById("inputFocusUser").focus()
        }
    }

    function onChangeScanUser(event) {
        setScanUserValue(event.target.value)
    }

    function onKeyPressScanUser(event) {
        // if (event.keyCode === 13) {
        //     if (event.target.value == "0013810595") {
        //         handleUpdateValues({
        //             "nim": "2041710210003"
        //         });
        //         getExamsFromNim(null, "automatic", "2041710210003");
        //     }
        //     setScanUserValue("");
        // }
    }

    // -------------------------


    const [scanNimValue, setScanNimValue] = useState('');
    const [focusInNim, setFocusInNim] = useState(true);

    function nimAutoFocus() {
        if (focusInNim) {
            document.getElementById("inputFocusNim").focus()
        }
    }

    function onChangeScanNim(event) {
        setScanNimValue(event.target.value)
    }

    const [rackExist, setRackExist] = useState(false);
    const [changeRackStatus, setChangeRackStatus] = useState(false);

    const initChangeRack = () => {
        setChangeRackStatus(true);
        setFocusInNim(true);
    }

    const changeRack = async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, `Rack/FinishRack/${rackPrevious.id_rack}`);
        if (respuesta.code === 200) {
            let nims_aux = arrayNims;
            nims_aux.map(x => {
                x.id_rack = rackNext.id_rack;
                return x;
            });

            setArrayNims(nims_aux);
            setRackInfo(rackNext);
            setRackNext(null);
            setRackPrevious(null);
            createSweet("success", "success", "Cambio de gradilla satisfactorio");
            setChangeRackStatus(false);
            setFocusInNim(true);
        } else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const getRackFromId = async (idRack, noToggle = false) => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, `Rack/GetRackFromCode/${idRack}`);

        if (respuesta.code === 200) {
            if (respuesta.data.available) {
                if (!noToggle) {
                    setRackInfo(respuesta.data);
                    setRackExist(false);
                    toggleRack();
                } else {
                    setRackPrevious(rackInfo);
                    setRackNext(respuesta.data);
                }
            } else {
                if (respuesta.data.actual_position_process === 0) {
                    setRackInfo(respuesta.data);
                    setRackExist(true);
                    let nims_aux = arrayNims;
                    respuesta.data.nims.forEach(element => {
                        let newObj = {
                            "id_tracking": 0,
                            "nim": element,
                            "id_user": userSelected.id_user,
                            "id_process": headerSelected.id_process,
                            "id_section": headerSelected.id_section,
                            "minutes": 0,
                            "validate_exams_pending": true,
                            "force_execute": false,
                            "id_exam": 0,
                            "list_not_exams": [],
                            "id_rack": idRack,
                            "init_time": new Date()
                        };
                        nims_aux.push(newObj);
                    });
                    setArrayNims(nims_aux)
                    if (!noToggle) {
                        toggleRack();
                    }
                } else {
                    if (parseInt(headerSelected.id_process) === parseInt(respuesta.data.actual_id_process)) {
                        createSweet("info", "info", "Este proceso ya ha finalizado");
                    } else if (parseInt(headerSelected.id_process) === parseInt(respuesta.data.next_id_process)) {
                        setRackInfo(respuesta.data);
                        setRackExist(true);
                        let nims_aux = arrayNims;
                        respuesta.data.nims.forEach(element => {
                            let newObj = {
                                "id_tracking": 0,
                                "nim": element,
                                "id_user": userSelected.id_user,
                                "id_process": headerSelected.id_process,
                                "id_section": headerSelected.id_section,
                                "minutes": 0,
                                "validate_exams_pending": true,
                                "force_execute": false,
                                "id_exam": 0,
                                "list_not_exams": [],
                                "id_rack": idRack
                            };
                            nims_aux.push(newObj);
                        });
                        setArrayNims(nims_aux)
                        if (!noToggle) {
                            toggleRack();
                        }
                    } else {
                        createSweet("info", "info", "No se puede pasar la muestra por este proceso");
                    }
                }

            }
        }
    }

    const [examRack, setExamRack] = useState("");

    const getExamsFromNimRack = async (nim) => {
        setFocusInUser(false);
        setFocusInNim(false);

        let body = {
            "nim": nim,
            "id_section": headerSelected.id_section,
            "id_process": headerSelected.id_process,
            "id_user": userSelected.id_user,
            "id_branch": localStorage.getItem("branchId"),
        }


        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Tracking/GetExamsFromNim");

        if (respuesta.code === 200) {
            let nims_actuales = arrayNims;
            if (respuesta.data.length > 0) {
                if (respuesta.data[0].code_error === 409) {
                    createSweet("info", "info", respuesta.data[0].status);
                }
                else {
                    if (nims_actuales.length > 0) {
                        if (nims_actuales[0].id_exam === respuesta.data[0].id_exam) {
                            let newObj = {
                                "id_tracking": 0,
                                "nim": nim,
                                "id_user": userSelected.id_user,
                                "id_process": headerSelected.id_process,
                                "id_section": headerSelected.id_section,
                                "minutes": 0,
                                "validate_exams_pending": true,
                                "force_execute": false,
                                "id_exam": respuesta.data[0].id_exam,
                                "list_not_exams": [],
                                "id_rack": rackInfo.id_rack
                            };
                            nims_actuales.push(newObj);
                        } else {
                            createSweet("success", "success", "Este nim no cuenta con el mismo examen");
                        }
                    } else {
                        setExamRack(respuesta.data.name);
                        let newObj = {
                            "id_tracking": 0,
                            "nim": nim,
                            "id_user": userSelected.id_user,
                            "id_process": headerSelected.id_process,
                            "id_section": headerSelected.id_section,
                            "minutes": 0,
                            "validate_exams_pending": true,
                            "force_execute": false,
                            "id_exam": respuesta.data[0].id_exam,
                            "list_not_exams": [],
                            "id_rack": rackInfo.id_rack
                        };
                        nims_actuales.push(newObj);
                    }
                }
            } else {
                createSweet("info", "info", "Sin examenes vinculados");
            }

            setArrayNims(nims_actuales);
            setFocusInNim(true);
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
        return respuesta.data
    }

    const initTrackingFromRack = async (existRack = false, idRack = 0) => {

        let arrayNimAux = arrayNims;

        if (existRack) {
            let rack_solicitado = racks.find(x => x.id_rack === idRack);
            rack_solicitado.items.map(x => {
                x.list_not_exams = [];
                return x;
            })
            arrayNimAux = rack_solicitado.items;
        }

        let body = {
            "list_trackings": arrayNimAux
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        arrayNims.map(x => {
            if (x.init_time === undefined) {
                x.init_time = new Date();
                return x;
            }
        })

        const respuesta = await sendRequest(requestOptions, "Tracking/InitTrackingFromRack");

        if (respuesta.code === 200) {
            if (respuesta.data.status === "INIT") {
                createSweet("success", "success", "Gradilla iniciada con éxito");
                let newRack = {
                    "id_rack": rackInfo.id_rack,
                    "rackInfo": rackInfo,
                    "items": arrayNims
                }
                let racks_aux = racks;
                racks_aux.push(newRack);
                setRacks(racks_aux);
                setRackInfo(null);
                setArrayNims([]);
                toggleRack();
                setFocusInNim(true);
            }
            else {
                let racks_aux = racks.filter(x => x.id_rack !== idRack);
                setRacks(racks_aux);
                setRackInfo(null);
                setArrayNims([]);

                createSweet("success", "success", "Gradilla terminada con éxito");
            }
        } else if (respuesta.code === 405) {
            createSweet("info", "info", "Estan pendientes");
        } else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const getTrackingsFromRacks = async (id_user, id_section, id_process) => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, `Tracking/GetTrackingsFromRacks/${id_user}/${id_section}/${id_process}`);

        if (respuesta.code === 200) {
            respuesta.data.map(element => {
                element.items.map(obj => {
                    obj.id_rack = element.id_rack;
                    return obj;
                });
                return element;
            });

            setRacks(respuesta.data);
        } else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    function onKeyPressScanNim(event) {
        let nim_aux = "";
        if (event.keyCode === 13) {
            if (changeRackStatus) {
                // if (event.target.value === "0013750347") {
                //     getRackFromId(3, true);
                // }
                getRackFromId(event.target.value, true);
            } else {
                if (headerSelected.enable_racks) {
                    if (rackInfo !== null) {
                        let nims_actuales = arrayNims;
                        let nim_actual = event.target.value.substring(0, 13);
                        // if (event.target.value == "0013810595") {
                        //     if (nims_actuales.find(x => x.nim === "1032207180014") === undefined) {
                        //         nim_actual = "1032207180014"
                        //     }
                        // } else if (event.target.value == "0013750347") {
                        //     if (nims_actuales.find(x => x.nim === "1032207180012") === undefined) {
                        //         nim_actual = "1032207180012"
                        //     }
                        // }
                        getExamsFromNimRack(nim_actual)
                    } else {
                        // if (event.target.value === "0013810441") {
                        //     let racks_aux = racks;
                        //     let validacion_aux = racks_aux.find(x => x.id_rack === 2);
                        //     //console.log(validacion_aux);
                        //     if (validacion_aux === undefined) {
                        //         getRackFromId(2);
                        //     }
                        //     else {
                        //         initTrackingFromRack(true, 2);
                        //     }
                        // } else if (event.target.value === "0013750347") {
                        let racks_aux = racks;
                        let validacion_aux = racks_aux.find(x => x.id_rack === event.target.value);

                        if (validacion_aux === undefined) {
                            getRackFromId(event.target.value);
                        }
                        else {
                            initTrackingFromRack(true, event.target.value);
                        }
                        // }
                    }
                }
                // else if (event.target.value == "0013810595") {
                else {
                    handleUpdateValues({
                        "nim": event.target.value.substring(0, 13)
                    });

                    let cards_aux = nimCards;
                    if (cards_aux.find(x => x.nim == event.target.value.substring(0, 13)) !== undefined) {
                        initTracking(null, event.target.value.substring(0, 13), true);
                    } else {
                        getExamsFromNim(null, "automatic", event.target.value.substring(0, 13));
                    }
                }
                // else if (event.target.value == "0013750347") {

                //     handleUpdateValues({
                //         "nim": "1032207180012"
                //     });

                //     let cards_aux = nimCards;
                //     if (cards_aux.find(x => x.nim == "1032207180012") !== undefined) {
                //         initTracking(null, "1032207180012", true);
                //     } else {
                //         getExamsFromNim(null, "automatic", "1032207180012");
                //     }
                // }
                // else if (event.target.value == "0013748042") {

                //     handleUpdateValues({
                //         "nim": "1502207260003"
                //     });

                //     let cards_aux = nimCards;
                //     //console.log(cards_aux);
                //     if (cards_aux.find(x => x.nim == "1502207260003") !== undefined) {
                //         //console.log("entro?11");
                //         initTracking(null, "1502207260003", true);
                //     } else {
                //         getExamsFromNim(null, "automatic", "1502207260003");
                //     }
                // }
                // else if (event.target.value == "0013679410") {

                //     handleUpdateValues({
                //         "nim": "2132207310002"
                //     });

                //     let cards_aux = nimCards;
                //     if (cards_aux.find(x => x.nim == "2132207310002") !== undefined) {
                //         initTracking(null, "2132207310002", true);
                //     } else {
                //         getExamsFromNim(null, "automatic", "2132207310002");
                //     }
                // }
                setScanNimValue("");
            }
        }
    }

    const quitFocus = () => {
        setFocusInNim(false);
        setFocusInUser(false);
    }

    const handleFocusInputNim = () => {
        setFocusInNim(true);
    }
    //datos
    useEffect(() => {
        if (focusInNim) {
            document.getElementById("inputFocusNim").focus();
        }
    }, [focusInNim])

    const addOther = () => {
        let cartas = nimCards;
        let newCard = {
            "nim": "100000" + parseInt(cartas.length + 1),
            "exams": [],
            "time": new Date()
        }
        cartas.push(newCard);
        setNimCards(cartas);
        // setNimCards([...cartas]);
    }


    const handleModalUser = () => {
        setFocusInNim(false);
        setTypeModal("nim");
        setTypeModalLogin("fromCard")
        toggle2();
        setFocusInUser(false);
    }

    const [llavecard, setLlavecard] = useState("");
    const [arrayExams, setArrayExams] = useState([]);
    const [labelNim, setLabelNim] = useState("")




    const handleModalListNim = (keyNim, array, lnim) => {
        setFocusInNim(false);
        setTypeModalList("listNim")
        toggle3();
        setFocusInUser(false);
        setLlavecard(keyNim);
        setArrayExams(array);
        setLabelNim(lnim);
    }

    function cerrarModalNimExam() {
        setFocusInNim(true);
        toggle3();
    }

    function cerrarModalUsuario() {
        setFocusInNim(true);
        setFocusInUser(false);
        toggle2();
    }

    //#region Sections

    const [show, setShow] = useState(true);
    const [show2, setShow2] = useState(true)
    const [tittle, setTittle] = useState("");
    //Sections
    const [departamento, setDepartamento] = useState([]);
    const [sections, setSections] = useState([]);
    const [process, setProcess] = useState([]);

    const toggle2 = () => {
        setModal2(!modal2);
    }


    const toggle3 = () => {
        setModal3(!modal3);
    }


    const handleShow = () => {
        setShow(!show);
        //setCardView(true);
        // handleCloseCard();

    }

    const handleDepartamento = () => {
        setFocusInNim(!focusInNim);
        setFocusInUser(false);
        setShow2(!show2);
        setTittle("Nueva Área");
        handleUpdateValuesT({
            id_area: 0,
            id_departament: 0,
            userName: "",
            password: "",
            card_codes: ""

        })
    }

    //useEffect Sections
    useEffect(() => {

        getDepartaments();
        getSections();

    }, [history.location]);


    const [formValuesT, handleInputChangeT, , handleUpdateValuesT, handleSelectValuesT] = useForm({
        id_area: "",
        id_departament: "",
        userName: "",
        password: "",
        card_codes: ""


    });

    const { id_area, id_departament, userName, password, card_codes } = formValuesT;


    const getDepartaments = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListDepartaments = [];

        const respuesta = await sendRequest(requestOptions, "Departaments/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListDepartaments.push({
                        id_departament: value.id_departament,
                        id_area: value.id_area,
                        name: value.name,
                    });
                });
                setDepartamento(ListDepartaments);
            }
            else {
                setDepartamento([]);
            }
        }
        else {
            //createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const getSections = async () => {

        let requestOptions = {
            method: 'GET'
        };


        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {

            setSections(respuesta.data);
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }


    //id sections
    useEffect(() => {
        if (id_area > 0) {
            getProcessFromSection();
        }
    }, [id_area])


    const getProcessFromSection = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/GetProccessInSection/" + id_area + "/true");

        if (respuesta.code === 200) {
            setProcess(respuesta.data);
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    //#endregion Sections


    //#region SesionTracking

    const [formValuesTFC, handleInputChangeTFC, , handleSelectValuesTFC] = useForm({
        card_code: "",
        id_section: 0,
    });

    const { card_code, id_section } = formValuesTFC;


    const iniciarSesionCard = async (e) => {
        // e.preventDefault(); por la card
        setShowText(false);


        let raw = JSON.stringify(formValuesT);

        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "Users/LoginTracking");

        if (respuesta.code === 200) {
            if (respuesta.data.msg !== "Éxito") {
                createSweet("error", "error", respuesta.data.msg);
                return;
            }
            let secciones = JSON.parse(localStorage.getItem("sectionProcessArray"));
            let usuarios = JSON.parse(localStorage.getItem("usersArray"));

            let newSectionProcess = {
                "id_section": id_area,
                "id_process": id_departament,
                "proceso": process.find(x => parseInt(x.id_process) === parseInt(id_departament)).name,
                "seccion": sections.find(x => parseInt(x.id_section) === parseInt(id_area)).name,
                "enable_racks": sections.find(x => x.id_section === parseInt(id_area)).enable_racks ? (process.find(x => x.id_process === parseInt(id_departament)).is_operative ? true : false) : false,
                "enable_reception": sections.find(x => x.id_section === parseInt(id_area)).enable_reception
            }

            let newUser = { "name": respuesta.data.username, "id_user": respuesta.data.id_user, "id_section": id_area, "id_process": id_departament }

            secciones.push(newSectionProcess);
            usuarios.push(newUser);

            localStorage.setItem("sectionProcessArray", JSON.stringify(secciones));
            localStorage.setItem("usersArray", JSON.stringify(usuarios));

            setUserSelected(newUser);
            setHeaders(secciones);
            setHeaderSelected(newSectionProcess);

            let usersAux = usuarios.filter(x => {
                if (x.id_section === parseInt(id_departament) && parseInt(id_area) === x.id_process) {
                    return x;
                }
            })
            setUsers(usersAux);
            createSweet("", "success", "Éxito", "Sección" + " agregada con Éxito");
            reloadSection();

            handleDepartamento();

            handleUpdateValuesT({
                id_area: 0,
                id_departament: 0,
                userName: "",
                password: "",
                card_codes: ""

            })
            setFocusInNim(true);

        }
        else {
            setShowText(true);
            handleUpdateValuesT({
                id_area: 0,
                id_departament: 0,
                userName: "",
                password: "",
                card_codes: ""

            })
            reloadSection();
            // createSweet("error", "error", "Hubo un error!", LostConnection);
        }

    }


    const LoginTrankingFormCard = async (e) => {
        e.preventDefault();
        let id_section = headerSelected.id_section
        let body = {
            card_code: formValuesTFC.card_code,
            id_section: id_section
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        let usuarios = JSON.parse(localStorage.getItem("usersArray"));

        const respuesta = await sendRequest(requestOptions, "Users/LoginTrackingFromCard");

        if (respuesta.code === 200) {
            let id = respuesta.data.id_user
            let user = respuesta.data.username

            let newUser = {
                "name": user,
                "id_user": id,
                "id_section": id_section,
                "id_process": headerSelected.id_process
            };

            usuarios.push(newUser);
            localStorage.setItem("usersArray", JSON.stringify(usuarios));
            setUserSelected(newUser);

            let usersAux = usuarios.filter(x => {
                if (parseInt(x.id_section) === parseInt(headerSelected.id_section) && parseInt(headerSelected.id_process) === parseInt(x.id_process)) {
                    return x;
                }
            })

            setUsers(usersAux);
            toggle2();
            handleShow();
            setFocusInNim(true);

            //handleShow();
        }
    }


    //#endregion SesionTracking

    //#region Proccess User

    const deleteUser = () => {
        let usuario = userSelected;
        let seccion = headerSelected;
        let secciones = JSON.parse(localStorage.getItem("sectionProcessArray"));
        let usuarios = JSON.parse(localStorage.getItem("usersArray"));

        usuarios = usuarios.filter(x => {
            if (!(x.id_process === usuario.id_process && x.id_section === usuario.id_section && x.id_user === usuario.id_user && x.name === usuario.name)) {
                return x
            }
        })
        let usuariosState = usuarios;
        usuariosState = usuariosState.filter(x => {
            if (x.id_process === usuario.id_process && x.id_section === usuario.id_section) {
                return x;
            }
        })

        if (usuariosState.length > 0) {
            let newUserSelected = usuarios[0];
            setUserSelected(newUserSelected);
        } else {
            secciones = secciones.filter(x => {
                if (!(seccion.id_section === x.id_section && seccion.id_process === x.id_process)) {
                    return x
                }
            })

            if (secciones.length > 0) {
                localStorage.setItem("sectionProcessArray", JSON.stringify(secciones));
                setHeaders(secciones);
                setHeaderSelected(secciones[0]);
            } else {
                // cerrarSesion();
            }
            setUserSelected(null);
        }
        handleShow();
        setUsers(usuariosState);

        localStorage.setItem("usersArray", JSON.stringify(usuarios));
        createSweet("", "success", "Éxito", "Sesión de usuario finalizado");
        setFocusInNim(true);

    }


    const deleteSectionprocess = () => {

        let usuario = userSelected;
        let section = headerSelected;
        let secciones = JSON.parse(localStorage.getItem("sectionProcessArray"));
        let usuarios = JSON.parse(localStorage.getItem("usersArray"));

        let findSection = headerSelected.id_section

        if (findSection) {
            secciones = secciones.filter(x => {

                if (!(x.id_process === section.id_process && x.id_section === section.id_section)) {
                    return x
                }
            })

            if (secciones.length > 0) {
                localStorage.setItem("sectionProcessArray", JSON.stringify(secciones));
                setHeaders(secciones);
                setHeaderSelected(secciones[0]);
            }

            usuarios = usuarios.filter(x => {

                if (!(x.id_process === usuario.id_process && x.id_section === usuario.id_section)) {
                    return x
                }
            })
            if (usuario.length > 0) {
                let newUserSelected = usuarios[0];
                setUserSelected(newUserSelected);
            }
            else {
                secciones = secciones.filter(x => {
                    if (!(section.id_section === x.id_section && section.id_process === x.id_process)) {
                        return x
                    }
                })
            }
            handleShow();
            setHeaders(secciones)
            localStorage.setItem("sectionProcessArray", JSON.stringify(secciones));
            setUsers(usuarios);
            localStorage.setItem("usersArray", JSON.stringify(usuarios));
            createSweet("", "success", "Éxito", "Área Finalizada");
            setFocusInNim(true);
        }
    }

    const removeRackNim = (indice) => {

        let find = arrayNims
        find.splice(indice, 1)
        setArrayNims([...find])
    }


    //#endregion proccess User

    //#region method

    const cerrarSesion = () => {
        let toma = localStorage.getItem("tomaMuestra");
        localStorage.removeItem("token");
        localStorage.removeItem("tomaMuestra");
        localStorage.removeItem("id");
        localStorage.removeItem("nameUser");
        localStorage.removeItem("sectionProcessArray");
        localStorage.removeItem("usersArray");
        localStorage.clear();

        if (toma == "3") {

            setTimeout(() => {
                history.go(`${window.location.origin}/seguimiento`)
            }, 500);
        }
        else {
            setTimeout(() => {
                history.go(`${window.location.origin}/toma`)
            }, 500);

        }
    }


    const reload = () => {
        window.location.reload(true);
    }




    //#endregion method


    //#region card-Input

    const [cardView, setCardView] = useState(false);
    const [focusCardInput, setFocusCardInput] = useState(true);

    const handleViewCard = () => {
        setCardView(true);

    }

    const handleCloseCard = () => {
        setCardView(!cardView);
    }


    const cardAutoFocus = () => {
        if (setFocusCardInput) {
            if (document.getElementById("inputFocusCardL") !== null) {
                document.getElementById("inputFocusCardL").focus();
            }
        }
    }

    useEffect(() => {

        cardAutoFocus()

    }, [setFocusCardInput]);


    function onKeyPressCard(event) {


        if (event.keyCode === 13) {

            let mValue = event.target.value;

            setLoading(true);

            if (mValue.length > 0) {

                iniciarSesionCard();
            }
            else {
                createSweett("", "Debes de ingresar una credencial valida", "warning");

                handleUpdateValuesT({
                    id_area: "",
                    id_departament: "",
                    userName: "",
                    password: "",
                    card_codes: ""
                })


                setFocusCardInput();
                setLoading(false);
            }
        }
    }

    const reloadSection = () => {
        window.location.reload(true);
        setFocusInNim(true);
    }


    //#endregion card-Input

    //#region listNim

    const [resultNim, setResultNim] = useState([]);
    const [enableNim, setEnableNim] = useState(false);

    const handleGetInformationnIM = async (id_user, id_process, id_sectio) => {

        let listTmp = await handleRequest("GET", `Tracking/DetailNimTracking2/${id_user}/${id_process}/${id_sectio}`, 'tracking');

        if (listTmp !== null) {
            //console.log(listTmp);
            setResultNim([...listTmp.trackingListNim])
        }
    }


    const handleViewNim = (id_quoter) => {
        setEnableNim(true);
    }


    const handleCloseViewNim = () => {
        setEnableNim(!enableNim);
    }

    //#endregion listNim



    //#region refused

    useEffect(() => {
        if (userSelected !== null) {
            onKeyPressRefused(userSelected.id_user, userSelected.id_section, userSelected.id_process)
        }
    }, [userSelected])


    const [formR, handleInputChangeR, , handleUpdateValuesR, handleSelectValuesR] = useForm({
        id_return_reason: 0,
        refusedNIm: ""

    });

    const { id_return_reason, refusedNIm } = formR;

    const [typeModalRefused, setTypeModalRefused] = useState("refused");
    const [modalRefused, setModalRefused] = useState(false);
    const [reason, setReason] = useState([]);
    const [refusedPatien, setRefusedPatien] = useState([]);
    const [inputAndCard, setInputAndCard] = useState(false);//input And Card


    const handleInputAndCard = () => {
        setInputAndCard(!inputAndCard);


        handleUpdateValuesR({
            refusedNIm: ""
        });
        setRefusedPatien([]);

    }

    const toggleRefused = () => {
        setInputAndCard(false)
        setModalRefused(!modalRefused);
    }

    const refused = (nim) => {
        setTypeModalRefused("refused");
        toggleRefused()
        setFocusInUser(false);
        setFocusInNim(false);
        handleReason()
        handleUpdateValuesR(
            {
                id_return_reason: 0,
                refusedNIm: ""
            }
        )
        setRefusedPatien([]);
    }

    const handleReason = async () => {
        let listTmp = await handleRequest("GET", "ReturnReason/List?is_sample=false&is_work_order=false&is_tracking=true&is_send=false", "seguimiento");



        //console.log(listTmp);

        if (listTmp !== null) {
            let list = [];

            listTmp.forEach((obj, i) => {

                list.push({
                    value: obj.id_return_reason,
                    label: obj.reason
                });
            });
            setReason([...list]);
        }
    }


    const [focusrefused, setFocusrefused] = useState(true);

    const refusedAutoFocus = () => {
        if (setFocusrefused) {
            if (document.getElementById("inputFocusRefused") !== null) {
                document.getElementById("inputFocusRefused").focus();
            }
        }
    }

    useEffect(() => {

        refusedAutoFocus()

    }, [setFocusrefused]);


    const handleRefusedPatient = async (nim_actual) => {

        let a = formR.refusedNIm
        let nims = nim_actual
        //console.log(a);//verificar
        //console.log(nims);

        let newForm = {
            "nim": nim_actual === "3" || nim_actual === 3 ? nims : a,
            id_section: headerSelected.id_section,
            id_process: headerSelected.id_process,
            id_user: userSelected.id_user,
            id_branch: 0
        };

        //console.log(newForm);

        let listTmp = await handleRequest("POST", "Tracking/StateFromNim", "Trackin", newForm);

        if (listTmp !== null) {

            setRefusedPatien(listTmp)
            setInputAndCard(!inputAndCard);
        }

        setInputAndCard(!inputAndCard);
    }



    function onKeyPressRefused(event) {
        if (event.keyCode === 13) {

            let nim_actual = event.target.value.substring(0, 13);

            handleRefusedPatient(nim_actual)
        }
    }

    //refused tracking

    const refusedPatient = async (id_work_order) => {

        let id_return_reason2 = formR.id_return_reason.value
        let id = id_work_order

        let newForm = [{
            id_work_order: id,
            id_return_reason: id_return_reason2,
        }];

        let listTmp = await handleRequest("POST", "Tracking/RefusedNimPatient", "Trackin", newForm);

        if (listTmp !== null) {
            setFocusInUser(true);
            setFocusInNim(true);
            setFocusrefused(false)
            createSweet("create", "success", "Se rechazó la muestra exitosamente!");
            toggleRefused();
            setTimeout(() => {
                handleGetInformationnIM(userSelected.id_user, userSelected.id_process, userSelected.id_section)
            }, 1000);
        }
        setTimeout(() => {
            handleGetInformationnIM(userSelected.id_user, userSelected.id_process, userSelected.id_section)
        }, 1000);
    }

    //#endregion refused



    //#region modal refused closed

    const [show10, setShow10] = useState(true)

    const handlerefusedManual = () => {

        setShow10(!show10);
    }

    function closedModalRefused() {

        setFocusInUser(true);
        setFocusInNim(true);
        setFocusrefused(false)
        toggleRefused()
    }

    //#endregion modal refused closed


    //#region Modal Info

    const [typeModalInfo, setTypeModalInfo] = useState("info");
    const [modalInfo, setModalInfo] = useState(false);
    const [dataPatient, setDataPatient] = useState([]);
    const [dataStudy, setDataStudy] = useState([]);
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [loadingRefused, setLoadingRefused] = useState(false);
    //patient
    const [namepatien, setNamepatien] = useState("");
    const [age, setAge] = useState("");
    const [curp, setCurp] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState(0);
    const [datanim, setDatanim] = useState(0);

    const [typeModalCan, setTypeModalCan] = useState("can");
    const [modalCan, setModalCan] = useState(false);


    const toggleInfoPatient = () => {
        setInputAndCard(false)
        setModalInfo(!modalInfo);

    }

    const toggleCancell = () => {
        setInputAndCard(false)
        setModalCan(!modalCan);
    }

    function closedModalInfo() {
        setFocusInUser(true);
        setFocusInNim(true);
        setFocusrefused(false)
        toggleInfoPatient();
        setLoadingRefused(false);
    }

    function closedModalCancell() {
        setFocusInUser(true);
        setFocusInNim(true);
        toggleCancell();
        //setLoadingRefused(false);

    }

    const handlePatientInfo = (_nim, _process, _section, _idUser) => {
        setTypeModalInfo("info");
        toggleInfoPatient();
        handlePatient(_nim, _process, _section, _idUser);
        getDataFromNim(_nim)

    }

    const handlePatientCancell = (_nim, _process, _section, _idUser) => {
        setFocusInUser(false);
        setFocusInNim(false);
        setFocusrefused(false)
        handleReason()
        //console.log("pasa");
        setTypeModalCan("can");
        toggleCancell();
        handlePatient(_nim, _process, _section, _idUser);
        // getDataFromNim(_nim)
    }

    const handlePatient = async (_nim, _process, _section, _idUser) => {
        setLoadingPatient(false);

        let _newForm = {
            "nim": _nim,
            "id_section": _section,
            "id_process": _process,
            "id_user": _idUser,
            "id_branch": localStorage.getItem("branchId"),
        }

        const _listTmp = await handleRequest("POST", "Tracking/GetExamsFromNim", "tracking", _newForm);
        if (_listTmp !== null) {
            setLoadingPatient(true);
            setDataStudy(_listTmp);
        }
    }

    const getDataFromNim = async (_nim) => {

        let _newForm = {
            "nim": _nim,
            "id_departament": 1
        }

        let _listTmp = await handleRequest("POST", "Sass/PatientSendSample", "Sample", _newForm);

        if (_listTmp !== null) {
            let _namePatient = ""
            let _idgender = 0
            let _curp = "";
            let _phone = "";
            let _age = "";
            let _nim = 0;

            _listTmp.lista_pruebas.forEach(_find => {
                _namePatient = _find.name_patient
                _idgender = _find.gender
                _curp = _find.curp
                _phone = _find.phone
                _age = _find.age
                _nim = _find.nim
            });
            setNamepatien(_namePatient);
            setGender(_idgender);
            setPhone(_phone);
            setCurp(_curp);
            setAge(_age);
            setDatanim(_nim);
        }
    }

    const refusedPatientSection = async (id_work_order) => {

        let _copy = dataStudy
        let _array = [];
        let id_return_reason2 = formR.id_return_reason.value
        let _body = [];


        _copy.forEach(_obj => {

             _body = [{
                "id_work_order": _obj.id_work_order,
                "id_work_order_exam": _obj.id_work_order_exam,
                "id_return_reason": id_return_reason2,
                "origen": "Seguimiento"
              }]
          
            
            _array.push({
                "id_work_order": _obj.id_work_order,
                "id_work_order_exam": _obj.id_work_order_exam,
                "id_return_reason": id_return_reason2,
                "list_refusedTracking": [
                    {
                        "refused_check_specimen": true,
                        "is_send1": false,
                        "is_send2": false,
                        "is_send3": false,
                        "check_specimen": true
                    }
                ]
            })

            
        });

       if (id_return_reason2 === null || id_return_reason2 === '' || id_return_reason2 === undefined)
       {
           createSweet("error", "warning", "Motivo de rechazo", "Por favor, selecciona un motivo de rechazo");
       }
       else
       {
            const _response =  await handleRequest("POST", "Sass/CreateRefusedGlobal", "refused", _body);

            let listTmp = await handleRequest("POST", "Tracking/RefusedSection", "Trackin", _array);

            if (listTmp !== null) {
                setFocusInUser(true);
                setFocusInNim(true);
                setFocusrefused(false)
                createSweet("create", "success", "Se rechazó la muestra exitosamente!");
                toggleCancell();
                setTimeout(() => {
                    handleGetInformationnIM(userSelected.id_user, userSelected.id_process, userSelected.id_section)
                }, 1000);
            }
            setTimeout(() => {
                handleGetInformationnIM(userSelected.id_user, userSelected.id_process, userSelected.id_section)
            }, 1000);
       }
    }





    //#endregion Modal Info
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            setLoadingRefused(true)
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }



    return {
        headers, users, nimCards, nim,
        headerSelected, userSelected,
        handleModalNim, getExamsFromNim, quitFocus, handleFocusInputNim,
        modal, toggle, loading, handleInputChange, typeModal, cerrarModalNim,
        exams, handleInputCheckboxChange, initTracking, changeUserSelected, changeHeaderSelected,
        scanUserValue, userAutoFocus, onChangeScanUser, onKeyPressScanUser, focusInUser,
        scanNimValue, nimAutoFocus, onChangeScanNim, onKeyPressScanNim, focusInNim,
        addOther,
        handleModalUser, toggle2, modal2, handleDepartamento, show, handleShow, show2,
        departamento, sections, process, userName, password, id_area, id_departament, handleInputChangeT,
        iniciarSesionCard,
        handleSelectValuesTFC, card_code, id_section, LoginTrankingFormCard, handleInputChangeTFC,
        deleteUser, deleteSectionprocess, cerrarSesion, tittle, reload,
        racks, rackInfo, arrayNims, modalRack, toggleRack, initTrackingFromRack, rackExist, initChangeRack, changeRackStatus, rackPrevious, rackNext, changeRack,
        removeRackNim, handleModalListNim, modal3, typeModalList, toggle3, llavecard, arrayExams,
        cerrarModalNimExam, labelNim, cerrarModalUsuario,
        cardView, handleViewCard, handleCloseCard,
        //card
        focusCardInput, onKeyPressCard, cardAutoFocus, setFocusCardInput, handleSelectValuesT, showText, card_codes,
        resultNim,
        enableNim, handleViewNim, handleCloseViewNim,
        typeModalRefused, modalRefused, toggleRefused, refused, reason, id_return_reason, handleSelectValuesR,
        refusedNIm, handleRefusedPatient, handleInputChangeR, refusedPatien,
        inputAndCard, handleInputAndCard,
        focusrefused, refusedAutoFocus, onKeyPressRefused, setFocusrefused, refusedPatient,
        show10, handlerefusedManual, closedModalRefused,
        //info Paient
        typeModalInfo, modalInfo, toggleInfoPatient, handlePatientInfo, closedModalInfo, dataPatient, dataStudy, loadingPatient,
        namepatien, age, curp, phone, gender, datanim, loadingRefused,
        //cancell
        typeModalCan, modalCan, toggleCancell, closedModalCancell, handlePatientCancell, refusedPatientSection
    }

}




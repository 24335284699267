import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Container, Row, Col, Label, Card } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useConsentimiento } from '../../../hooks/tomaMuestra/useConsentimiento';
import { useLogin } from '../../../hooks/tomaMuestra/useLogin';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { useEncuestaCliente } from '../../../hooks/tomaMuestra/useEncuestaCliente';
import { ModalCancellation } from "./ModalCancellation";
import { ModalExamListFlebotomista } from "./ModalExamListFlebotomista";
import { ModalObservationF } from './tracking/ModalObservationF';
import '../../../assets/css/pantalla1.css'
import '../../../assets/css/cartaConsentimiento.css'
import DeniReactTreeView from "deni-react-treeview"
import { ModalAccept } from './ModalAccept';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';

// const {
//     counter, handleClick1, handleClick2
// } = useSamplingForm();


export const IndexSamplingForm = () => {

  const {
    saveUpdateTmSample, saveUpdateTmSampleCancell,
    typeModalCancellation, modal5, toggle5, handleInputChangeCancellation,
    handleModalCancellToma, loading, comments, handleInputChangeComment,
    //refused sample patient
    id_cancellation_reazon_sample, handleSelectValuesR, reason, validationsSG, //sample
    handleSample, toggle6, flebotomiaRefused, modal6
  } = useEncuestaCliente();

  const { hour, minute, second } = useConsentimiento();

  const { time, reload, dataCancellation } = useLogin();

  const { nimm } = useParams();
  let name2 = localStorage.getItem('flebotomisaNombre');
  let name5 = localStorage.getItem('willson');
  let maquila = localStorage.getItem('maquila');

  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const [message, setMessage] = useState(true);
  const themes = ['green']
  const [itemsTree, setItemsTree] = useState([]);
  const treeviewRef = useRef(null);
  const [patien, setPatien] = useState("");
  const [idGender, setIdGender] = useState(0);
  const [idP, setIdP] = useState(0);
  const [loadin, setLoadin] = useState(false);
  const [nim, setNim] = useState("");
  const [pendingSample, setPendingSample] = useState([]);
  const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();
  const [idBranch, setIdBranch] = useState(0);

  //#region SAMPLINGS

  useEffect(() => {

    if (nimm !== undefined) {
      getDataFromNimPost(nimm)
    }
  }, [nimm]);



  const getDataFromNimPost = async (nimm) => {

    setLoadin(false)
    let _newForm = {
      nim: nimm.substring(0, 13),
      id_departament: localStorage.getItem('depW')
    }

    //console.log(_newForm);

    let _listTmp = await handleRequest("POST", "Sass/GetDataFromPatient", "Sample", _newForm);


    if (_listTmp !== null) {
      setLoadin(true);

      let nim = _listTmp.lista_pruebas;

      let _isToma = await handleRequest("GET", `Sample/DetailSample/${nim[0].nim}`, 'sample');
      

      let is_toma = _isToma[0];
      let validateToma = is_toma.finallyFech;


      let _idWorkOrder = 0;
      let _namePatient = "";
      let _idgender = 0;
      let _arrayNuevo = [];
      let _nimPatient = ""
      let _idBranch = 0;

      

      _listTmp.lista_pruebas.forEach(_find => {
        _idWorkOrder = _find.id_work_order
        _namePatient = _find.name_patient
        _idgender = _find.gender
        _nimPatient = _find.nim
        _idBranch = _find.id_brach

        _arrayNuevo.push({
          "profiles": _find.listProfile,
          "exams": _find.listExam,
          "is_toma" : _isToma.length > 0  && validateToma !== '' ? _isToma : ''
        })

        let tree = createTree(_arrayNuevo);
        setItemsTree(tree);
      });
      setPatien(_namePatient);
      setIdGender(_idgender);
      setIdP(_idWorkOrder);
      setNim(_nimPatient);
      setIdBranch(_idBranch);
    }
  }



  const createTree = (_arrayNuevo) => {

    let _tree = [];
    _arrayNuevo.forEach(_find => {

      if (_find.exams.length > 0) {
        _find.exams.forEach(_e => {
          _tree.push({
            "children": [],
            "id": _e.id_work_order_exam,
            "id_work_order": _e.id_work_order,
            "id_exam": _e.id_exam,
            "state": _e.refused_check_specimen === false ? 1 : 2,
            "text": _e.name,
            "isLeaf": _e.check_specimen,
            "refused_check_specimen": _e.refused_check_specimen,
            "check_forwarded": _e.check_forwarded,
            "is_toma": _e.refused_check_specimen === false ? _find.is_toma : ''
          })
        });
      }

      if (_find.profiles.length > 0) {
        _find.profiles.forEach(_p => {
          let examenes_aux = [];
          let id = true;
          let checkParent = 1;
          let checy = 0;

          _p.listExam.forEach((_e, Key) => {
            examenes_aux.push({
              "id": _e.id_work_order_exam,
              "id_work_order": _e.id_work_order,
              "id_exam": _e.id_exam,
              "isLeaf": _e.check_specimen,
              "state": _e.refused_check_specimen === false ? 1 : 2,
              "text": _e.name,
              "refused_check_specimen": _e.refused_check_specimen,
              "check_forwarded": _e.check_forwarded,
              "is_toma" :  _e.refused_check_specimen === false ? _find.is_toma : ''
            })
            id = _e.check_specimen
            checy = _e.refused_check_specimen === false ? 1 : 2
          });

          _tree.push({
            "children": examenes_aux,
            "state": checy,
            "text": _p.name_profile,
           
            // "state": 1,
          })

        });
      }
    });

    //console.log(_tree);
    return _tree;
  }



  const onRenderItem = (item, treeview) => {
   
    return (
      <div className="treeview-item-example">
        
          {
            
          item.check_forwarded === true || (item.is_toma  && item.is_toma !== '') ? 
          
          <span className='text-secondary'>
          <i className="icofont icofont-laboratory f-12"></i>{item.text}</span>

      
          :
          <span className=''>
          <i className="icofont icofont-laboratory f-12"></i>{item.text}
           </span>
        
          }
      </div>
    )
  }
  const [dataPending, setDataPending] = useState([]);

  const changeTree = (e) => {
    changeStatus(e)
  }

  const changeStatus = async (e) => {

    const api = treeviewRef.current.api
    const items = api.getItems();

    let pending2 = [];

    items.forEach(_find => {
      //console.log(_find);
      if (_find.id !== undefined) {
        //console.log("pasa aqui..............");
        if (_find.state === 2 && _find.check_forwarded == false) {
          pending2.push({
            "id_work_order": idP,
            "id_work_order_exam": _find.id,
            "list_refusedTracking": [
              {
                //"refused_check_specimen": true,
                "refused_check_specimen":_find.is_toma !== '' ? false : _find.state === 2 ? true : false,
                "text": _find.text
              }
            ]
          });
        }
        if (_find.state === 1 && _find.check_forwarded === false) {//modifica aqui esta parte
          pending2.push({
            "id_work_order": idP,
            "id_work_order_exam": _find.id,
            "entra": 0,
            "list_refusedTracking": [
              {
                "refused_check_specimen": false,
                "text": _find.text
                // "refused_check_specimen": _find.state === 2 ? true : false
              }
            ]
          });
        }//modifique aqui no
      }
      //profiles
      else {
        _find.children.forEach(ex => {
          if (ex.state === 2 && ex.check_forwarded === false) {
            pending2.push({
              "id_work_order": idP,
              "id_work_order_exam": ex.id,
              "list_refusedTracking": [
                {
                  //"refused_check_specimen": true,
                  "refused_check_specimen": ex.is_toma !== '' ? false : ex.state === 2 ? true : false


                }
              ]
            });
          }

          if (ex.state === 1 && ex.check_forwarded === false) {
            pending2.push({
              "id_work_order": idP,
              "id_work_order_exam": ex.id,
              "entra": 0,
              "list_refusedTracking": [
                {
                  "refused_check_specimen": false,
                  "text": _find.text
                  // "refused_check_specimen": ex.state === 2 ? true : false
                }
              ]
            });
          }
        })
      }
    });
    setPendingSample(pending2)
  }

  const savePendingMuestra = async () => {

    let listTmp = await handleRequest("POST", "Sass/RefusedSample2", "Sample", pendingSample);
    if (listTmp !== null) {
    }
  }


  const saveReload = (studies) => {
    let analytes = [];

    studies.forEach((item, index) => {
      let children = item.children;
      if(children.length > 0)
      {
         children.forEach((child, c) => {
          analytes.push({
            'id_work_order': child.id_work_order,
            'id_work_order_exam': child.id,
            'check': child.state,
            'check_forwarded' : child.check_forwarded
          });
         })
      }
      else
      {
        analytes.push({
            'id_work_order': item.id_work_order,
            'id_work_order_exam': item.id,
            'check': item.state,
            'check_forwarded' : item.check_forwarded
        });
      }
    })

    



    if(maquila === true || maquila === "true")
    {
      processMaquila(studies);
    }
     sampleProcess(analytes);
    savePendingMuestra();
    saveUpdateTmSample();
  }

  const sampleProcess = async (analytes) => {
    let listTmp = await handleRequest("POST", "Sample/SampleProcess", "Sample", analytes);
  }

  const processMaquila = async (studies) => {
    let maquilaExams = await handleRequest("GET", `ExamBranchesMaquila/List/${idBranch}`)
    let _listExams = studies;
    let _foundNotMaquila = [];
   
    _listExams.forEach((item, index) => {
      if(item.state === 1)
      {
        let _children = item.children;

         let foundMaquilaExam = maquilaExams.find(x => x.id_exam === item.id_exam);

        

         if(foundMaquilaExam === undefined){
          _foundNotMaquila.push({
            "id_work_order": item.id_work_order,
            "id_work_order_exam": item.id
          });
          }

         _children.forEach((child, IChild) => {
            if(child.state === 1)
            {
              let foundChild = maquilaExams.find(x => x.id_exam === child.id_exam);

              if(foundChild === undefined)
              {
                _foundNotMaquila.push({
                  "id_work_order": child.id_work_order,
                  "id_work_order_exam": child.id
                });
              }
            }
         })
      }
    })


    let processMaquila = await handleRequest("POST", "Sample/ProcessMaquila", "Sample", _foundNotMaquila)


    
  }

  const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
    let requestOptions = {
      method: method
    };

    if (body !== null) {
      requestOptions = {
        ...requestOptions,
        body: JSON.stringify(body)
      };
    }

    const response = await sendRequest(requestOptions, methodUrl);

    if (response.code === 200) {
      return response.data;
    }
    else if (response.code === 404) {
      createSweet("info", "info", "Atención", response.data.msg);
    }
    else if (response.code === 500) {
    }
    else {
    }
    return null;
  }

  return (
    <Fragment>
      <Container fluid={true} >
        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' >
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-l-1"> <br />
              <div className='box-departamento'>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                  <Row>
                    <Col xs="2" sm="2" md="2" lg="2" xl="2" >
                      <Label className='labelToma1'>{time}</Label>
                    </Col>
                    <Col xs="1" sm="1" md="1" lg="1" xl="1" >
                    </Col>

                    <Col xs="6" sm="6" md="6" lg="6" xl="6"  >
                      <label className="labelToma1">Toma de Muestra&nbsp;/&nbsp;{name5}</label>
                    </Col>
                    <Col xs="1" sm="1" md="1" lg="1" xl="1" >
                    </Col>
                    <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-1' style={{ textAlign: "-webkit-right" }}>
                      {
                        show1 ? (<label className='rc'>{hour}:{minute}:{second}</label>) : ""
                      }
                    </Col>
                    <Col xs="1" sm="1" md="1" lg="1" xl="1" className='p-2' >
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>

            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-l-0 p-r-0"  >

              <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                {
                  loadin === false ?
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center p-l-0 p-r-0'>
                      {/* style={{ height: "380px" }} */}
                      <Card className='shadow'>
                        <Row className='mt-2'> <br />
                          <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>  <br /> <br /> <br /> <br />
                            <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                          </Col>
                        </Row>
                        <Row className='mt-2'>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='text-center'></Col>
                          <Col xs="2" sm="2" md="2" lg="2" xl="2" className='text-left p-r-0'>
                            <p className='f-18 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
                          </Col>
                          <Col xs="1" sm="1" md="1" lg="1" xl="1" className='text-center'>
                            <br />
                            <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                          </Col>
                          <Col xs="1" sm="1" md="1" lg="1" xl="1" className='text-center'>
                            <br />
                            <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
                          </Col>
                          <Col xs="1" sm="1" md="1" lg="1" xl="1" className='text-center'>
                            <br />
                            <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                          </Col>
                          <Col sm="2" md="2" lg="2" className='text-center'></Col>
                        </Row>
                      </Card>
                    </Col>
                    :
                    <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
                      <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                        <label className="f-w-600 f-20 badge badge-light-secondary">HOLA&nbsp; {patien}</label>
                        {/* {
                          pendingSample.length === 0 ? ""
                            :
                            <i className="fa fa-exclamation-triangle f-26 txt-danger animate__animated animate__pulse animate__infinite pointer" onClick={handleSample} ></i>
                        } <br /> */}
                      </Col>
                      <Card className="shadow cardSampleD" style={{ height: "auto" }} >
                        <Row>
                          <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-r-0' > <br /> <br /> <br /> <br />
                            <div className="animate__animated animate__pulse"  >
                              {
                                (idGender) === 2 ?
                                  <div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "100%" }} alt="" /></div>
                                  :
                                  <div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "100%" }} alt="" /></div>
                              }
                            </div>
                          </Col>
                         
                          <Col xs="5" sm="5" md="5" lg="5" xl="5" > <br />
                          <label className="f-w-600 f-14 badge badge-light-secondary">Estudios a Realizar</label>
                          <Row>
                         
                                    <ul className="list-group" style={{display:'-webkit-box', width:'100%'}}>
                                    <li className="list-group-item list-group-item-success">A recibir</li>
                                      <li className="list-group-item list-group-item-secondary">En posesión </li>
                                    </ul>
                                 
                          </Row>
                          
                            <Row>
                              <DeniReactTreeView
                                ref={treeviewRef}
                                style={{ marginRight: '10px', marginBottom: '10px', height: "240px", textAlign: "center" }}
                                showCheckbox={true}
                                showIcon={false}
                                items={itemsTree}
                                selectRow={false}
                                onRenderItem={onRenderItem}
                                theme={themes}
                                onCheckItem={(e) => changeTree(e)}
                              />
                            </Row>
                            <Row>
                            </Row>
                          </Col>
                          <Col xs="5" sm="5" md="5" lg="5" xl="4" className="p-l-0 p-r-0"> <br />
                            {
                              message ?
                                (
                                  <div>
                                    <label className="f-w-600 f-14 badge badge-light-secondary">Comentario del Flebotomista</label>
                                    <textarea
                                      className='comentFlebotomista'
                                      name='comments'
                                      value={comments}
                                      onChange={handleInputChangeComment}
                                    />

                                  </div>
                                )
                                :
                                (
                                  <div  >
                                    {/* <label className="f-w-600 f-14 badge badge-light-secondary">Observaciones del Paciente</label>
                                  <div className='comentFlebotomista2 '>
                                    <label >{commetFlebotomista}</label>
                                  </div> */}
                                  </div>

                                )

                            }
                          </Col>
                        </Row>

                      </Card>

                    </Col>


                }

              </Col>

              <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-0" style={{ textAlign: "-webkit-center" }}>
                {/* <button className='button-samplingForm' onClick={() => saveUpdateTmSample()} >Finalizar Toma</button> */}
                <button className='button-samplingForm' onClick={() => saveReload(itemsTree)} >Finalizar Toma</button>

                {/* {  aqui viene lo de toma para que lo puedan modificar
                  dataPending.length === 0 ? <button className='button-samplingForm' onClick={() => saveUpdateTmSample()} >Finalizar Estudios</button>
                    : <button className='button-samplingForm' onClick={handleModalToma}>Finalizar estudios</button>
                } */}
              </Col>
            </Col >
          </Row>
          <Col style={{ position: 'absolute', zIndex: 5 }} className='float-right divMenu p-l-0'>
            {
              show ?
                (
                  ""
                )
                :
                (
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className='username-Active ' >
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0 p-r-0 p-0' style={{ textAlign: "-webkit-center" }} >
                            <br />
                            <i className="fa fa-circle f-20 icon-active"></i>
                          </Col>
                          <Col xs="10" sm="10" md="10" lg="10" xl="10" className='p-l-0 p-r-0'> <br />
                            <label className='r-label-active p-0 ' >{name2}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div> <br />
                    <div className='username-Active pointer'>
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                            <img className=" " src={require("../../../assetsToma/imagenes/Recargar.png")} style={{ width: "43%" }} alt="" />
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 ' onClick={reload} > <br />
                            <label className='r-label-active p-0 pointer'>{"Recargar"}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div> <br />
                    <div className='username-Active pointer' onClick={() => { setShow1(!show1); }}>
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                            <img className=" " src={require("../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "45%" }} alt="" />
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                            <label className='r-label-active p-0 pointer'>{"Cronómetro"}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div><br />
                    <div className='username-Active pointer' onClick={handleModalCancellToma} >
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                            <img className=" " src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "36%" }} alt="" />
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                            <label className='r-label-active-omitir-Encuesta p-0 pointer' >{"Cancelar Toma"}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div> <br />
                  </Col>
                )
            }
          </Col>



        </Col>
        {/* <div sm="12" className='tap-top-cardToma' style={{ display: "block" }} >
          <img style={{ width: "15%", filter: "brightness(0.5)" }} src={require("../../../assetsToma/imagenes/Frascos.gif")} />
        </div> */}

        <div className='tap-top' style={{ display: "block" }} onClick={() => { setShow(!show) }}><i className="icon-angle-double-up f-24"></i>
        </div>
        {/* {
          commetFlebotomista === null || commetFlebotomista === "" ?
            ""
            :
            <div className='tap-top-chat' style={{ display: "block" }} onClick={() => { setMessage(!message) }}>
              <img className="imgMessage" src={require("../../../assets/images/loginHelenLabs/TomaDeMuestra/correo.gif")} /> <br />
            </div>
        } */}

      </Container>

      <ModalCancellation
        {
        ...{
          dataCancellation, typeModalCancellation, toggle5, modal5, handleInputChangeCancellation, loading,
          saveUpdateTmSampleCancell, handleSelectValuesR, id_cancellation_reazon_sample, reason, validationsSG,
          name2, name5, patien, nim
        }
        }
      />

      <ModalAccept
        {
        ...{
          handleSample, toggle6, flebotomiaRefused, modal6, pendingSample,
          name2, name5, patien, nim
        }
        }

      />
    </Fragment>
  )
}

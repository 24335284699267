import React from 'react'
import { useCallback, useEffect, useState } from 'react';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory, useParams } from 'react-router';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useDebounce } from '../debounce/useDebounce';

export const useExam = (isSimple = false) => {

    //* PARAMS VIEWS
    const history = useHistory();
    const { examEditId } = useParams();
    const { examEditIm } = useParams();

    //*PAGINATION
    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 500);
    const [filters, setfilters] = useState("&is_referenced=false");

    //*SECTIONS
    const [listSection, setListSection] = useState([]);
    const [sections, setSections] = useState([]);
    const [sections2, setSections2] = useState([]);
    const [subSections, setSubSections] = useState([]);

    //*TRASPORTS AND SPECIMEN
    const [specimenOrigins, setSpecimenOrigins] = useState([]);
    const [specimens, setSpecimens] = useState([]);
    const [specimenProvenances, setSpecimenProvenances] = useState([]);
    const [typeTransports, setTypeTransports] = useState([]);
    const [transports, setTransports] = useState([]);

    //*COMERCIAL LINE
    const [commercialLines, setCommercialLines] = useState([]);

    //*EXAMS
    const [examsData, setExamsData] = useState([]);
    const [exams, setExams] = useState([]);
    const [examId, setExamId] = useState(0);

    //* MODAL
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    //*MENU
    const [tabGeneral, setTabGeneral] = useState('1');

    //*MODAL INDICATIONS
    const [modal2, setModal2] = useState(false);
    const [listIndications, setListIndications] = useState([]);
    const [indicationn, setIndicationn] = useState([]);
    const [selectIndication, setSelectIndication] = useState([]);

    //*MODAL IMAGENOLOGÍA
    const [isImagenologyy, setIsImagenologyy] = useState(false)
    const [methodImagenolofia, setMethodImagenolofia] = useState("");
    const [titleImagenologia, setTitleImagenologia] = useState("");
    const [modalImagenologia, setModalImagenologia] = useState(false);

    //*APP CODE
    const [appCodeMethodId, setAppCodeMethodId] = useState(0);
    const [dataAppCodeMethod, setDataAppCodeMethod] = useState(null);

    //*LIST INDICATIONS
    const [dataIndication, setDataIndication] = useState([])

    //*VALIDATIO
    const [validationSectionArea, setValidationSectionArea] = useState(false);

    //*CONFIGURATION EXTERNAL LABORATORY
    const [dataLaboratory, setDataLaboratory] = useState([])


    const goToBack = () => {
        history.goBack();
    }

    const [formValuesFilter, handleInputChangeFilter, resetFilter, handleUpdateValuesFilter, handleSelectValuesFilter, handlePickerValuesFilter] = useForm({
        is_referencedf: "false",
        is_simplef: "",
        individual_salef: "",
        is_criticf: "",
        is_completef: "",
        id_sectionf: "0",
    });

    const { is_referencedf, is_simplef, individual_salef, is_criticf, is_completef, id_sectionf } = formValuesFilter;

    //#region Paginations
    //*PAGINATION AND FILTERS EXAMS
    const handleFilters = () => {

        let query = "";

        if (is_referencedf !== "") {
            query += `&is_referenced=${is_referencedf}`;
        }

        if (is_simplef !== "") {
            query += `&is_simple=${is_simplef}`;
        }

        if (individual_salef !== "") {
            query += `&individual_sale=${individual_salef}`;
        }

        if (is_criticf !== "") {
            query += `&is_critic=${is_criticf}`;
        }

        if (is_completef !== "") {
            query += `&is_complete=${is_completef}`;
        }

        if (id_sectionf !== "0") {
            query += `&id_section=${id_sectionf}`;
        }

        setfilters(query);
    }

    const handleSearch = (e) => {
        let busqueda = e.target.value;
        setSearchQuery(busqueda);
    }

    useEffect(() => {
        searchAsync();
    }, [debounceValue])

    useEffect(() => {
        searchAsync();
    }, [filters])

    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "Exam/List",
        history,
        searchQuery,
        filters
    );


    useEffect(() => {
        let listExams = [];

        results.forEach((data, key) => {
          
            data.is_complete = (data.is_complete === true ? <i className="fa fa-check txt-success"></i> : <i className="fa fa-times text-danger"></i>)
            listExams.push({
                ...data,
                typeExam: data.is_simple ? "Sencillo" : (data.is_imagenology === true ? "Imagenologia" : (data.is_micro === true ? "Micro" : "Complejo")),
                visibleTable: true
            });
        });
        setExamsData(results);
        setExams(listExams);

    }, [results]);
    //#endregion

    //*METHODS 
    useEffect(() => {
        getCommercialLines();
        getSections();
        getSpecimens();
        getSpecimenProvenances();
        getTransports();
        getSection();
        getSections2();
        getLaboratory();
    }, []);

    useEffect(() => {

        getExamIndicationSelect();

    }, [history.location]);


    //#region Sections 

    //*SECTIONS
    const getSection = async () => {

        let _listTemp = await _handleRequest("GET", "Section/List/false", "Secciones");

        if (_listTemp !== null) {
            let _list = [];

            _listTemp.forEach((_find) => {
                if (!_find.is_micro) {
                    _list.push({
                        id_section: _find.id_section,
                        name: _find.name
                    })
                }
            });
            setListSection(_list);   
        }
    }


    const handleChangeSection = (e) => {

        handleInputChangeFilter(e)
        let _find = e.target.value

        getSection(_find)
    }

    const getSections = async () => {

        let _listTemp = await _handleRequest("GET", "Section/List/false", "Secciones");

        if (_listTemp !== null) {
            setSections(_listTemp)
        }
    }

    const getSections2 = async () => {

        let _listTemp = await _handleRequest("GET", "Section/List/true", "Secciones");

        if (_listTemp !== null) {
            setSections2(_listTemp);   
        }
    }

    //#endregion

    //#region Configuration Modal

    //*VALID RELEASE EXAM
    const [releaseExam, setReleaseExam] = useState({
        exams_transports: false,
        production_cost: false,
        valid_method_default: false,
        valid_analyte: false,
        valid_analyte_age_gender_range: false,
        valid_reference_values: false,
        valid_indications: false
    });
    const [modalReleaseExam, setModalReleaseExam] = useState(false);
    const [loadingReleaseExam, setLoadingReleaseExam] = useState(false);

    const changeTabGeneral = (number) => {
        setTabGeneral(number);
    }

    const toggle = () => {
        setModal(!modal);
    }

    //MODAL INDICATIONS
    const toggle2 = () => {
        setModal2(!modal2)
    }

    //MODAL RELEASE EXAM
    const toggleRelease = () => {
        setModalReleaseExam(!modalReleaseExam)
    }

    //MODAL IMAGENOLOGIA

    const toggleImagenologia = () => {
        setModalImagenologia(!modalImagenologia);
    }

    //#endregion

   
    const [formValues, handleInputChange, , handleUpdateValues, handleSelectValues] = useForm({
        id_section: 0,
        id_subsection: 0,
        id_specimen_origin: 0,
        id_specimen: 0,
        id_specimen_provenance: 0,
        code: "",
        alias: "",
        name: "",
        abbreviation: "",
        delivery_time: "",
        individual_sale: false,
        cost: 0.0,
        is_referenced: false,
        is_critic: false,
        is_curve: false,
        listExternalLaboratories: [],
        list_transports: [],
        number_projections: 0,
        enable_analyte_configuration: false,
        print_new_page: false
    });

    const { id_section, id_subsection, id_specimen_origin, id_specimen, id_specimen_provenance, code, alias, name,
        abbreviation, delivery_time, individual_sale, cost, is_referenced, is_critic, is_curve, listExternalLaboratories,
        list_transports, number_projections, enable_analyte_configuration, print_new_page
        
    } = formValues;


    const [validaciones, setValidaciones] = useState({
        id_section: false,
        id_specimen_origin: false,
        code: false,
        alias: false,
        name: false,
        abbreviation: false,
    });


    //#region Configuration method

    const changeMethodDefault = ({ target }) => {
        setAppCodeMethodId(target.value);
    }

    const saveAppCodeMethodFromExam = async () => {
        setLoading(true);
        let formData = {
            "id_app_code_method": appCodeMethodId,
            "id_exam": examId
        }

        setLoading(true);

        let raw = JSON.stringify(formData);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "ExamMethods/AddExamMethodMachine");
        if (respuesta.code === 200) {
            createSweet("create", "success", "Método seleccionado correctamente");
            let name_method = respuesta.data.name_method;
            console.log(name_method);
            setDataAppCodeMethod(name_method);
            changeTabGeneral("3");
            let location = {
                pathname: '../examenes/simple/' + examId,
                state: { changeTab: 2 }
            }
            history.replace(location);
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveAppCodeMethodFromExam);
        }
        else if (respuesta.code === 500) {
            setLoading(false);
            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    const updateAppCodeMethodExam = async () => {

        setLoading(true);

        let formData = {
            "id_app_code_method": appCodeMethodId,
            "id_exam": examId
        }

        setLoading(true);

        let raw = JSON.stringify(formData);

        let requestOptions = {
            method: 'PUT',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "ExamMethods/UpdateExamMethodMachine");
        if (respuesta.code === 200) {
            createSweet("create", "success", "Método seleccionado correctamente");
            let name_method = respuesta.data.name_method;
            console.log(name_method);
            setDataAppCodeMethod(name_method);

        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, updateAppCodeMethodExam);
        }
        else if (respuesta.code === 500) {
            setLoading(false);

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {
            setLoading(false);

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    //#endregion

    //#region Configurations transport
   //*CONFIGURATIONS METHODS GET
    
    const getSubsections = async (id_section) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `SubSection/GetRelationSubsection/${id_section}`);
        if (respuesta.code === 200) {
            setSubSections(respuesta.data);
        }
    }


    const getSpecimenOrigins = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenOrigins");
        if (respuesta.code === 200) {
            setSpecimenOrigins(respuesta.data);
        }
        else {
            validarSesion(history, respuesta.code, getSpecimenOrigins);
        }
    }

    const getCommercialLines = async () => {

        let _listTemp = await _handleRequest("GET", "CommercialLine/List", "Linea Comercial");

        if (_listTemp !== null) {
            let _newCommercialLine = [];

            _listTemp.forEach(_find => {
                _newCommercialLine.push({
                    id_commercial_line: _find.id_commercial_line,
                    long_name: _find.long_name,
                    short_name: _find.short_name,
                    price_commercial_line: 0
                })
            });
            setCommercialLines(_newCommercialLine);
        }
    }

    const getSpecimens = async () => {

        let _listTemp = await _handleRequest("GET", "Specimen/List", "Specimen");

        if (_listTemp !== null) {
            let _newSpecimens = _listTemp;

            _newSpecimens.map(function (x) {
                x.value = x.id_specimen;
                x.label = x.name;
                return x;
            })
            setSpecimens(_newSpecimens);
        }
    }

    const getSpecimenProvenances = async () => {

        let _listTemp = await _handleRequest("GET", "SpecimenProvenances/List", "Lista proviene");

        if (_listTemp !== null) {
            let _newSpecimenProvences = _listTemp;

            _newSpecimenProvences.map(function (x) {
                x.value = x.id_specimen_provenance;
                x.label = x.name;
                return x;
            })
            setSpecimenProvenances(_newSpecimenProvences);
        }
    }

    const getTransports = async () => {

        let _listTemp = await _handleRequest("GET", "Transport", "Transporte");

        if (_listTemp !== null) {
            let _newTransports = _listTemp;

            _newTransports.map(function (x) {
                x.value = x.id_transport;
                x.label = `${x.name}[${x.abbreviation}]`;
                return x;
            })
            setTransports(_newTransports);
        }
    }

    const getTypeTransports = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "TypeTransports");
        if (respuesta.code === 200) {
            let newTypeTransports = respuesta.data;
            newTypeTransports.map(function (x) {
                x.value = x.id_type_transport;
                x.label = x.name;
                return x;
            })

            setTypeTransports(newTypeTransports);
        }
        else {
            validarSesion(history, respuesta.code, getTypeTransports);
        }
    }
    //#endregion
    
    //#region create
    const handleCreate = () => {
        handleUpdateValues({
            id_section: 0,
            id_subsection: 0,
            id_specimen_origin: 0,
            code: "",
            alias: "",
            name: "",
            abbreviation: "",
            delivery_time: "",
            individual_sale: false,
            cost: 0.00,
            is_referenced: false,
            is_critic: false,
            is_curve: false,
            print_new_page: false
        });

        setValidaciones({
            id_section: false,
            id_specimen_origin: false,
            code: false,
            alias: false,
            name: false,
            abbreviation: false,
            individual_sale: false,
            is_referenced: false
        });

        setExamId(0);
        setMethod("create");
        setModalTitle("Crear nuevo exámen");
        getSections();
        getCommercialLines();
        getSpecimens();
        getSpecimenProvenances();
        getTransports();
        toggle();
        setIsImagenologyy(false);
    }

    const handleCreateExamImagenologia = () => {
        handleUpdateValues({
            id_section: 0,
            id_subsection: 0,
            id_specimen_origin: 0,
            code: "",
            alias: "",
            name: "",
            abbreviation: "",
            delivery_time: "",
            individual_sale: false,
            cost: 0.00,
            is_referenced: false,
            is_critic: false,
            number_projections: 0,
            print_new_page: false
        });

        setExamId(0);
        getSections2();
        setMethodImagenolofia("createImagen")
        setTitleImagenologia("Crear exámen nuevo")
        toggleImagenologia();
        setIsImagenologyy(true);
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }
        
        if (!validationSectionArea) {
            return;
        }
        setLoading(true);

        let _valid_laboratory_external = formValues.is_referenced === true ? 1 : 2

        let body = {
            list_prices: commercialLines,
            ...{ ...formValues },
            list_transports: (isImagenologyy ? [] : formValues.list_transports),
            "id_specimen": id_specimen.value,
            "id_specimen_provenance": id_specimen_provenance.value,
            "is_simple": isSimple,
            //TODO: SI LA VARIABLE isImagenologyy == true -> hay que poner enable_analyte_configuration en 'false' -> si no es true
            //YA QUE LOS EXAMENES DE IMAGENOLOGIA NO LLEVAN CONFIGURACION DE ANALITOS
            "enable_analyte_configuration": (isImagenologyy ? false : true),
            "external_laboratory_info":dataLaboratory
        }
        
        let raw = JSON.stringify(body);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "Exam");
        if (respuesta.code === 200) {
            createSweet("create", "success");
            setLoading(false);

            if (isImagenologyy) {
                setExamId(respuesta.data.id_exam);
                toggleImagenologia();
                searchAsync();
            }
            else if (isSimple) {
                setExamId(respuesta.data.id_exam);
                changeTabGeneral('2');
                return respuesta.data.id_exam;
            } else {
                toggle();
                navigateConfigExam(respuesta.data.id_exam);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        setLoading(false);

    }

    //#endregion

     //#region update
   const handleUpdate = (id_exam) => {
    let exam = exams.find(e => e.id_exam === parseInt(id_exam));

    handleUpdateValues({
        id_section: exam.id_section,
        id_subsection: exam.id_subsection === null ? 0 : exam.id_subsection,
        id_specimen_origin: exam.id_specimen_origin,
        code: exam.code,
        alias: exam.alias,
        name: exam.name,
        abbreviation: exam.abbreviation,
        delivery_time: exam.delivery_time,
        individual_sale: exam.individual_sale,
        is_referenced: exam.is_referenced,
        is_critic: exam.is_critic,
        is_curve: exam.is_curve,
        listExternalLaboratories: exam.listExternalLaboratories,
        id_specimen: specimens.find(x => x.value === exam.id_specimen),
        id_specimen_provenance: specimenProvenances.find(x => x.value === exam.id_specimen_provenance),
        list_transports: exam.select_transports,
        print_new_page: exam.print_new_page
    });

    setValidaciones({
        id_section: false,
        id_specimen_origin: false,
        code: false,
        alias: false,
        name: false,
        abbreviation: false
    });

    setExamId(id_exam);
    setMethod("update");
    setModalTitle("Modificar exámen");
    getSections();
    getSubsections(id_section);
    getCommercialLines();
    toggle();
    setIsImagenologyy(false);
}

const handleUpdateImagenology = (id_exam) => {
    let exam = exams.find(e => e.id_exam === parseInt(id_exam));

    handleUpdateValues({
        id_specimen_origin: exam.id_specimen_origin,
        id_specimen_provenance: specimenProvenances.find(x => x.value === exam.id_specimen_provenance),
        id_specimen: specimens.find(x => x.value === exam.id_specimen),
        id_section: exam.id_section,
        id_subsection: exam.id_subsection === null ? 0 : exam.id_subsection,
        code: exam.code,
        alias: exam.alias,
        name: exam.name,
        abbreviation: exam.abbreviation,
        delivery_time: exam.delivery_time,
        individual_sale: exam.individual_sale,
        is_referenced: exam.is_referenced,
        is_critic: exam.is_critic,
        number_projections: exam.number_projections,
        print_new_page: exam.print_new_page
    });
    setExamId(id_exam);
    setMethodImagenolofia("updateImagen")
    setTitleImagenologia("Actualizar el exámen")
    getSections2();
    getSubsections(id_section);
    getCommercialLines();
    toggleImagenologia();
    setIsImagenologyy(true);
}

const saveUpdate = async (e, id_exam) => {
    e.preventDefault();

    if (validarForm()) {
        return;
    }

    if (id_section === 0 || id_section === "0") {
        return;
    }

    setLoading(true);
    let _valid_laboratory_external = formValues.is_referenced === true ? 1 : 2


    let body = {
        id_exam: examId,
        ...{ ...formValues },
        list_prices:commercialLines,

        //list_prices: commercialLines,
        "id_specimen": id_specimen.value,
        "id_specimen_provenance": id_specimen_provenance.value,
        "enable_analyte_configuration": isImagenologyy,
        "external_laboratory_info":dataLaboratory

    };

    console.log(body);

    let requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const respuesta = await sendRequest(requestOptions, "Exam/Update");

    if (respuesta.code === 200) {

        if (isSimple) {
            createSweet("update", "success");
            setLoading(false);

        }
        else if (!isSimple && isImagenologyy) {
            createSweet("update", "success")
            setLoading(false)
            toggleImagenologia()
            searchAsync();

        }
        else if (!isSimple && !isImagenologyy) {
            exams.map(e => {
                if (e.id_exam === parseInt(examId)) {
                    e.select_transports = formValues.list_transports;
                }
                return e;
            });

            createSweet("update", "success");
            setLoading(false);

            toggle();
            searchAsync();
        }
    }
    else if (respuesta.code === 401) {

        validarSesion(history, respuesta.code, saveUpdate);
    }
    else if (respuesta.code === 500) {

        createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
    }
    else {

        createSweet("error", "warning", "Examen", respuesta.data.msg);
    }
 
}

   //#endregion update
   
     //#region delete
     const handleDelete = (id_exam) => {
        let exam = exams.find(e => e.id_exam === parseInt(id_exam));

        handleUpdateValues({
            name: exam.name,
            code: exam.code,
            alias: exam.alias
        });

        setExamId(id_exam);
        setMethod("delete");
        setModalTitle("Eliminar exámen");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Exam/${examId}`);

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("delete", "success");
            let examenes = exams;
            examenes = examenes.filter(x => x.id_exam !== parseInt(examId));
            setExams([...examenes])
            toggle();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveDelete);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
       
    }

     //#endregion
 
     //#region indications exams
    //*INDICATIONS
    const [formIndication, handleInputChangeIndication, resetIndication, handleUpdateValuesIndication, handleSelectValuesIndication] = useForm({
        listIndication: [],
    });

    const { indication, id_exam_indication, listIndication } = formIndication;

    const handleListProfile = (id_exam) => {

        setExamId(id_exam);
        setMethod("list");
        setModalTitle("Asignar indicaciones al exámen");
        toggle2();
        getListIndicationsByExam(id_exam)
    }

    const getExamIndicationSelect = async () => {

        let _listTemp = await _handleRequest("GET", "ExamIndication/List", "Indicaciones");

        if (_listTemp !== null) {
            let _new_array = [];

            _listTemp.forEach(_find => {
                let obj = {
                    "value": _find.id_exam_indication,
                    "label": _find.indication,
                    "name": _find.indication,
                }
                _new_array.push(obj);
            });
            setDataIndication(_new_array);
        }
    }

    const getListIndicationsByExam = async (id_exam) => {

        if (id_exam === 0) {
            return false; 
        }

        let _listTemp = await _handleRequest("GET", `Exam/ListIndicationsByExam/${id_exam}`, "Indicaciones de Examenes");

        if (_listTemp !== null) {
            let _arrTmp = [];

            _listTemp.forEach(_find => {
                _arrTmp.push({
                    "value": _find.id_exam_indication,
                    "label": _find.indication,
                    "name": _find.indication,
                })
            });

            handleUpdateValuesIndication({
                ...formIndication,
                listIndication: [..._arrTmp]
            });
        }
    }

    const CreateOrUpdateExamIndications = async (e) => {

        e.preventDefault();
        setLoading(true);

        let newList = formIndication.listIndication;

        newList.map(element => {
            element.id_exam_indication = element.value;
        });

        formIndication.listIndication = newList

        let mData = {
            id_exam: examId,
            listIndications: formIndication.listIndication
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(mData)
        };

        const respuesta = await sendRequest(requestOptions, "Exam/CreateOrUpdateExamIndications");
        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "info", "Exito!", "Se han asignado correctamente las indicaciones");
            toggle2();

        }
    }

    //#endregion


    const handleChangePrice = (e) => {

        const _tmpPrices = [...commercialLines];

        _tmpPrices[e.target.dataset.id][e.target.name] = e.target.value;

        setCommercialLines(_tmpPrices);
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        if (formValues.id_section <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_section: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_section: false
            }
        }

        if (formValues.id_specimen_origin <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_origin: true
            }
            statusValidacion = false;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_origin: false
            }
        }

        if (formValues.code.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                code: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                code: false
            }
        }

        if (formValues.alias.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                alias: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                alias: false
            }
        }

        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        if (formValues.abbreviation.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                abbreviation: true
            }
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                abbreviation: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }

    const changeSelect = (e) => {
        handleInputChange(e);
        let mValue = e.target.value;

        if(mValue === "0"){
            setValidationSectionArea(false);
        } else {
            setValidationSectionArea(true);
            getSubsections(mValue);
        }

    }

    //#region Navigate
    const navigateMicroAnalytes = (examId) => {
        history.push("../micro/diseno/" + examId + "/analitos");
    }

    const navigateCreateOrUpdateAnalytes = (examId) => {
        history.push("../diseno/examenes/" + examId + "/analitos");
    }

    const navigateConfigExam = (examId) => {
        history.push("../diseno/examenes/" + examId + "/metodos");
    }

    const navigateCostProduction = (examId) => {
        history.push("../diseno/examenes/" + examId + "/precios");
    }

    const navigateCreateExamSimple = () => {
        history.push("../diseno/examenes/simple");
    }

    const navigateEditExamSimple = (examId) => {
        let find_exam = exams.find(x => x.id_exam === examId);


        let is_simple = find_exam.is_simple;
        let is_imagenology = find_exam.is_imagenology;

        if (is_simple) {
            history.push("../diseno/examenes/simple/" + examId);

        }
        else if (!is_simple && is_imagenology) {
            handleUpdateImagenology(examId)
        }
        else if (!is_simple && !is_imagenology) {
            handleUpdate(examId)
        }
    }

    //#endregion

    useEffect(() => {
        if (examEditId > 0) {
            setExamId(examEditId);
            setMethod("update");
            getExam(examEditId);
        }
    }, [examEditId])


    useEffect(() => {
        if (examEditIm > 0) {
            setExamId(examEditIm)
            setMethodImagenolofia("updateImagen")
            getExam(examEditIm);
        }
    }, [examEditIm])


    const getExam = async (examId) => {
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, "Exam/GetExam/" + examId);

        if (respuesta.code === 200) {
            handleUpdateValues({
                ...formValues,
                id_section: respuesta.data.id_section,
                id_subsection: respuesta.data.id_subsection === null ? 0 : respuesta.data.id_subsection,
                // id_specimen_origin: respuesta.data.id_specimen_origin,
                id_specimen: respuesta.data.select_specimen,
                id_specimen_provenance: respuesta.data.select_specimen_provenance,
                code: respuesta.data.code,
                alias: respuesta.data.alias,
                name: respuesta.data.name,
                abbreviation: respuesta.data.abbreviation,
                delivery_time: respuesta.data.delivery_time,
                individual_sale: respuesta.data.individual_sale,
                // cost: respuesta.data.cost,
                is_referenced: respuesta.data.is_referenced,
                is_critic: respuesta.data.is_critic,
                is_curve: respuesta.data.is_curve,
                listExternalLaboratories: respuesta.data.listExternalLaboratories,
                list_transports: respuesta.data.select_transports,
                is_imagenology: respuesta.data.is_imagenology,
                enable_analyte_configuration: respuesta.data.enable_analyte_configuration,
                number_projections: respuesta.data.number_projections,
                print_new_page: respuesta.data.print_new_page,
              //  dataLaboratory : respuesta.external_laboratory_info
            })
            setDataLaboratory([...respuesta.data.external_laboratory_info])

          //  setDataLaboratory(respuesta.external_laboratory_info)
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getExam);
        // }
    }


    const getUpdateDefaultExternalLaboratory = async (examId, id_external_laboratory) => {

        let requestOptions = {
            method: 'GET'
        };
    
        const respuesta = await sendRequest(requestOptions, `Exam/UpdateDefaultExternalLaboratory/${examId},${id_external_laboratory}`);

        if (respuesta.code === 200) {

            let copy = exams;
            let arrayExam = copy.find(e => e.id_exam === parseInt(examId));

            arrayExam.listExternalLaboratories.forEach((obj, i) => {
                if (obj.id_external_laboratory === parseInt(id_external_laboratory)) {
                    obj.is_default = true;
                }
                else {
                    obj.is_default = false;
                }
            })
            setExams([...copy]);
        }
       
    }

    const handleGetValidReleaseExam = async (id_exam) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Exam/ValidReleaseExam/${id_exam}`);

        if (respuesta.code === 200) {
            setReleaseExam({
                exams_transports: respuesta.data.exams_transports,
                production_cost: respuesta.data.production_cost,
                valid_method_default: respuesta.data.valid_method_default,
                valid_analyte: respuesta.data.valid_analyte,
                valid_analyte_age_gender_range: respuesta.data.valid_analyte_age_gender_range,
                valid_reference_values: respuesta.data.valid_reference_values,
                valid_indications: respuesta.data.valid_indications,
                is_imagenology: respuesta.data.is_imagenology,
                enable_analyte_configuration: respuesta.data.enable_analyte_configuration,
                valid_number_projections: respuesta.data.valid_number_projections

            });
            setExamId(id_exam);
            toggleRelease();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    const saveUpdateStatusComplete = async () => {

        let requestOptions = {
            method: 'GET'
        };

        setLoadingReleaseExam(true);

        const respuesta = await sendRequest(requestOptions, `Exam/UpdateCompleteExam/${examId}`);

        if (respuesta.code === 200) {
            createSweet("success", "success", "Examen", "El estatus del examen se cambio con éxito");

            let examenes = exams;

            let find = examenes.find(x => x.id_exam === examId);

            let icon = <i className="fa fa-check txt-success"></i>

            if (find) {
                find.is_complete = icon
                setExams(
                    examenes,
                    find
                );
            }

            toggleRelease();
            setLoadingReleaseExam(false);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
            setLoadingReleaseExam(false);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Examen", "Ocurrio un problema en el servidor");
            setLoadingReleaseExam(false);
        }
        else {
            setLoadingReleaseExam(false);
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    //*CONFIGURATION EXTERNLA LABORATORY

        const getLaboratory = async () => {

        let _listTemp = await _handleRequest("GET", "ExternalLaboratories/List", "Laboratorios externos");

        if (_listTemp !== null) {
            let _newLaboratory = [];
            _listTemp.forEach((_obj) => {
            _newLaboratory.push({
                id_external_laboratory: _obj.id_external_laboratory,
                external_laboratory: _obj.name,
                price: 0,
                cost: 0,
                default_lab:false
                });
            });
            setDataLaboratory(_newLaboratory);
            }
        }

        const handleChangePriceIni = (e, _id_external) => {

            const _value = e.target.value;
            const _name_input = e.target.name;
           // let _v = parseInt(_value);

            const _find_test = dataLaboratory.find(x => x.id_external_laboratory === _id_external);
        
            if (_find_test !== undefined) {
                switch (_name_input) {
                    case "amount":
                        _find_test.price = _value;
                        break; 
                    case "cost":
                        _find_test.cost = _value;
                        break;
                    case "default_lab":
                        dataLaboratory.forEach(item => {
                            if (item.id_external_laboratory !== _id_external) {
                                item.default_lab = false;
                            }
                        });
                        _find_test.default_lab = true;
                        break;
                }
            }
            setDataLaboratory([...dataLaboratory]);
        };

    //* CONEXION
    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 404) {
          createSweet("warning", "warning", nameMethod, "No se ha realizado ningún deposito bancario en este dia");
        }
        else {
            createSweet("warning", "warning", nameMethod, response?.data?.msg);
        }

        return null;
    }



    return {
        examEditId, formValues, alias, exams, method, handleInputChange, handleSelectValues, validaciones,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, id_specimen, id_specimen_provenance, code, alias, name, abbreviation, delivery_time,
        individual_sale, cost, is_referenced, print_new_page,
        toggle, modal, modalTitle, loading, listExternalLaboratories, list_transports, examId, is_critic, is_curve,
        changeSelect, validationSectionArea,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        navigateCreateOrUpdateAnalytes, navigateMicroAnalytes,
        goToBack, navigateConfigExam, navigateCostProduction, navigateCreateExamSimple, navigateEditExamSimple,
        handleChangePrice, getUpdateDefaultExternalLaboratory,
        appCodeMethodId, changeMethodDefault,
        dataAppCodeMethod, saveAppCodeMethodFromExam, updateAppCodeMethodExam,
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
        tabGeneral, changeTabGeneral,
        handleSearch,
        //MODAL INDICATIONS
        toggle2, modal2, handleListProfile, listIndications, indication,
        id_exam_indication, handleInputChangeIndication, indicationn, listIndication, selectIndication,
        CreateOrUpdateExamIndications,
        handleGetValidReleaseExam, releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
        saveUpdateStatusComplete,
        //filter
        is_referencedf, is_simplef, individual_salef, is_criticf, is_completef,
        handleInputChangeFilter, handleFilters, id_sectionf, listSection, handleChangeSection,
        handleSelectValuesIndication, dataIndication,
        //IMAGENOLOGIA
        handleCreateExamImagenologia, titleImagenologia, methodImagenolofia, modalImagenologia, toggleImagenologia,
        //nuevo_campo,nuevo_campo2,
        sections2, number_projections, enable_analyte_configuration, handleUpdateImagenology,
        isImagenologyy, examEditIm,

        //*CONFIGURATONS LABORATORY EXTERNAL
        dataLaboratory,handleChangePriceIni
    };
}

import React, { useState } from 'react';
import { useForm } from '../forms/useForm';
import { useEffect } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { Code500 } from '../../constant/globalMessage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as XLSX from 'xlsx';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import radialimage from '../../assets/images/dashboard-2/radial-graph.png';
import ConfigDB from '../../data/customizer/config';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;
const danger = localStorage.getItem('danger_color') || ConfigDB.data.color.danger_color;



export const useReportWorkOrderInvoice = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [createSweet] = useSweetAlert();

    const [viewCompany, setViewCompany] = useState(true);
    const [reportCompany, setReportCompany] = useState(null);
    const [reportPatient, setReportPatient] = useState(null);
    const [reportGeneralPublic, setReportGeneralPublic] = useState(null);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();

    //*Filters
    const [filterPaid, setFilterPaid] = useState(false);
    const [filterInvoice, setFilterInvoice] = useState(false);
    const [filterCredit, setFilterCredit] = useState(false);
    const [filterEfectivo, setFilterEfectivo] = useState(false);
    const [filterTC, setFilterTC] = useState(false);
    const [filterTD, setFilterTD] = useState(false);
    const [filterTransferencia, setFilterTransferencia] = useState(false);
    const [dataHistoricP, setDataHistoricP] = useState([])

    const [loadingCredit, setLoadingCredit] = useState(false)
    const [branch, setBranch] = useState([]);
    const [loadingC, setLoadingC] = useState(false);
    const [loadingC2, setLoadingC2] = useState(false);

    //*CREDIT 
    const [creditTotal, setCreditTotal] = useState([]);
    const [amountTotal, setAmountTotal] = useState(0);
    const [amountHistorial, setAmountHistorial] = useState(0);
    const [filterPendingC, setFilterPendingC] = useState(0)

    //*TYPE BUTTONS
    const [isHovered, setIsHovered] = useState(false);


    const startDate = `${currentYear}-${currentMonth <= 9 ? '0' + currentMonth : currentMonth}-01`;

    const endDate = `${currentYear}-${currentMonth <= 9 ? '0' + currentMonth : currentMonth}-${currentDay <= 9 ? '0' + currentDay : currentDay}`;

    const [formQuery, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        start_date: startDate,
        end_date: endDate,
        id_branch: 0
    });

        const { start_date, end_date, id_branch
        } = formQuery;

        useEffect(() => {
            GetReportHistorial()
            getHistoric()
            getBranches()
        }, [])
        

        useEffect(async () => {
            await GetReportInvoice();
        }, []);


        const getBranches = async () => {
            let _listTemp = await _handleRequest("GET", "Branch/List", "Sucursal");
            if (_listTemp !== null && _listTemp.length > 0) {
                let _allOption = {
                    value: 0,
                    label: "TODAS LAS SUCURSALES"
                };
        
                let _newBranches = [_allOption]; 
        
                _listTemp.forEach((_obj) => {
                    _newBranches.push({
                        value: _obj.id_branch,
                        label: _obj.name
                    });
                });
        
                setBranch(_newBranches);
            }
        };

    
        const GetReportInvoice = async () => {
            setLoadingCredit(true)


            let method = `Reports/GetReportInvoices/${start_date}/${end_date}`;

            if (id_branch !== 0 && id_branch.value !== undefined) {
                method = method + `&id_branch=${id_branch.value}`;
            }

            const request = await _handleRequest("GET", method, "Reporte global");
        

            if (request !== null) {
                setLoadingCredit(false)
                setReportPatient(request.reportPatient);

                
            }
        }

        const handleChangeView = () => {
            setViewCompany(!viewCompany);
        }

        const RiskBarPatient = {
            series: [reportPatient?.total_invoices, reportPatient?.not_invoices],
            options: {
                chart: {
                    height: 280,
                    type: 'radialBar',
                    offsetY: -10,
                },

                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        inverseOrder: true,
                        hollow: {
                            margin: 5,
                            size: '60%',
                            image: radialimage,
                            imageWidth: 140,
                            imageHeight: 140,
                            imageClipped: false,
                        },
                        track: {
                            opacity: 0.4,
                            colors: secondary
                        },
                        dataLabels: {
                            show: true,
                            enabled: true,
                            value: {
                                show: true,
                                fontSize: '14px',
                                formatter: function (val) {
                                    return val + ' '
                                }
                            },
                            textAnchor: 'middle',
                            distributed: false,
                            offsetX: 0,
                            offsetY: 0,

                            style: {
                                fontSize: '14px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fill: ['#2b2b2b'],

                            },
                            total: {
                                show: true,
                                label: (reportPatient?.total_work_orders === 0 ? "Facturas pendientes" :  "Total De Admisiones"),
                                color: danger,
                        
                                formatter: function (w) {
                                    return (reportPatient?.total_invoices === 0 ? reportPatient?.not_invoices :  reportPatient?.total_work_orders )
                                }
                            }
                        },
                    }
                },

                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.15,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, reportPatient?.total_work_orders],
                        gradientToColors: ['#a927f9'],
                        type: 'horizontal'
                    },
                },
                stroke: {
                    dashArray: 15,
                    strokecolor: ['#ffffff']
                },

                labels: ['Facturadas', 'Pendientes'],
                colors: [secondary, primary,danger],
            }
        }

        //*MAKE EXCEL
        const MakeExcelParticular = ( outValidity = false, payment = false) => {
            let _report = [];

            reportPatient?.reportDetail?.length > 0 && (
                (filterPaid || filterInvoice
                    ? reportPatient.reportDetail
                        .filter(_filter => {
                            if (outValidity && _filter?.is_invoiced === true) {
                                return true;
                            }
                            if (payment && _filter?.is_invoiced === false) {
                                return true;
                            }
                            return false;
                        })
                    : reportPatient.reportDetail
                ).map((_order, _index) => (
                    _report.push({
                        "Nim": _order?.nim,
                        "Sucursal": _order?.name_branch,
                        "Nombre del Paciente": _order?.name_patient,
                        "Recepción": _order?.reception_date,
                        "Pagado": _order?.is_full_paid ? "Si" : "No",
                        "Facturado": _order?.is_invoiced ? "Si" : "Pendiente",
                        "Serie": _order.listInvoice.length === 0 ? "-----" : _order.listInvoice[0].invoiceSerie,
                        "Folio": _order.listInvoice.length === 0 ? "-----" : _order.listInvoice[0].folio,
                        "Total": _order?.total,
                        "Debe": _order?.credit,
                        "Metódo de Pago":
                        _order?.pay_methods?.length > 0 && (
                            (() => {
                                const lastPaymentMethod = _order.pay_methods[_order.pay_methods.length - 1];

                                switch (lastPaymentMethod) {
                                    case 1:
                                        return "Efectivo";
                                    case 2:
                                        return "Tarjeta de Crédito";
                                    case 4:
                                        return "Tarjeta de Débito";
                                    case 8:
                                        return "Transferencia";
                                    default:
                                        return "Crédito";
                                }
                            })()
                        )
                        
                

                    })
                ))
            )
            const worksheet = XLSX.utils.json_to_sheet(_report);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = 'indicadores_facturacion.xlsx';

            if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
                // Para Internet Explorer
                window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
            } else {
                // Para otros navegadores
                const url = window.URL.createObjectURL(dataBlob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }

        const GetReportHistorial = async (_findId) => {
            setLoadingC(true);

            let _valid = _findId === undefined ? 0 : _findId

            let _listTmp = await _handleRequest("GET", `WorkOrder/DetailCredit/${_valid}`, 'Historico');

            if (_listTmp !== null) {
                let _arrayCredit = [];
                let _amounTotal = 0;

                _listTmp.forEach(_find => {
                    _amounTotal += Math.round(parseFloat(_find.amount_deposit));

                    _arrayCredit.push({
                        amount_deposit: _find.amount_deposit,
                        branch: _find.branch,
                        fech: _find.fech,
                        name_patient: _find.name_patient,
                        nim: _find.nim,
                        userResponsable: _find.userResponsable
                    });
                });

                setCreditTotal(_arrayCredit);
                setAmountTotal(_amounTotal);
                setLoadingC(false);

            }
        };


        const handleMouseEnter = () => {
        setIsHovered(true);
        };

        const handleMouseLeave = () => {
        setIsHovered(false);
        };


         //*DETAIL HISTORIC CREDIT
        const getHistoric = async (_findId) => {

            setLoadingC2(true);
            let _valid = _findId === undefined ? 0 : _findId

            let _listTmp = await _handleRequest("GET", `WorkOrder/DetailHistoricCredit/${start_date}/${end_date}/${_valid}`, 'Historico');

            if (_listTmp !== null) {
                setLoadingC2(false);

                let _amounTotal = 0;
                let _amounTotal2 = 0;

                _listTmp.forEach(_find => {
                    if (_find.fech_recupered !== "") {
                        _amounTotal += Math.round(parseFloat(_find.amount_deposit));
    
                    }
                    if (_find.fech_recupered === "" && _find.fully_paid === false) {
                        _amounTotal2 += Math.round(parseFloat(_find.amount_deposit));
    
                    }   
                });
                setDataHistoricP(_listTmp)
                setAmountHistorial(_amounTotal)
                setFilterPendingC(_amounTotal2)
            }    
        }

        const handleSelectChange = (_findId) => {
            GetReportHistorial(_findId);
        };

        const handleSelectChange2 = (_findId) => {
            getHistoric(_findId);
        };

        const handleReport = () => {
            getHistoric();
            GetReportInvoice();
        }

        //*MAKE EXCEL
        const MakeExcelDetailCredit = ( _arrayCredit) => {
            let _report = [];

            _arrayCredit?.length > 0 && _arrayCredit.map((_find) => {
                _report.push({
                    "Solicitud(Nim)": _find.nim ?? "-----",
                    "Nombre del Paciente" : _find.name_patient ?? "-----",
                    "Sucursal":_find.branch ?? "-----",
                    "Reponsable":_find.userResponsable ?? "-----",
                    "Fecha y Hora": _find.fech ?? "-----",
                    "Monto": _find.amount_deposit ?? "-----",
                    "Estatus":"Pendiente"
                })
            })
            
            const worksheet = XLSX.utils.json_to_sheet(_report);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = 'Pendientes de Saldos.xlsx';

            if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
                // Para Internet Explorer
                window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
            } else {
                // Para otros navegadores
                const url = window.URL.createObjectURL(dataBlob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }

        // * FORMAT NUMBERS
        const number_format = (cantidad, decimals) => {
            cantidad += '';
            cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));
    
            decimals = decimals || 0;
    
            if (isNaN(cantidad) || cantidad === 0) {
                return parseFloat(0).toFixed(decimals);
            }
    
            cantidad = '' + cantidad.toFixed(decimals);
    
            let cantidad_parts = cantidad.split('.'),
                regexp = /(\d+)(\d{3})/;
    
            while (regexp.test(cantidad_parts[0]))
                cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');
    
            return cantidad_parts.join('.');
        }
    
        const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
            let requestOptions = {
                method: method
            };

            if (body !== null) {
                requestOptions = {
                    ...requestOptions,
                    body: JSON.stringify(body)
                };
            }

            const response = await sendRequest(requestOptions, methodUrl);

            if (response.code === 200) {
                return response.data;
            }
            else if (response.code === 401) {
                validarSesion(history, response.code, _handleRequest);
            }
            else if (response.code === 500) {
                createSweet("error", "error", nameMethod, Code500);
            }
            else {
                createSweet("warning", "warning", nameMethod, response?.data?.msg);
            }

            return null;
        }


    return {

        //*FILTER SEARCH
        loading,start_date, end_date, id_branch,viewCompany,handleInputChange, handleChangeView,

        //*VIEW CHARTS AND REPORTS
        reportCompany, reportPatient, reportGeneralPublic,GetReportInvoice,MakeExcelParticular,RiskBarPatient,

        //*Filters
        filterPaid,setFilterPaid,
        filterInvoice,setFilterInvoice,
        filterCredit,setFilterCredit,
        filterEfectivo,setFilterEfectivo,
        filterTC,setFilterTC,
        filterTD,setFilterTD,
        filterTransferencia, setFilterTransferencia,

        //*TOTAL
        creditTotal,number_format,amountTotal,

        //*TYPE BUTTONS
        isHovered,handleMouseEnter,handleMouseLeave,

        //*TYPE REPORT CREDIT
        dataHistoricP,handleReport,amountHistorial,filterPendingC, setFilterPendingC,loadingCredit,branch,handleSelectChange,loadingC,handleSelectChange2,loadingC2,
        
        //*EXCEL DETAIL CREDIT
        MakeExcelDetailCredit
    }

}

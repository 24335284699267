import React from 'react';
import { Modal, ModalBody, Col, Row, Button, ModalFooter,Table } from 'reactstrap';


export const ModalPreviewCredt = (props) => {

    const { toggleCredit, modalCredit, typeModalCredit = "ViewCredit",dataCredit } = props;

    return (
        <Modal isOpen={modalCredit} toggle={toggleCredit} backdrop='static' keyboard={false} centered={true} size='xl' scrollable={true} >
            <ModalBody className="border-3 b-primary "  >
                {
                    typeModalCredit === "ViewCredit" ?
                        <div>
                            <b className='txt-secondary f-16'>Pacientes Pendientes de cobro</b>
                            <hr />
                            <div className="table-responsive" style={{ height: '350px', overflowY: "scroll", scrollBehavior: "smooth" }}>
                        <Table size='sm' className="">
                            <thead className="theadInvoice">
                                <tr className='text-center'>
                                    <th className='labelThead' >Solicitud(NIM)</th>
                                    <th className='labelThead' >Paciente</th>
                                    <th className='labelThead' >Fecha</th>
                                    <th className='labelThead' >Monto</th>
                                    <th className='labelThead'>Estatus del Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataCredit !== undefined && dataCredit.length > 0 && dataCredit.map((_find, _key) => {
                                        return (
                                            <tr key={_key}>
                                                <td style={{ textAlign: "center" }} >{_find.nim}</td>
                                                <td style={{ textAlign: "center" }}>{_find.name_patient}</td>
                                                <td style={{ textAlign: "center" }}>{_find.fech}</td>
                                                <td style={{ textAlign: "center" }}>{_find.amount_deposit}</td>
                                                <td style={{ textAlign: "center" }}> <label className='f-w-600 f-12 badge badge-light-danger' >Pendiente</label> </td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                          
                        </div>
                        :
                        ""
                }
            </ModalBody>
            {
                <ModalFooter className="border-1 b-primary ">
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleCredit}>Cerrar</Button>
                </ModalFooter>
            }
        </Modal>
    )
}

import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Table ,Badge ,ButtonGroup} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import { useReportWorkOrderInvoice } from '../../hooks/reports/useReportWorkOrderInvoice'

export const IndexInvoiceReport = () => {

    const {

         //*FILTER SEARCH
         start_date, end_date,handleInputChange, 

         //*VIEW CHARTS AND REPORTS
         reportPatient,MakeExcelParticular,RiskBarPatient,
 
         //*Filters
         filterPaid,setFilterPaid,
         filterInvoice,setFilterInvoice,
         filterCredit,setFilterCredit,
         filterEfectivo,setFilterEfectivo,
         filterTC,setFilterTC,
         filterTD,setFilterTD,
         filterTransferencia, setFilterTransferencia,
 
         //*TOTAL
         creditTotal,number_format,amountTotal,
 
         //*TYPE BUTTONS
         isHovered,handleMouseEnter,handleMouseLeave,
 
         //*TYPE REPORT CREDIT
         dataHistoricP,handleReport,amountHistorial,filterPendingC,loadingCredit,branch,handleSelectChange,loadingC,handleSelectChange2,loadingC2,
         
         //*EXCEL DETAIL CREDIT
         MakeExcelDetailCredit


    } = useReportWorkOrderInvoice();

    const totalRow = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td></td>
        <td></td>
        <td className='labeltheadInvoice'><label className='f-12' >Total Pendiente:</label> </td>
        <td  className='labeltheadInvoice'><label className='f-12' > ${number_format(amountTotal, 2)}</label> </td>
        <td ></td>
       
    </tr> 
);

const totalRow2 = (
    <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '18px' }} className='sticky-footer' >
    <td ></td>
    <td></td>
    <td style={{textAlign :"end"}} className='labeltheadInvoice'><label className='' >Total Recuperado:</label> </td>
    <td  style={{textAlign :"center"}} className='labeltheadInvoice'><label className='' > ${number_format(amountHistorial, 2)}</label> </td>
    <td style={{textAlign :"end"}} className='labeltheadInvoice'><label className='' >Total Pendiente:</label> </td>
    <td  className='labeltheadInvoice'><label className='' > ${number_format(filterPendingC, 2)}</label> </td>
    <td></td>
    <td></td>

</tr> 
);


                                 
console.log("branch",branch);


const divStyle = {
    backgroundColor: isHovered ? "#1e90ff" : "#46afe5",
    borderColor: "#46afe5",
    color: "#fff",
    textAlign: "center",
    cursor: "pointer",
    padding: "8px 16px",  
  };


    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0 mb-5'>
                <Row>
                    <Col sm="4">
                    <Card className='border-1'>
                            <CardHeader className='p-2 text-center bg-warning'>
                                <Col style={{padding : "10px"}}>
                                <h5 className='card-title f-14' style={{textAlign:"justify"}}>   <i className='fa fa-filter fIconFilter f-18' ></i> Filtros de busqueda</h5>
                                </Col>
                            </CardHeader>
                            {
                                //!CARDS
                            }
                            <Col style={{padding:"20px"}}>
                            <Row>
                                <Col sm="4" className=''>
                                <Card className='shadow pt-2' style={{ height: "60px" }}>
                                <Col sm="12">
                                <Row>
                                    <Col sm="3" className='pt-2'>
                                    <div className='card-validateFinance-TE'>
                                    <b className='f-12'>T.P</b>
                                    </div>
                                    </Col>
                                    <Col sm="9"  style={{textAlign : "center"}}>
                                    <label  className='f-w-600 pt-2 f-14'>{reportPatient?.total_work_orders}</label> 
                                    </Col>
                                </Row>
                                </Col>
                               </Card>
                                </Col>
                                <Col sm="4" className=''>
                                <Card className='shadow pt-2' style={{ height: "60px" }}>
                                <Col sm="12">
                                <Row>
                                    <Col sm="3" className='pt-2'>
                                    <div className='card-validateFinance-TC'>
                                    <b className='f-12'>T.F</b>
                                    </div>
                                    </Col>
                                    <Col sm="9"  style={{textAlign : "center"}}>
                                    <label  className='f-w-600 pt-2 f-14'> {reportPatient?.total_invoices}</label>
                                    </Col>
                                </Row>
                                </Col>
                            </Card>
                                </Col>
                                <Col sm="4" className=''>
                                <Card className='shadow pt-2' style={{ height: "60px" }}>
                                <Col sm="12">
                                <Row>
                                    <Col sm="3" className='pt-2'>
                                    <div className='card-validateFinance-TD'>
                                    <b className='f-12'>S/F</b>
                                    </div>
                                    </Col>
                                    <Col sm="9" style={{textAlign : "center"}}>
                                    <b className='f-w-600 pt-2 f-14' >{reportPatient?.not_invoices}</b>
                                    </Col>
                                </Row>
                                </Col>
                            </Card>
                                </Col>
                            </Row>
                            {
                                loadingCredit ? 
                                <>
                                    <Row className='mt-2'>
                                        <Col sm="12" md="12" lg="12" className='text-center'>
                                            <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '100px' }} />
                                        </Col>
                                        </Row>
                                        <Col sm="12" className='mt-2'  style={{textAlign:"center"}} >
                                           <label className='f-14 txt-secondary'>Cargando</label>
                                        </Col>
                                    </>
                                :
                                <>
                                     <ReactApexChart id="riskfactorchart" options={RiskBarPatient.options} series={RiskBarPatient.series} type='radialBar' height={280} />
                                   <div className="goal-end-point text-center">
                                        <h6 className="typeParticular">{"Pacientes Particulares"}</h6>                                                
                                    </div>
                                </>
                            }
                                <label className=''>Fecha Inicio:</label>
                               <Input disabled={loadingCredit ? true : false}  type='date' size={"sm"} className='round b-r-5 border-1' name='start_date' value={start_date} onChange={(e) => handleInputChange(e)}>
                                        <option value="">Selecciona una sucursal</option>
                                </Input>
                                    <br />
                                    <label className=''>Fechas Final:</label>
                                    <Input disabled={loadingCredit ? true : false} type='date' size={"sm"} className='round b-r-5 border-1' name='end_date' value={end_date} onChange={(e) => handleInputChange(e)}>
                                        <option value="">Selecciona una sucursal</option>
                                    </Input> <br /> <br />
                                    <div className={loadingCredit ? "d-none" : ' p-2 pointer'} onClick={() => handleReport()}   
                                    style={divStyle}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                        Busqueda
                                    </div>
                                   <br />
                            </Col>
                        </Card>
                    </Col>
                    {
                        //!TABLE CREDIT PENDING
                    }
                    <Col sm="8"  >
                    <Card className='shadow'>
                        <Col style={{padding : "20px"}}>
                        <Row>
                            <Col sm="6">
                            <b className='f-14'>Saldos Pendientes</b>
                            </Col>
                            <Col sm="2">
                            </Col>
                            <Col sm="3" className='p-r-0' >
                            <Input
                            style={{borderColor: "#000",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "230px",fontSize:"11px"}}
                            type='select'
                            name="id_tax_regime_business_name"
                            onChange={(e) => handleSelectChange(e.target.value)}
                        >
                            <option value={0}>Selecciona una sucursal</option>
                            {branch.length > 0 && branch.map(tax => (
                                <option key={`taxRegime-${tax.value}`} value={tax.value}>{tax.label}</option>
                            ))}
                        </Input> <br />
                            </Col>
                            <Col sm="1" className='p-l-0' style={{textAlign :"center"}}>
                            <Badge color='success'> <i className='icofont icofont-file-excel f-20 pointer' onClick={() => { MakeExcelDetailCredit(creditTotal) }}  ></i> </Badge>

                            </Col>
                        </Row>
                                <div className='hr-detail-patient' >
                                </div> <br /> 
                                <div className="col-12">
                        <Card>
                           
                          
                        </Card>
                    </div>
                            <div  className= 'table-wrapper-saldos'>
                        <Table className='b-r-5 table table-xs'>
                          <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }}>
                                  <th style={{textAlign:"center"}} >Solicitud(NIM)</th>
                                  <th style={{textAlign:"center"}}>Sucursal</th>
                                  <th style={{textAlign:"center"}}>Responsable</th>
                                  <th style={{textAlign:"centtable-wrapper-saldoser"}}>Fecha</th>
                                  <th style={{textAlign:"center"}}>Monto</th>
                                  <th style={{textAlign:"center"}}>Estatus</th>
                              </tr>
                          </thead>
                          <tbody>
                                {
                                    loadingC && <tr>
                                        <td colSpan={9} className='bg-info text-center f-16'>
                                            <i className="fa fa-spin fa-spinner"></i> Cargando...
                                        </td>
                                    </tr>
                                }
                              {!loadingC &&creditTotal?.map((_find, _key) => {
                                
                                  return (
                                      <tr key={_key}>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}> <b className='txt-warning'>{_find?.nim ?? "---"}</b> </td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{_find?.branch ?? "----"}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{_find?.userResponsable ?? "----"}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{_find?.fech ?? "----"}</td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}> <b className='txt-danger' >${number_format(_find?.amount_deposit ?? 0, 2)}</b>  </td>  
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}> <label className={`f-w-600 badge badge-danger`}>Pendiente</label> </td>                              
                                      </tr>
                                  );
                              })}
                              {totalRow}
                          </tbody>
                      </Table>
                            </div>
                            </Col> 
                        </Card>
                    </Col>
                </Row>
                {
                    //!DETAIL PAYMENT METHODS
                }
                <Row className={loadingCredit ? "d-none" : "m-b-20"} >
                    <Col sm="12">
                       <Card style={ !filterCredit ? { height: '440px', overflowY: "scroll", scrollBehavior: "smooth" } : {}}>
                        <CardBody>
                            <div className='text-right'>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterEfectivo ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterEfectivo(!filterEfectivo) }} > Efectivo </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterCredit ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterCredit(!filterCredit) }} > Saldos</Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTC ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTC(!filterTC) }} > Tarjeta de Crédito </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTD ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTD(!filterTD) }} > Tarjeta de Débito </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTransferencia ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTransferencia(!filterTransferencia) }} > Transeferencia </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterPaid ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPaid(!filterPaid) }}>Facturado </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterInvoice ? "" : "-light"}-danger`} style={{ cursor: "pointer" }} onClick={() => { setFilterInvoice(!filterInvoice) }} > Pendiente en Facturar </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge-success`} style={{ cursor: "pointer" }} onClick={() => { MakeExcelParticular(filterPaid,filterInvoice) }} >Descargar excel</Label>
                            </div>
                            <div  className={filterCredit ? "d-none" : 'table-responsive' } >
                                <Table className=' m-b-5'>
                                    <thead className="thead-Particular"> 
                                        <tr style={{ backgroundColor: "#033d5b" }} >
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>NIM</th>
                                            <th className='labeltheadInvoice'>Paciente</th>
                                            <th className='labeltheadInvoice'>Sucursal</th>
                                            <th className='labeltheadInvoice'>Recepción</th>
                                            <th className='labeltheadInvoice'>¿Pagada?</th>
                                            <th className='labeltheadInvoice'>¿Facturada?</th>
                                            <th className='labeltheadInvoice'>Total</th>
                                            <th className='labeltheadInvoice'>Debe</th>
                                            <th className='labeltheadInvoice'>Método de Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportPatient?.reportDetail?.length > 0 && (
                                            (filterPaid || filterInvoice  || filterEfectivo || filterTC || filterTD || filterTransferencia
                                            ? reportPatient.reportDetail.filter(_filter => {
                                                const { is_invoiced, pay_methods} = _filter;
                                                const lastPaymentMethod = pay_methods[pay_methods.length - 1];

                                                return (
                                                    (filterPaid && is_invoiced === true) ||
                                                    (filterInvoice && is_invoiced === false) ||
                                                    (filterEfectivo && lastPaymentMethod === 1) ||
                                                    (filterTC && lastPaymentMethod === 2) ||
                                                    (filterTD && lastPaymentMethod === 4) ||
                                                    (filterTransferencia && lastPaymentMethod === 8)
                                                );
                                                })
                                            : reportPatient.reportDetail
                                            ).map((_order, _index) => (
                                            <tr key={_order?.id_work_order}  className={_order.cancell === true ? "colorTable" : "" }    >
                                                <td>{_index + 1}</td>
                                                <td>{_order?.nim}</td>
                                                <td>{_order?.name_patient}</td>
                                                <td>{_order?.name_branch}</td>
                                                <td style={{textAlign: "center"}}>
                                                <Button style={{ pointerEvents: "none" }} color='info' outline className={`btn btn-pill btn-xs`}>{_order?.reception_date}</Button> <br />
                                                <label className='txt-primary ' style={{ fontSize: "10px" }} > {_order?.dateFech}</label>
                                                </td>
                                                <td>
                                                <Label type="text" className={`f-w-400 f-12 badge badge-${_order?.is_full_paid === true ? 'success' : 'danger'}`}>
                                                    {_order?.is_full_paid ? "Si" : "No"}
                                                </Label>
                                                </td>
                                                <td>
                                                <Label
                                                    type="text"
                                                    className={`f-w-400 f-12 badge badge-${_order?.is_invoiced === true ? 'secondary' : 'danger'}`}
                                                >
                                                    {_order?.is_invoiced ? "Facturado" : "Pendiente"}
                                                </Label>
                                                </td>
                                                <td className={_order.is_invoiced == true ? 'f-w-700' : 'font-danger f-w-700'}>${_order?.total}</td>
                                                <td>${_order?.credit}</td>
                                                <td>
                                                <label className='f-w-600 f-12 badge badge-light-secondary'>
                                                    {_order?.pay_methods?.length > 0 && (
                                                    (() => {
                                                        const lastPaymentMethod = _order.pay_methods[_order.pay_methods.length - 1];

                                                        switch (lastPaymentMethod) {
                                                        case 1:
                                                            return "Efectivo";
                                                        case 2:
                                                            return "T.C";
                                                        case 4:
                                                            return "T.D";
                                                        case 8:
                                                            return "Transferencia";
                                                        case 12:
                                                            return "Mercado Pago";
                                                        case 30:
                                                             return "Cupón";
                                                        case 31:
                                                            return "Cupón";
                                                        default:
                                                            return "Crédito";
                                                        }
                                                    })()
                                                    )}
                                                </label>
                                                </td>
                                            </tr>
                                            ))
                                        )}
                                 </tbody>
                                </Table>
                            </div>

                            <Col className={!filterCredit ? "d-none" : 'table-responsive' } style={{padding : "20px"}}>
                           <br />
                           <Row>
                            <Col sm="6">
                            <b className='f-14'>Historial de Saldos</b>

                            </Col>
                            <Col sm="4">
                            </Col>
                            <Col sm="2" >
                            <Input style={{borderColor: "#000",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px", fontSize:"11px"}}
                            type='select'
                            name="id_tax_regime_business_name"
                            onChange={(e) => handleSelectChange2(e.target.value)}
                             >
                            <option value={0}>Selecciona una sucursal</option>
                            {branch.length > 0 && branch.map(_find => (
                                <option key={`branchI-${_find.value}`} value={_find.value}>{_find.label}</option>
                            ))}
                        </Input> <br />
                            </Col>
                        </Row>
                           <div className='hr-detail-patient' >
                           </div> <br /> 
                       <div className='table-wrapper'>
                   <Table className='b-r-5 table table-sm'>
                     <thead className='b-r-5'>
                         <tr style={{ backgroundColor: "#033d5b" }}>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}} >Solicitud(NIM)</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}} >Sucursal</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}} >Usuario Responsable</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}}>Fecha de Ingreso</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}}>Monto</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}} >Usuario de Recuperación</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}}>Fecha de Recuperación</th>
                             <th className='labeltheadInvoice' style={{textAlign:"center"}}>Estatus</th>
                         </tr>
                     </thead>
                     <tbody>
                        {
                            loadingC2 && <tr>
                            <td colSpan={9} className='bg-info text-center f-16'>
                                <i className="fa fa-spin fa-spinner"></i> Cargando...
                            </td>
                            </tr>
                        }
                         {!loadingC2 && dataHistoricP?.map((_find, _key) => {
                             return (
                                 <tr key={_key}>
                                   <td style={{ textAlign: "center" }} ><b style={{fontSize: "12px"}} className='txt-warning' >{_find?.nim ?? "---"}</b> </td>
                                   <td style={{ textAlign: "center" }}>{_find?.branch ?? "----"}</td>
                                   <td style={{ textAlign: "center" }}>{_find?.userResponsable ?? "----"}</td>
                                   <td style={{ textAlign: "center" }}><label className='f-w-600 txt-danger'>{_find?.fech ?? "----"}</label> </td>
                                   <td style={{ textAlign: "center" }}> <b className={_find?.fully_paid ? 'f-12 txt-success' : 'f-12 txt-danger' } >${number_format(_find?.amount_deposit ?? 0, 2)}</b>  </td>      
                                   <td style={{ textAlign: "center" }}>{_find?.name_recupered ?? "----"}</td>
                                   <td style={{ textAlign: "center" }}><label style={{fontSize :"10px"}} className={_find?.fully_paid ? 'f-w-600 txt-success' : 'f-w-600 txt-danger' } >{ _find?.fech_recupered === "" ? "---------": _find?.fech_recupered ?? "----"}</label> </td>
                                   <td style={{ textAlign: "center" }} ><label style={{fontSize :"10px"}}  className={ _find?.fully_paid ? `f-w-600 badge badge-success` : `f-w-600 badge badge-danger` } >{_find.fech_recupered !== ""  ? "Pagado" : "Pendiente" }</label>  </td>

                                 </tr>
                             );
                         })}
                         {totalRow2}
                     </tbody>
                 </Table>

                   </div>
                             </Col>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

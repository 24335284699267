import React, { useState } from "react";
import {
    Button, Card, CardBody, CardHeader, Col, Collapse, Container, Row, FormGroup, Label, FormFeedback, Spinner, TabContent, TabPane, Nav, NavItem, NavLink,
} from "reactstrap";
import { TableStriped } from "../../../components/tables/TableStriped";
import { ModalAnalytes } from "../../../components/analytes/ModalAnalytes";
import { FormAnalytes } from "../../../components/analytes/FormAnalytes";
import { ModalTestFormula } from "../../../components/analytes/ModalTestFormula";
import { ModalRange } from "../../../components/analytes/ModalRange";
import { ModalOrderAnalytes } from "../../../components/analytes/ModalOrderAnalytes";
import { Modal } from "react-morphing-modal";
import { ModalComplex } from "../../../components/analytes/ModalComplex";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { ModalOrderPrintAnalytes } from "../../../components/analytes/FormsTypeAnalytes/ModalOrderPrintAnalytes";
import Breadcrumb from "../../../layout/breadcrumb";
import { useCreateExamSimple } from "../../../hooks/exams/useCreateExamSimple";
import { CreateExam } from "../../../components/exams/CreateExam";
import { useExam } from "../../../hooks/exams/useExam";
import Select from "react-select";
import { AddMethodExam } from "./AddMethodExam";
import { ButtonGoBack } from "../../../constant";
import { IndexCosts } from "./IndexCosts";
import { useDetectedActivity } from "../../../hooks/exams/useDetectedActivity";
import './StyleExam.css';
import { useHistory } from 'react-router-dom';
import { GlobalReferenceValue } from "./GlobalReferenceValue";




export const CreateExamSimple = () => {
    const {
        examEditId, formValues, alias, method: methodExam, handleInputChange: handleInputChangeExam, validationSectionArea,
        handleSelectValues, validaciones: validacionesExam, sections, specimenOrigins, subSections, commercialLines, specimens,
        specimenProvenances, typeTransports, transports, loading: loadingExam, examId, getUpdateDefaultExternalLaboratory, changeSelect, handleChangePrice, cost,
        saveCreate: saveCreateExam, saveUpdate: saveUpdateExam, appCodeMethodId, changeMethodDefault, dataAppCodeMethod, saveAppCodeMethodFromExam, updateAppCodeMethodExam, tabGeneral, changeTabGeneral, goToBack,
        //*CONFIGURATION LABORATORY EXTERNAL
        dataLaboratory,handleChangePriceIni
    } = useExam(true);

   console.log(dataAppCodeMethod);
    // //console.log(examEditId);
    const {
        analytes, loadingAnalytes, exam, analytesGroup, analytesGroupPrint, groupAnalytes, typeAnalytes, typePrecodedTexts, units, unitsTo, isOpen, toggle,
        loading, modal, toggleModal, nameAnalyte, loadingModal, handleInputChange, validaciones, methodActive, validateFormula, name, app_code, code,
        id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print, required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to,
        compute, check_compute, replace, id_exam, loinc_num, addAnalyte, removeAnalyte, saveAnalytes, deleteAnalyte,
        handleEdit, editAnalyte, backCreate, changeUnit, validarFormula, resetValidarFormula, toggleTest, modalTest, loadingTest,
        testearFormula, init_range, end_range, value, increment, handleInputTest, resetTest, results, resetResults, handleModalTest, handleFullModal, modalProps, modalFullRef,
        gender, init_value, end_value, years_from, months_from, days_from, years_to, months_to, days_to, text_range, validacionesRange,
        method, methods, handleInputRange, resetRange, precodedTexts, listRanges, addAnalyteNumberRange, removeAnalyteNumberRange,
        saveTempAnalytesNumberRanges, editAnalyteNumberRange, id_analyte_age_gender_range, handleInputRangePrecoded, listRangesPrecoded, addRangePrecoded, saveTempRangePrecoded, removeRangePrecoded,
        editRangePrecoded, saveEditRangePrecoded, methodActiveRangePrecoded, backToCreateRangePrecoded, saveEditAnalyteRangeNumber, methodActiveRange, backToCreateRange, loincState,
        handleModalOrder, modalOrder, loadingOrder, saveOrderAnalytes, handleModalOrderPrint, modalOrderPrint, loadingOrderPrint, saveOrderPrintAnalytes,
        activeModal, analytesAvailables, refreshFormComplex, responseComplexText, refreshResultComplex, preSavedFormComplex, analytesComplex,
        exams, saveTempAnalytesNumberAlerts, listReferenceValues, handleValueBold, handleValueItalics, handleValueUnderline,
        handleValueFormatTransform, valueBold, valueItalics, valueUnderline, valueFormatTransform, selectedInfoAnalytes, infoAnalytes, handleValueInfoAnalyte,
        saveTempAnalytesTextAlerts, listReferenceTexts,
        onChange, data, handleChangeTextP, selected,
    } = useCreateExamSimple(
        parseInt(examId),
        dataAppCodeMethod,
        methodExam,
        changeMethodDefault,
        changeTabGeneral
    );

    const { innerHeight: height } = window;

    useDetectedActivity(60000, 10000);
    const history = useHistory();

    console.log(infoAnalytes);



    console.log(selectedInfoAnalytes);


    return (
        <div>
            {/* <Breadcrumb parent="Diseño de examen" title={exam.name} /> */}
            <Container className="mt--7" fluid>
                <Row>
                    <CardBody>
                        <Row>
                            <Col sm="3" className="tabs-responsive-side">
                                {/* <CardHeader className="">
                                    <label className='f-20 txt-primary'>{exam.name}</label> <br />
                                    <Button color="light" className="float-right mr-1 ml-1" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
                                    <br /> <br /><br />
                                    <h6 className="mb-0">
                                        {methodExam === "update" ? "Edición" : "Creación"} de examen
                                    </h6>
                                </CardHeader> */}
                                <Col sm="12"> <br />
                                    {/* <label className='labelExamNameSimple txt-secondary'>Exámen: {exam.name}</label> <br /> */}
                                </Col>
                                {/* <br />  */}
                                <Card className="shadow">
                                    <CardHeader className="p-2 p-b-0  bg-primary ">
                                        <label className="labelUpdateEdit">{methodExam === "update" ? "Edición" : "Creación"} de examen</label> <br />
                                        <label className='labelExamNameSimple txt-secondary'>{exam.name}</label> <br />

                                    </CardHeader>
                                    <Nav className="flex-column nav-pills border-tab nav-left">
                                        <NavItem>
                                            <NavLink
                                                href="#javascript"
                                                className={tabGeneral === "1" ? "active p-b-0" : ""}
                                                onClick={() => changeTabGeneral("1")}
                                            >
                                                <i className="icofont icofont-ui-edit"></i>
                                                <label className="labelMenuItem">Generales</label>
                                                {/* {`Generales`} */}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#javascript"
                                                className={`${examId > 0 ? "" : "disabled"} ${tabGeneral === "2" ? "active p-b-0" : ""
                                                    }`}
                                                onClick={() => changeTabGeneral("2")}
                                            >
                                                <i className="icofont icofont-listine-dots"></i>
                                                <label className="labelMenuItem">Métodos</label>
                                                {/* {`Métodos`} */}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#javascript"
                                                className={`${dataAppCodeMethod !== null ||
                                                    exam.id_app_code_method_default > 0
                                                    ? ""
                                                    : "disabled"
                                                    } ${tabGeneral === "3" ? "active p-b-0" : ""}`}
                                                onClick={() => changeTabGeneral("3")}
                                            >
                                                <i className="icofont icofont-gears"></i>
                                                <label className="labelMenuItem">Configuración</label>
                                                {/* {`Configuración`} */}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#javascript"
                                                className={`${examId > 0 ? "" : "disabled"}  ${tabGeneral === "4" ? "active p-b-0" : ""}`}
                                                onClick={() => changeTabGeneral("4")}
                                            >
                                                <i className="icofont icofont-cur-dollar"></i>
                                                <label className="labelMenuItem">Costos</label>
                                                {/* {`Costos`} */}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#javascript"
                                                className={`${examId > 0 ? "" : "disabled"}  ${tabGeneral === "5" ? "active p-b-0" : ""}`}
                                                onClick={() => changeTabGeneral("5")}
                                            >
                                                <i className="icofont icofont-cur-dollar"></i>
                                                <label className="labelMenuItem">Valor de referencia global</label>
                                                {/* {`Costos`} */}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                        <NavLink
                                                href="#javascript"
                                                //className="active p-b-0"
                                                onClick={() => history.goBack()}
                                            >
                                                <i className="icofont icofont-arrow-left"></i>
                                                <label className="labelMenuItem">Regresar</label>
                                                {/* {`Costos`} */}
                                            </NavLink>
                                        {/* <Button color="light" className="float-right mr-1 ml-1 " onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> <label className="labelgoBack">Regresar</label> </Button> */}

                                        </NavItem>
                                    </Nav>

                                </Card>  <br />

                                {/* <Card className="shadow">
                                    <CardHeader className="p-3 bg-primary ">
                                        <label className="labelCreateExa">Acciones</label>
                                    </CardHeader>
                                    <br />
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="0" xl="1"></Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="8">
                                                <Button color="light" className="float-right mr-1 ml-1 " onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> <label className="labelgoBack">Regresar</label> </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <br />


                                </Card> */}

                            </Col> <br />
                            <Col sm="9">
                                <TabContent activeTab={tabGeneral}>
                                    <TabPane className="fade show" tabId="1">
                                        <Row className="pt-1">
                                            <Col sm="12">
                                                <Card className="shadow">
                                                    <CardHeader className="p-3 bg-secondary ">
                                                        <label className="labelCreateGeneral"><i className="fa fa-list-alt"></i>&nbsp;Información General</label>
                                                    </CardHeader>
                                                    <CardBody className="p-3">
                                                        <Row>
                                                            <div className="col-12">
                                                                <FormGroup className={`mb-3 col-12`}>
                                                                    <Label className="labelInfoAnalyte">
                                                                        Busqueda del analito
                                                                    </Label>
                                                                    <Select
                                                                        // className="form-control form-control-plaintext form-control-sm"
                                                                        classNamePrefix="select"
                                                                        options={infoAnalytes}
                                                                        name="id_info_analyte"
                                                                        isClearable={false}
                                                                        value={selectedInfoAnalytes}
                                                                        placeholder={`Busqueda de analito`}
                                                                        onChange={(e) =>
                                                                            handleValueInfoAnalyte(e)
                                                                        }
                                                                    />
                                                                    <FormFeedback>
                                                                        El nombre debe tener más de 3 caracteres{" "}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                                <CreateExam
                                                                    examEditId={examEditId}
                                                                    isSimple={true}
                                                                    infoEdit={exam}
                                                                    methodAction={
                                                                        examEditId === undefined
                                                                            ? saveCreateExam
                                                                            : saveUpdateExam
                                                                    }
                                                                    {...{
                                                                        formValues,
                                                                        methodExam, validationSectionArea,
                                                                        alias, examId, toggle, handleInputChange: handleInputChangeExam, handleSelectValues,
                                                                        handleChangePrice, validaciones: validacionesExam, loading: loadingExam, sections, changeSelect,
                                                                        specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports, cost,
                                                                        //*CONFIGURATION LABORATORY EXTERNAL
                                                                         dataLaboratory,handleChangePriceIni
                                                                        // methodAction: saveCreateExam
                                                                    }}
                                                                />
                                                            </div>

                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {examId > 0 && (
                                            <Row>
                                                <Col sm="12">
                                                    <Card className="shadow">
                                                        <CardHeader className="p-3 bg-secondary ">
                                                            <label className="labelCreateGeneral"><i className="fa fa-gear"></i>&nbsp;Agregación de método</label>
                                                        </CardHeader>
                                                        <AddMethodExam
                                                            idAppCodeMethodDefault={
                                                                exam.id_app_code_method_default
                                                            }
                                                            methodAction={
                                                                examEditId === undefined
                                                                    ? saveAppCodeMethodFromExam
                                                                    : updateAppCodeMethodExam
                                                            }
                                                            {...{
                                                                loading: loadingExam,
                                                                id_info_analyte,
                                                                appCodeMethodId,
                                                                dataAppCodeMethod,
                                                                changeMethodDefault,
                                                            }}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {(dataAppCodeMethod !== null ||
                                            exam.id_app_code_method_default > 0) && (
                                                <Row className="pt-1 position-relative">
                                                    <Col sm="12">
                                                        <Card className="shadow">
                                                            <CardHeader className="p-3 bg-secondary">
                                                                {methodActive === "create" ? (
                                                                    <label className="labelCreateGeneral"><i className="fa fa-edit"></i>&nbsp;Configuración de analito</label>
                                                                ) : (
                                                                    <>
                                                                        {/* <a style={{ cursor: "pointer" }} className="link" onClick={backCreate}>Regresar a creación</a>
                                                    <br /> */}
                                                                        <label className="labelCreateGeneral"><i className="fa fa-edit"></i>&nbsp;Edición de analito</label>
                                                                    </>
                                                                )}
                                                            </CardHeader>
                                                            <FormAnalytes
                                                                isSimple={true}
                                                                {...{
                                                                    analytes, methodActive, addAnalyte, editAnalyte, changeUnit, validarFormula, resetValidarFormula,
                                                                    name, app_code, code, id_analyte_group, name_analyte_group, id_type_analyte, id_info_analyte, print,
                                                                    required, id_type_precoded_text, integers, decimals, id_unit, id_unit_to, compute, replace,
                                                                    check_compute, id_exam, loinc_num, groupAnalytes, typeAnalytes, typePrecodedTexts, units, unitsTo, handleInputChange, validaciones,
                                                                    validateFormula, handleModalTest, handleFullModal, modalFullRef, loincState, handleValueBold, handleValueItalics, handleValueUnderline, handleValueFormatTransform,
                                                                    valueBold, valueItalics, valueUnderline, valueFormatTransform, selectedInfoAnalytes, infoAnalytes, handleValueInfoAnalyte,
                                                                    // handleChangeTextP,data
                                                                    onChange, data, handleChangeTextP, selected, data
                                                                }}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {examId > 0 && (
                                            <IndexCosts examId={examId} />
                                        )}
                                    </TabPane>
                                    <TabPane tabId={"5"}>
                                        {
                                            tabGeneral === "5" && examId > 0 && <GlobalReferenceValue 
                                                examId={examId}                                                
                                            />
                                        }
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </CardBody>
                    {/* </Card> */}
                    {/* </Col> */}
                </Row>

                <ModalAnalytes
                    {...{ modal, toggleModal, nameAnalyte, loadingModal, deleteAnalyte }}
                />
                <ModalTestFormula
                    {...{
                        toggleTest, modalTest, loadingTest, testearFormula, compute, init_range, end_range, value, increment, handleInputTest, resetTest, results, resetResults,
                    }}
                />
                <Modal {...modalProps} padding={false} className="containerZ">
                    {
                        // TODO:estoy armando el modal para los puros rangos, estoy pasando la variable de "method" y debo continuar con es Daniel del futuro, no te estreses.
                        activeModal === "modalRangos" ? (
                            <ModalRange
                                isSimple={true}
                                {...{
                                    name, gender, init_value, end_value, years_from, months_from, days_from, years_to, months_to, days_to,
                                    text_range, handleInputRange, resetRange, validacionesRange, method, methods, id_type_analyte, id_type_precoded_text, precodedTexts, listRanges,
                                    addAnalyteNumberRange, removeAnalyteNumberRange, saveTempAnalytesNumberRanges, editAnalyteNumberRange, saveEditAnalyteRangeNumber, methodActiveRange,
                                    backToCreateRange, id_analyte_age_gender_range, handleInputRangePrecoded, listRangesPrecoded, addRangePrecoded, saveTempRangePrecoded,
                                    removeRangePrecoded, editRangePrecoded, saveEditRangePrecoded, methodActiveRangePrecoded, backToCreateRangePrecoded, saveTempAnalytesNumberAlerts, listReferenceValues,
                                    saveTempAnalytesTextAlerts, listReferenceTexts,
                                }}
                            />
                        ) : (
                            <ModalComplex
                                {...{
                                    analytesAvailables, refreshFormComplex, responseComplexText, refreshResultComplex, preSavedFormComplex,
                                    analytesComplex, exams, exam, methodActive, name,
                                }}
                            />
                        )
                    }
                </Modal>
                {modalOrder && (
                    <ModalOrderAnalytes
                        {...{
                            analytesGroup, modalOrder, loadingOrder, handleModalOrder, saveOrderAnalytes,
                        }}
                    />
                )}
                {modalOrderPrint && (
                    <ModalOrderPrintAnalytes
                        {...{
                            analytesGroupPrint, modalOrderPrint, loadingOrderPrint, handleModalOrderPrint, saveOrderPrintAnalytes,
                        }}
                    />
                )}
            </Container>
        </div>
    );
};

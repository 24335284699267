import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Label, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap'
import Select from 'react-select';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { useHistory } from 'react-router';
// import Creatable, { useCreatable } from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { ModalPatient } from './ModalPatient';
import { useForm } from '../../../hooks/forms/useForm';
import moment from 'moment';
import { Thermometer } from 'react-feather';


export const ItemSample = (props) => {

    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [valueSample, setValueSample] = useState(null);
    const [valueSite, setValueSite] = useState(null);
    const [valueArea, setValueArea] = useState(null);
    const [valueThermometer, setValueThermometer] = useState(null)
    const [valueTypeSample, setValueTypeSample] = useState(null);
    const [valuePatient, setValuePatient] = useState(null);
    const [patientLoinc, setPatientLoinc] = useState([])
    const [dataPatient, setDataPatient] = useState(null)
    const [otherValues, setOtherValues] = useState({
        "temperature": "",
        "quantity": "",
        "observations": "",
        "not_collected": false,
        "listDeterminations":[]
    })
    const [resultData, setResultData] = useState(null);
    const [isSampling, setIsSampling] = useState(true);
    const [sitesAreas, setSitesAreas] = useState([])

    const [dataSample, setDataSample] = useState(null)
    const [origin, setOrigin] = useState(false);
    const [modal, setModal] = useState(false);
    const [state, setState] = useState(false)

    const [muestraData, setMuestraData] = useState({
        // ... otros valores,
        required_temperature: false // o true, dependiendo de tu valor inicial
    });
    // const [formPatient, setFormPatient] = useState({

    // })
    const [formPatient, handleInputChange, , handleUpdateValues, , , , handleDatePatient] = useForm({
        name_patient: "",
        paternal_surname: "",
        maternal_surname: "",
        birthday: "",
        id_gender: "1"
    });

    //#region PROPIEDADES
    const {
        muestra, cotizacion,
        idKey, updateStates, updateSample,
        sites, typeSamples, samples, thermometer, data,
        // setValueThermometer,valueThermometer
    } = props;
    ////console.log(muestra)
    ////console.log("----");
    // //console.log(data);

    //#endregion PROPIEDADES

    ////console.log(otherValues);

    useEffect(() => {
        // setDataSample(muestra);
        // //console.log(typeSamples);
        if (muestra !== null) {
            if (muestra.id_mic_type_sample !== null) {
                let data = typeSamples.find(x => x.id_mic_type_sample === muestra.id_mic_type_sample);
                if (data.origin === 1) {
                    setOrigin(true);
                }
                changeSelect(data, "typeSample")
            }

            if (muestra.id_mic_sample !== null) {
                let data = samples.find(x => x.value === muestra.id_mic_sample);
                changeSelect(data, "sample")
            }

            if (muestra.id_mic_site !== null) {
                let data = sites.find(x => x.id_mic_site === muestra.id_mic_site);
                changeSelect(data, "site");
                changeSite(data);
                handleGetSitesAreas(data.id_mic_site).then(resp => {
                    if (muestra.id_mic_site_areas !== null) {
                        let value = resp.find(x => x.value === muestra.id_mic_site_areas);
                        if (value !== null && value !== undefined) {
                            changeSelect(value, "area");
                        }
                    }
                });
            }
            // if (muestra.id_thermometer !== null) {
            //     let data = thermometer.find(x => x.value === muestra.id_mic_sample);
            //     changeSelect(data, "Thermometers")
            // }
            if (muestra.id_thermometer !== null) {
                let data = thermometer.find(x => x.value === muestra.id_thermometer);
                changeSelect(data, "Thermometers")
            }

            if (muestra.id_patient !== null) {
                setValuePatient(muestra.id_patient);
                findPatient(muestra.id_patient).then(resp => {
                    setPatientLoinc({
                        value: resp.id_patient,
                        label: resp.name_patient
                    })
                });
            }
            // debugger;
            let newOtherValues = {
                "temperature": "",
                "quantity": "",
                "observations": "",
                "not_collected": false,
                "listDeterminations":[]
            }
           
            if (muestra.quantity !== null) {
                newOtherValues.quantity = muestra.quantity;
            }
            if (muestra.observations !== null) {
                newOtherValues.observations = muestra.observations;
            }
            if (muestra.not_collected !== null) {
                newOtherValues.not_collected = muestra.not_collected;
            }
            if (muestra.temperature !== null) {
                newOtherValues.temperature = muestra.temperature;
            }
            if(muestra.listDeterminations!=null && muestra.listDeterminations!=[]){
                newOtherValues.listDeterminations = muestra.listDeterminations;
            } 

            setOtherValues({
                ...newOtherValues
            })
        }
    }, [])



    const changeSite = (info) => {
        handleGetSitesAreas(info.id_mic_site)
    }

    const handleGetSitesAreas = async (id_mic_site) => {
        let requestOptions = {
            method: 'GET'
        };
        const response = await sendRequest(requestOptions, `SitesAreas/List/${id_mic_site}`);
        if (response.code === 200) {
            let tmp_areas = [];
            if (response.data.length > 0) {
                response.data.forEach(a => {
                    tmp_areas.push({
                        value: a.id_mic_site_area,
                        label: a.name,
                        id_mic_site: a.id_mic_site
                    });
                });
                setSitesAreas(tmp_areas);
            } else {
                setSitesAreas([]);
            }

            return tmp_areas;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleGetSitesAreas);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Microbiología", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Microbiología", response.data.msg);
        }
    }

    const handleCreateSite = async (texto) => {
        setIsLoading(true);
        texto = texto.toUpperCase();
        let data = {
            name: texto
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const response = await sendRequest(requestOptions, "Sites/Create");

        if (response.code === 200) {
            const newOption = {
                "label": response.data.name,
                "value": response.data.id_mic_site
            }
            setIsLoading(false);
            updateStates(newOption, "site")
            setValueSite(newOption);
        } else if (response.code === 401) {
            validarSesion(history, response.code, handleCreateSite);
            setIsLoading(false);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setIsLoading(false);
        }
    };

    const handleCreateArea = async (texto) => {
        setIsLoading(true);
        texto = texto.toUpperCase();
        let data = {
            id_mic_site: valueSite.value,
            name: texto
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const response = await sendRequest(requestOptions, "SitesAreas/Create");

        if (response.code === 200) {
            const newOption = {
                "label": texto,
                "value": response.data.id_mic_site_area,
                "id_mic_site": response.data.id_mic_site
            }

            setIsLoading(false);
            setSitesAreas((prev) => [...prev, newOption]);
            setValueArea(newOption);
        }
    };

    const handleCreateTypeSample = async (texto) => {
        setIsLoading(true);
        texto = texto.toUpperCase();
        let data = {
            name: texto,
            abbreviation: texto
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const response = await sendRequest(requestOptions, "TypeSamples/Create");

        if (response.code === 200) {
            const newOption = {
                "label": response.data.name,
                "value": response.data.id_mic_type_sample
            }
            setOrigin(false);
            setIsLoading(false);
            updateStates(newOption, "typeSample")
            setValueTypeSample(newOption);
        } else if (response.code === 401) {
            validarSesion(history, response.code, handleCreateTypeSample);
            setIsLoading(false);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setIsLoading(false);
        }

    };

    const handleCreateSample = async (texto) => {
        setIsLoading(true);
        texto = texto.toUpperCase();
        let data = {
            name: texto,
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const response = await sendRequest(requestOptions, "SampleMic");

        if (response.code === 200) {
            const newOption = {
                "label": response.data.name,
                "value": response.data.id_mic_sample
            }
            setIsLoading(false);
            updateStates(newOption, "sample")
            setValueSample(newOption);
        } else if (response.code === 401) {
            validarSesion(history, response.code, handleCreateSample);
            setIsLoading(false);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setIsLoading(false);
        }
    };
    //#region Thermometer
    const handleCreateThermometer = async (texto) => {
        ////console.log("//////////////////////////////");
        setIsLoading(true);
        texto = texto.toUpperCase();
        let data = {
            name_thermometer: texto,
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const response = await sendRequest(requestOptions, "Thermometers");
        let factor = response.data.correction_factor
        setResultData(response.data.correction_factor)

        if (response.code === 200) {
            const newOption = {
                "label": response.data.name_thermometer,
                "value": response.data.id_thermometer
            }


            ////console.log("gatosss");
            setOrigin(false);
            setIsLoading(false);
            updateStates(newOption, "Thermometers")
            setValueThermometer(newOption);




        } else if (response.code === 401) {
            validarSesion(history, response.code, handleCreateThermometer);
            setIsLoading(false);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setIsLoading(false);
        }
    }

    //#endregion Thermometer

    const changeSelect = (e, type) => {
        // //console.log(e);
        ////console.log("pasass Q");
        switch (type) {
            case "sample":
                setValueSample(e)
                break;
            case "site":
                setValueSite(e)
                break;
            case "area":
                setValueArea(e)
                break;
            case "typeSample":

                if (e.origin === 0) {
                    setOrigin(false)
                } else {
                    setOrigin(true)
                }
                setValueTypeSample(e)
                break;
            case 'Thermometers':
                setValueThermometer(e)

                // let copy = data;
                // //console.log(copy);
                // let arary = copy.find(x => x.id_thermometer === response.data.id_thermometer)
                // //console.log(arary);

                break;
            default:
                break;
        }
    }


    const findPatient = async (id_patient) => {
        let requestOptions = {
            method: 'GET'
        };
        const response = await sendRequest(requestOptions, `Patient/FindPatientId/${id_patient}`);
        if (response.code === 200) {
            setDataPatient({
                "id_patient": response.data.id_patient,
                "birthday": response.data.birthday,
                "gender": response.data.gender
            });
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, findPatient);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Muestreo", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Muestreo", response.data.msg);
        }
    }

    const inputChangePatient = (e) => {
        // //console.log(e);
        setValuePatient(e.target.value);
        findPatient(e.target.value);
    }

    const toggleModal = () => {
        setModal(!modal);
    }

    const handleCreatePatient = () => {
        toggleModal();
    }

    const savePatient = async () => {
        let p_birthday = '';
        if (formPatient.birthday !== "" && formPatient.id_gender !== "" && formPatient.name_patient !== "" && formPatient.paternal_surname !== "" && formPatient.maternal_surname !== "") {
            if (formPatient.birthday !== "") {
                let arrBirthday = formPatient.birthday.split('/');
                p_birthday = `${arrBirthday[2]}-${arrBirthday[1]}-${arrBirthday[0]}`;

                let validDate = moment(p_birthday).format("YYYY-MM-DD");

                if (validDate === "Fecha inválida") {
                    createSweet("warning", "warning", "Paciente", "Verifica la fecha de nacimiento del paciente: " + p_birthday);
                    return;
                }

                if (formPatient.id_gender == 0) {
                    createSweet("warning", "warning", "Paciente", "Verifica el genero.");
                    return;
                }
            }
        } else {
            createSweet("warning", "warning", "Paciente", "Verfique que todos los campos se han llenado.");
            return;
        }

        let data = {
            ...formPatient,
            id_state_patient: null,
            id_municipality_patient: null,
            id_social_gender_patient: null,
            new_social_gender: "",
            id_blood_type: null,
            code: "",
            curp: "CURPDEFAULT",
            address_patient: "",
            cp_patient: "00000",
            colony_patient: "",
            phone: "0",
            email_patient: "CORREO@DEFAULT.COM",
            who_collects_exams: "",
            membership: "",
            cardnumber: "",
            birthday: p_birthday,
            name: formPatient.name_patient,
            expedient_number: "",
        }
        // return;
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const respuesta = await sendRequest(requestOptions, "Patient");

        if (respuesta.code === 200) {
            let e = {
                target: {
                    value: respuesta.data.id_patient
                }
            }
            inputChangePatient(e)
            setPatientLoinc({
                value: respuesta.data.id_patient,
                label: respuesta.data.name_patient
            })
            handleUpdateValues({
                name_patient: "",
                paternal_surname: "",
                maternal_surname: "",
                birthday: "",
                id_gender: "1"
            })
            toggleModal();

            createSweet("success", "success", "Paciente", "Paciente guardado correctamente");
        } else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, savePatient);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Muestreo", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Muestreo", respuesta.data.msg);
        }
        // id_patient
        // name_patient
    }

    //cambiar el estatus si se recolecto la muestra o no
    const changeStatusMuestra = () => {
        setOtherValues({
            ...otherValues,
            not_collected: !otherValues.not_collected
        })
    }

    const sumTemperature = async () => {

        setResultData()
        return (resultData, '°C')
    }

    const changeOtherInputs = (e, input) => {
        let valor = e.target.value;

        //  //console.log(valor);
        switch (input) {
            case "temperature":
                setOtherValues({
                    ...otherValues,
                    temperature: valor
                })
                break;
            // case 'correctionFactor':
            //     setOtherValues({
            //         ...otherValues,
            //         temperature: valor + valueThermometer
            //     })
            //     break;
            case "quantity":
                setOtherValues({
                    ...otherValues,
                    quantity: valor
                })
                break;
            case "observations":
                setOtherValues({
                    ...otherValues,
                    observations: valor
                })
                break;
            default:
                break;
        }
    }


    //guardar datos de la muestra
    const saveSample = async () => {
        setIsLoading(true);

        let data = {
            "id_quoter_type_sample": muestra.id_quoter_type_sample,
            "id_mic_type_sample": valueTypeSample !== null ? valueTypeSample.value : null,
            "folio": muestra.folio,
            "id_quoter": muestra.id_quoter,
            "id_mic_detail_sample": muestra.id_mic_detail_sample,
            "id_mic_sampling": muestra.id_mic_sampling,
            "id_mic_site": valueSite !== null ? valueSite.value : null,
            "id_mic_site_areas": valueArea !== null ? valueArea.value : null,
            "id_mic_sample": valueSample !== null ? valueSample.value : null,
            "id_patient": valuePatient,
            //"id_thermometer": valueThermometer.id_thermometer !== null ? valueThermometer.id_thermometer : null,
            "id_thermometer": valueThermometer && valueThermometer.id_thermometer ? valueThermometer.id_thermometer : null,
            "temperature": otherValues.temperature !== null ? otherValues.temperature : null,
            "quantity": otherValues.quantity !== null ? otherValues.quantity : null,
            "factor_correction": factorCorrection !== null ? factorCorrection : null,
            "observations": otherValues.observations,
            "not_collected": otherValues.not_collected,
            "date_collected": "",
            "required_temperature": muestra.required_temperature !== null ? muestra.required_temperature : state,
            // "required_temperature": muestra.required_temperature,
        }
        ////console.log('DATA: ', data);
        // return;
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        if (Object.keys(data).length > 0) {
            ////console.log('ando andentro')
            createSweet("warning", "warning", "Temperatura", "Una vez guardada la temperatura ya no se podrá cambiar");
            //return; // Esta línea es importante para salir de la función y no ejecutar el código que sigue
            await new Promise(resolve => setTimeout(resolve, 5000));
        }

        const respuesta = await sendRequest(requestOptions, "QuoterDeterminations/SaveIndividualSample");
        ////console.log('RESPUESTA: ', respuesta)
        if (respuesta.code === 200) {
            updateSample(respuesta.data)
            createSweet("success", "success", "Muestreo", "Muestra guardada correctamente");
            setIsLoading(false);
        } else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveSample);
            setIsLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Muestreo", "Ocurrio un error en el servidor");
            setIsLoading(false);
        }
        else {
            createSweet("error", "warning", "Muestreo", respuesta.data.msg);
            setIsLoading(false);
        }
    }


    const [factorCorrection, setFactorCorrection] = useState(0);
    const factorCorrectionRef = useRef(factorCorrection);

    useEffect(() => {
        factorCorrectionRef.current = factorCorrection;
    }, [factorCorrection]);

    useEffect(() => {
        const getFactor = async () => {
            // if (thermometer !== null) {
            //     let findT = Number(await fetchCorrectionFactor(valueThermometer));
            //     let temperatureValue = Number(otherValues.temperature);
            //     let sumTotal = (findT + temperatureValue);
            //     sumTotal = Number(sumTotal.toFixed(2));
            //     // //console.log(factorCorrection);
            //     // //console.log("SUMTOTAL", sumTotal);
            //     setFactorCorrection(sumTotal);
            // }
            if (valueThermometer && thermometer !== null) {
                let findT = Number(await fetchCorrectionFactor(valueThermometer));
                if (findT !== null) {
                    let temperatureValue = Number(otherValues.temperature);
                    let sumTotal = (findT + temperatureValue);
                    sumTotal = Number(sumTotal.toFixed(2));
                    setFactorCorrection(sumTotal);
                } else {
                    setFactorCorrection(0);  // Puedes establecer un valor por defecto si no encuentras el factor de corrección
                }
            }
        }
        getFactor();
    }, [valueThermometer, otherValues]);

    async function fetchCorrectionFactor(valueThermometer) {
        // Imagina que esto es una solicitud a una API o base de datos
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (valueThermometer && valueThermometer.correction_factor) {
                    resolve(valueThermometer.correction_factor);
                } else {
                    resolve(null);  // o puedes usar reject si lo consideras necesario
                }
            },);
        });
        //setFactorCorrection(valueThermometer.correction_factor)
    }

    const updateStates1 = (key, value) => {
        setMuestraData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // const handleCheckboxChange = (e) => {
    //     // setState(e.target.checked);
    // };

    const handleCheckboxChange = (e) => {
        const newState = !state;
        setState(newState);
        ////console.log(state)
    };

    ////console.log(state);

    useEffect(() => {
        if (!state) {
            ////console.log("que pasa aqui?");
            //setState(true);
            //Establecer los valores a null o a un valor inicial
            // setOtherValues({
            //     ...otherValues,
            //     temperature: null,
            //     quantity: null,
            // });
            //setValueThermometer(null);
        }
    }, [state]);

    // //console.log(otherValues);

    return (
        <Card className={`shadow`}>
            <CardHeader className='p-2 bg-primary text-center'>
                Muestra {muestra.folio}
            </CardHeader>
            <div className='p-3'>
                <Row>
                    <Col className='mb-2' sm="12">
                        Sitio
                        <CreatableSelect
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            menuPlacement={"bottom"}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="site"
                            options={sites}
                            value={valueSite}
                            onCreateOption={handleCreateSite}
                            onChange={(e) => (changeSelect(e, "site"), changeSite(e))}
                            bsSize="sm"
                            placeholder={"Sitios..."}
                            theme={
                                (theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989'
                                    }
                                })
                            }
                        />
                    </Col>
                    <Col className='mb-2' sm="12">
                        Area
                        <CreatableSelect
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            menuPlacement={"bottom"}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="area"
                            options={sitesAreas}
                            value={valueArea}
                            onCreateOption={handleCreateArea}
                            onChange={(e) => (changeSelect(e, "area"))}
                            bsSize="sm"
                            placeholder={"Area..."}
                            theme={
                                (theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989'
                                    }
                                })
                            }
                        />
                    </Col>
                    <Col className='mb-2' sm="12">
                        Tipo de muestra
                        <CreatableSelect
                            //isDisabled={isLoading}
                            isDisabled={true}
                            isLoading={isLoading}
                            menuPlacement={"bottom"}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="typeSample"
                            options={typeSamples}
                            value={valueTypeSample}
                            onCreateOption={handleCreateTypeSample}
                            onChange={(e) => (changeSelect(e, "typeSample"))}
                            bsSize="sm"
                            placeholder={"Tipo de muestra..."}
                            theme={
                                (theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989'
                                    }
                                })
                            }
                        />
                    </Col>
                    {
                        origin ?
                            <Col className='mb-2' sm="12">
                                <div>
                                    <div className="text-right"><Button onClick={toggleModal} type='button' size='sm'>+</Button></div>
                                    <div className="text-left">SELECCIONA O AGREGA AL PACIENTE</div>
                                </div>
                                <SearchAsync
                                    cacheOptions={false}
                                    value={valuePatient}
                                    name="valuePatient"
                                    method={inputChangePatient}
                                    loincState={patientLoinc}
                                    url="Patient/SearchPatient"
                                    maxLenght={3}
                                    placeholder="Búsqueda por nombre o curp"
                                />
                                {
                                    dataPatient !== null ?
                                        <p className='mb-0 mt-2'>Sexo: {dataPatient.gender} - Fecha de nacimiento: {dataPatient.birthday}</p>
                                        : ""
                                }
                            </Col>
                            : <Col className='mb-2' sm="12">
                                Descripción de muestra
                                <CreatableSelect
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    menuPlacement={"bottom"}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="sample"
                                    options={samples}
                                    value={valueSample}
                                    onCreateOption={handleCreateSample}
                                    onChange={(e) => changeSelect(e, "sample")}
                                    bsSize="sm"
                                    placeholder={"Tipo de muestra..."}
                                    theme={
                                        (theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#898989'
                                            }
                                        })
                                    }
                                />
                            </Col>
                    }
                    {/* {//console.log("Valor actual de state:", muestra.required_temperature)} */}
                    {/* <Col className='mb-2 d-flex align-items-center' sm="12">
                        <Label style={{ marginRight: '1cm' }}>Termómetro:</Label>
                        <Label className="switch mb-0">
                            <Input
                                type="checkbox"
                                name="optionTermo"
                                onChange={handleCheckboxChange}
                                checked={muestra.required_temperature}
                            />
                            <span className="slider round"></span>
                        </Label>
                    </Col> */}
                    <Col className='mb-2 d-flex align-items-center' sm="12">
                        <Label style={{ marginRight: '1cm' }}>Termómetro:</Label>
                        <Label className="switch mb-0">
                            <Input
                                type="checkbox"
                                name="optionTermo"
                                onChange={handleCheckboxChange}
                                checked={muestra.required_temperature}
                                disabled={muestra.required_temperature && state}
                            />
                            <span className="slider round"></span>
                        </Label>
                    </Col>
                    {
                        // muestra.required_temperature === true ?
                        (muestra.required_temperature !== null ? muestra.required_temperature : state) === true ?
                            <Col className='mb-2' sm="12">
                                <Row>
                                    <Col className='mb-2' sm="12">
                                        Termómetro
                                        <CreatableSelect
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            menuPlacement={"bottom"}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="Thermometers"
                                            options={thermometer}
                                            value={valueThermometer}
                                            onCreateOption={handleCreateThermometer}
                                            onChange={(e) => (changeSelect(e, "Thermometers"))}
                                            bsSize="sm"
                                            placeholder={"Termómetro..."}
                                            theme={
                                                (theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#898989'
                                                    }
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col className='mb-2' sm="4">
                                        Temperatura °C.
                                        <Input type='text' className='form-control' onChange={(e) => changeOtherInputs(e, "temperature")} value={otherValues.temperature || ""} />
                                    </Col>
                                    <Col className='mb-2' sm="4">
                                        Temperatura Corregida °C.
                                        <Input type='text' className='form-control' value={factorCorrection} disabled />
                                    </Col>
                                    <Col className='mb-2' sm="4">
                                        Cantidad
                                        <Input type='text' className='form-control' onChange={(e) => changeOtherInputs(e, "quantity")} value={otherValues.quantity || ""} />
                                    </Col>
                                </Row>
                            </Col>
                            :
                            <Col className='mb-2' sm="4">
                                <p>Sin opciones de temperatura</p>
                                        Cantidad
                                        <Input type='text' className='form-control' onChange={(e) => changeOtherInputs(e, "quantity")} value={otherValues.quantity || ""} />
                                    </Col>
                            
                    }
                    <Col className='mb-2' sm="12">
                        Observaciones
                        <Input type='textarea' className='form-control' onChange={(e) => changeOtherInputs(e, "observations")} value={otherValues.observations} />
                    </Col>
                    <Col className='mb-4' sm="12">
                        <br />
                        MARCAR QUE LA MUESTRA NO FUE TOMADA {!otherValues.not_collected ? <Button onClick={changeStatusMuestra} color='' className='btn btn-xs btn-outline-info'><i style={{ fontSize: 16 }} className="fa fa-square"></i></Button> : <Button onClick={changeStatusMuestra} color='' className='btn btn-xs btn-info'><i style={{ fontSize: 16 }} className="fa fa-check-square"></i></Button>}
                    </Col>
                    <Col className='mb-5' sm="12">
                        <Label className='danger'>Lista Determinaciones:</Label>
                        <ul>
                           
                            {otherValues.listDeterminations.length >= 1 && otherValues.listDeterminations.map((item, index) => {
                             return (
                                <li>*{item.name}</li>
                             )
                            })
                            }
                            
                            
                        </ul>

                    </Col>
                    <Col className='mb-2' sm="12">
                    <Button disabled={isLoading} type='button' size='lg' className={`${isLoading ? "progress-bar-animated progress-bar-striped btn-block" : "btn btn-primary btn-block"}`} onClick={saveSample}>GUARDAR MUESTRA</Button>   
                    </Col>
                </Row>
            </div>
            <ModalPatient
                {
                ...{
                    modal, toggleModal,
                    formPatient, handleInputChange, handleDatePatient, savePatient
                }
                }
            />
        </Card>
    )
}
import { useState, useEffect } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';
import { useDebounce } from '../debounce/useDebounce';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { ConfigServer } from '../../data/config';




export const useViewInvoice = () => {
    
    const history = useHistory();
    const id_branch = localStorage.getItem("branchId");

    //#region GLOBAL VARIABLES
    const [loadingTableInvoice, setLoadingTableInvoice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState("");
    //#endregion

    //#region  SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //#endregion

    //#region MODALS
    const [modalTitle, setModalTitle] = useState("");

    const [modalCancellation, setModalCancellation] = useState(false);

    const toggleModalCancellation = () => {
        setModalCancellation(!modalCancellation);
    }
    //#endregion

    //#region ViewInvoice

        //#region MODELS
            const [informationInvoice, setInformationInvoice] = useState({});

            const [ListInvoices, setListInvoices] = useState([]);     
            const [ListTypeCancellations, setListTypeCancellations] = useState([]); 
            const [listbranch , setListBranch ] = useState([]);

            
            //#region FILTERS
                const [searchQuery, setSearchQuery] = useState("");
                const debounceValue = useDebounce(searchQuery, 500);
                const [filters, setFilters] = useState(`&id_branch=${id_branch}`);
            //#endregion END FILTERS

            //#region FORM
                const [formInvoice, handleInputChange, reset, handleUpdateValues] = useForm({
                    id_invoice: 0,
                    code_type_cancellation: "0",
                    code_reference_value: "",
                    is_general_public: false,
                    is_general_public: false

                });

                const {
                    id_invoice, code_type_cancellation, code_reference_value, is_general_public
                } = formInvoice;

                const [formFilter, handleInputChangeFilters, resetFilters, handleUpdateValuesFilter] = useForm({
                    iSerie: "",
                    iFolio: "",
                    iRfc: "",
                    iTypeDocument: "0",
                    iTypePayMethod: "0",
                    iDateIni: "",
                    iDateEnd: "",
                    iBranch: id_branch,
                    isgeneral_public: false,
                    allBranch: 0,
                });

                const {
                    iSerie, iFolio, iRfc, iTypeDocument, iTypePayMethod, iDateIni, iDateEnd, iBranch,isgeneral_public,allBranch
                } = formFilter;
            //#endregion
            
        //#endregion

        //#region METHODS
            //#region HANDLE METHODS
                const handleCancelInvoice = (_id_invoice) => {

                    let _findInvoice = ListInvoices.find(x => x.id_invoice === _id_invoice);

                    if (_findInvoice !== undefined) {                    
                        setLoading(false);
                        setModalTitle("Cancelación");
                        toggleModalCancellation();
                        setMethod("cancellation");
                        
                        setInformationInvoice({
                            rfcReceptor: _findInvoice.rfcReceptor,
                            commercialName: _findInvoice.commercialName,
                            uuid: _findInvoice.uuid,
                            serieFolio: _findInvoice.serieFolio,
                            dateCreated: _findInvoice.dateCreated,
                            codeCoin: _findInvoice.codeCoin,
                            total: _findInvoice.total,
                            typeDocument: _findInvoice.typeDocument
                        });

                        handleUpdateValues({
                            id_invoice: _id_invoice,
                            code_type_cancellation: _findInvoice.isGeneralPublic ? "04" : "0",
                            code_reference_value: "",
                            is_general_public: _findInvoice.isGeneralPublic
                        });
                    }

                }

                const handleTypeCompany = (_typeClient) => {
                    let _valid = _typeClient === 1 ? null : null

                    handleUpdateValuesFilter({
                        ...formFilter,
                        allBranch: _valid
                    });
                }

                const handleSearch = (e) => {
                    let _search = e.target.value;

                    setSearchQuery(_search);
                }

                const handleFilters = () => {

                    let _valid = allBranch
                    console.log(_valid);
                    let _query = "";

                    if (iSerie !== "") {
                        _query += `&serie=${iSerie}`;
                    }

                    if (iFolio !== "") {
                        _query += `&folio=${iFolio}`;
                    }

                    if (iRfc !== "") {
                        _query += `&rfc=${iRfc}`;
                    }
                    
                    if (iTypeDocument !== "0") {
                        _query += `&typeDocument=${iTypeDocument}`;
                    }
                    
                    if (iTypePayMethod !== "0") {
                        _query += `&typePayMethod=${iTypePayMethod}`;
                    }
                    
                    if (iDateIni !== "" && iDateEnd !== "") {
                        _query += `&dateIni=${iDateIni}&dateEnd=${iDateEnd}`;
                    }

                    if (allBranch != null) {
                        if (iBranch !== "") {
                            _query += `&id_branch=${iBranch}`;
                        }   
                    }
                    else {
                        _query += `&id_branch=${""}`;
                        console.log("pasa aqui mi filtro");
                    }

                    if (isgeneral_public !== false) {
                        _query += `&is_general_public=${isgeneral_public}`;
                    }

                    setFilters(_query);
                }

                console.log(filters);

                const handleEditInvoice = (_id_invoice) => {
                    history.push(`../invoice/service/${_id_invoice}`);
                }

            //#endregion

            //#region PAGINATION
                const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
                    { method: 'GET' },
                    'Invoice/GetListInvoices',
                    history,
                    searchQuery,
                    filters
                );
            //#endregion

            //#region POST METHODS            
            
                const SaveCancellationInvoice = async () => {

                    setLoading(true);

                    let _list = [];

                    _list.push({
                        id_invoice: formInvoice.id_invoice,
                        uuid_from: informationInvoice.uuid,
                        cancellation_code: formInvoice.code_type_cancellation,
                        uuid_to: formInvoice.code_reference_value
                    });

                    const _response = await _handleRequest("POST", "Invoice/CreateInvoiceCancellation", "Guardar cancelación", _list);

                    if (_response !== null) {
                        setLoading(false);
                        setModalTitle("");
                        setMethod("");
                        toggleModalCancellation();

                        setInformationInvoice({});
                        handleUpdateValues({
                            id_invoice: 0, 
                            code_type_cancellation: "0",
                            code_reference_value: "",
                            is_general_public: false
                        });

                        createSweet("create", "success", "Exito!", "Se cancelo correctamente");
                    }
                    else {
                        setLoading(false);
                    }
                }

                const DownloadInvoice = async (uuid_invoice, is_preinvoice = false) => {

                    if (is_preinvoice) {
                        let token = localStorage.getItem("token");

                        await fetch(ConfigServer.serverUrl + "/api/Invoice/DownloadPreInvoice/" + uuid_invoice, {
                            method: 'GET',
                            headers: new Headers({
                                "Authorization": "Bearer " + token,
                                "Content-Type": "application/json"
                            })
                        })
                        .then(response => {
                            if (response.status !== 200) {
                                createSweet("warning", "warning", "No se descargo el archivo el PDF de la prefactura");
                                return;
                            }

                            return response.blob();
                        })
                        .then(blob => {
                            let url = window.URL.createObjectURL(blob);

                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${uuid_invoice}.pdf`);
                            document.body.appendChild(link);
                            link.style.display = 'none';
                            link.click();                        
                        })
                    }
                    else {
                        const response = await _handleRequest("POST", `Invoice/DownloadPdfInvoice/${uuid_invoice}`, "Descarga de factura");                   
                        
                        if (response !== null) {
                            let linkPdf = `data:application/pdf;base64,${response.cadena}`;
                            let downloadLink = document.createElement("a");
                            let fileName = uuid_invoice+".pdf";
                            downloadLink.href = linkPdf;
                            downloadLink.download = fileName;
                            downloadLink.style.display = 'none';
                            downloadLink.click();
                        }
                    }
                }

                const DownloadXml = async (id_invoice, uuid_invoice) => {
                    let token = localStorage.getItem("token");

                    await fetch(ConfigServer.serverUrl + "/api/Invoice/DownloadXml/" + id_invoice, {
                        method: 'POST',
                        headers: new Headers({
                            "Authorization": "Bearer " + token,
                            "Content-Type": "application/json"
                        })
                    })
                    .then(response => {
                        if (response.status !== 200) {
                            createSweet("warning", "warning", "No se descargo el archivo Xml");
                            return;
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);

                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `${uuid_invoice}.xml`);
                        document.body.appendChild(link);
                        link.style.display = 'none';
                        link.click();                        
                    })
                }

                const DownloadSupport = async (id_invoice) => {
                    let token = localStorage.getItem("token");

                    await fetch(ConfigServer.serverUrl + "/api/Invoice/DownloadInvoiceSupport/" + id_invoice, {
                        method: 'GET',
                        headers: new Headers({
                            "Authorization": "Bearer " + token,
                            "Content-Type": "application/json"
                        })
                    })
                    .then(response => {
                        if (response.status !== 200) {
                            createSweet("warning", "warning", "No se descargo el archivo el PDF del soporte");
                            return;
                        }

                        return response.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);

                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `soporte_${id_invoice}.pdf`);
                        document.body.appendChild(link);
                        link.style.display = 'none';
                        link.click();                        
                    })
                }
            //#endregion

        //#endregion

        //#region USE EFFECTS
            
            useEffect(() => {
                
                let _list = [];

                results.forEach(_invoice => {

                    let fechaHora = _invoice.date_2
                    let fechaSinMinutos = fechaHora.split(" ")[0];

                    let fechaHora1 = _invoice.date_1
                    let fechaSinMinutos1 = fechaHora1.split(" ")[0];

            
                    let visibleTable = true;
                    
                    _list.push({
                        id_tmp: (Date.now() + _invoice.id_invoice),
                        id_invoice: _invoice.id_invoice,
                        rfcReceptor: _invoice.rfcReceptor,
                        commercialName: _invoice.commercialName,
                        isGeneralPublic: _invoice.isGeneralPublic,
                        isPatient: _invoice.isPatient,
                        isCompany: _invoice.isCompany,
                        uuid: _invoice.uuid,
                        serieFolio: _invoice.serieFolio,
                        dateCreated: _invoice.dateCreated,
                        codeCoin: _invoice.codeCoin,
                        total: _invoice.total,
                        typeDocument: _invoice.typeDocument,
                        isEnvelope: _invoice.isEnvelope,
                        isPayed: _invoice.isPayed,
                        isEnvelopeOpen: _invoice.isEnvelopeOpen,
                        statuaInvoice: _invoice.statuaInvoice,
                        versionCfdi: _invoice.versionCfdi,
                        nameCompany: _invoice.nameCompany,
                        type_method: _invoice.type_method,
                        status_pay: _invoice.status_pay,
                        rfc_cliente: _invoice.rfc_cliente,
                        email_cliente: _invoice.email_cliente,
                        subtotal: _invoice.subtotal,
                        total_tax: _invoice.total_tax,
                        total_retentions: _invoice.total_retentions,
                        total_i: _invoice.total_i,
                        id_pay_method: _invoice.id_pay_method,
                        date_1: fechaSinMinutos1,
                        date_2: fechaSinMinutos,
                        visibleTable: visibleTable
                    });
                });
                setListInvoices(_list);

            }, [results]);

            useEffect(() => {
                searchAsync();
            }, [debounceValue]);

            useEffect(() => {
                if (filters !== "") {
                    searchAsync();
                }
            }, [filters]);

            useEffect(() => {

                setListTypeCancellations([
                    {
                        code: "01",
                        description: "(01) COMPROBANTE EMITIDO CON ERRORES CON RELACIÓN"
                    },
                    {
                        code: "02",
                        description: "(02) COMPROBANTE EMITIDO CON ERRORES SIN RELACIÓN"
                    },
                    {
                        code: "03",
                        description: "(03) NO SE LLEVO ACABO LA OPERACIÓN"
                    },
                    {
                        code: "04",
                        description: "(04) OPERACIÓN NOMINATIVA RELACIONADA EN LA FACTURA GLOBAL"
                    },
                ]);
                getBranch();
                
            }, []);
                            
        //#endregion
        
    //#endregion

    //#region BRANCHES
        const getBranch  = () => {
            const arrayBranch =  JSON.parse(localStorage.getItem('list_branch_storage'));
            setListBranch(arrayBranch);
        }
    //#endregion

      


    


    //#region excel
   

    const downdloadExcel = async () => {

        let _token = localStorage.getItem("token");
      

        await fetch(`/api/Invoice/DownloadListInvoices?${filters}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + _token,
                "Content-Type": "application/vnd.ms-excel"

            })
        })

            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Reporte_De_Facturas.xlsx")
                document.body.appendChild(link)
                    ;
                link.style.display = 'none';
                link.click();

            })
    }



    //#endregion excel

   



    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {

        listbranch,

        //#region RETURN GLOBAL VARIABLES
            loadingTableInvoice, loading, method,
        //#endregion

        //#region MODALS
            modalTitle, modalCancellation, toggleModalCancellation,
        //#endregion

        //#region RETURN VIEW INVOICE
            // RETURN MODELS
            informationInvoice, ListInvoices, ListTypeCancellations,

            id_invoice, code_type_cancellation, code_reference_value, is_general_public, 

            handleInputChange, 

            //RETURN FILTERS
            iSerie, iFolio, iRfc, iTypeDocument, iTypePayMethod, iDateIni, iDateEnd, iBranch,

            handleInputChangeFilters,

            //RETURN HANDLE METHODS
            handleCancelInvoice, SaveCancellationInvoice, handleSearch,
            handleFilters, DownloadInvoice, DownloadXml, DownloadSupport,
            handleEditInvoice,

            //RETURN PAGINATION
            goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
            prevPage, nextPage, totalRecords, resultCount,
            downdloadExcel,isgeneral_public,
            allBranch,handleTypeCompany
        //#endregion 
    }
}

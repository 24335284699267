import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigServer } from '../../data/config';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import moment from 'moment';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';




export const useQuoter = () => {

    //USE HISTORY
    const history = useHistory();

    //USE LOCAL STORAGE VARIABLES
    let typeCommercialLine = localStorage.getItem('commercialLineId');
    let branch = localStorage.getItem('branchId')
    let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');
    let roleUser = localStorage.getItem('roleUser');

    //#region DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, , , , , sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    //#endregion

    //#region MODALS    
    //MODEL
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");
    const [loading, setLoading] = useState(false);
    const [update_quoter, setupdate_quoter] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalRepeatProfile, setModalRepeatProfile] = useState(false);
    const [modalViewExamsP, setModalViewExamsP] = useState(false);
    const [modalFindQuoter, setModalFindQuoter] = useState(false);
    const [modalPatient, setModalPatient] = useState(false);
    //METHODS
    const toggle = () => {
        setModal(!modal);
    }

    const toggleRepeatProfile = () => {
        setModalRepeatProfile(!modalRepeatProfile);
    }

    const toggleModalViewExams = () => {
        setModalViewExamsP(!modalViewExamsP);
    }
    const toggleModalFindQuoter = () => {
        setModalFindQuoter(!modalFindQuoter);
    }
    const togglePatient = () => {
        setModalPatient(!modalPatient);
    }

    //#endregion MODALS

    //#region QUOTER

    //#region QUOTER MODEL
    //VIEW BUTTONS IN QUOTER
    const [viewButtons, setViewButtons] = useState(true);
    const [viewButtonsCreate, setViewButtonsCreate] = useState(false);

    //COUNTERS
    const [cardExam, setCardExam] = useState(0);
    const [cardProfile, setCardProfile] = useState(0);

    //SEARCH TEST
    const [examProfileState, setExamProfileState] = useState([]);

    //VALIDATIONS
    const [validationsTest, setValidationsTest] = useState({
        valid_repeat_test: false,
        valid_exist_exam_profile: false,
        valid_conteins_exam: false,
        message_validation: ""
    });

    const [validationsRepeat, setValidationsRepeat] = useState({
        name_profile: "",
        repeat_profiles: [],
        repeat_exams: []
    });

    //QUOTER
    const [idQuoter, setIdQuoter] = useState(0);
    const [CodeQuoter, setCodeQuoter] = useState("");
    const [times, setTimes] = useState([]);
    const [time, setTime] = useState([]);
    const [viewExamsProfile, setViewExamsProfile] = useState([]);
    const [totalW, setTotalW] = useState(0.00);
    const [subTotalW, setSubTotalW] = useState(0.0);
    const [totalTaxesW, setTotalTaxesW] = useState(0.0);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValuesWorkOrder, handlePickerValues] = useForm({
        id_company: 0,
        id_patient: 0,
        id_type_client: 0,
        // date_sample: "",
        date_sample: "",
        date_sample_up: moment().format("D/M/YYYY hh:mm"),
        is_sampling: false,
        observations: "",
        list_type_samples: [],
        listQuoterTest: []
    });

    const {
        id_company,
        id_patient,
        id_type_client,
        date_sample,
        is_sampling,
        observations,
        list_type_samples,
        listQuoterTest } = formValues;

    const [formFindQuoter, handleInputChangeFind, resetFind, handleUpdateValuesFind, handleSelectValuesWorkOrderFind, handlePickerValuesFind] = useForm({
        id_quoter_find: ""
    });

    const {
        id_quoter_find
    } = formFindQuoter;

    //#endregion 
    //#region new conts
    const [arrayAgreementDiscounts, setArrayAgreementDiscounts] = useState([]);
    const [searchSampleState, setSearchSampleState] = useState([]);
    const [checkTypeClient, setCheckTypeClient] = useState(1);
    const [listCompanies, setListCompanies] = useState([]);
    const [informationCompany, setInformationCompany] = useState({});
    const [searchPatientState, setSearchPatientState] = useState([]);
    const [totalDiscountW, setTotalDiscountW] = useState(0.0);
    const [informationPatient, setInformationPatient] = useState({
        birthday: null,
        is_complete: false,
        phone: null,
        gender: null,
        age: null,
        email: null,
        curp: null,
        membership: null,
        name: null,
        paternal_surname: null,
        maternal_surname: null,
        cardnumber: null,
    });

    const [viewPatient, setviewPatient] = useState(false);
    const [viewCompany, setviewCompany] = useState(false);
    const [methodSampleMic, setMethodSampleMic] = useState("");
    const [modalSampleMic, setModalSampleMic] = useState(false);
    const toggleSampleMic = () => {
        setModalSampleMic(!modalSampleMic);
    }
    const [type_sample_select, setType_sample_select] = useState();
    const [id_exam_temp, setId_exam_temp] = useState();
    const [type_sample_select_name, setType_sample_select_name] = useState();
    const [origin, setOrigin] = useState();
    const [name_human, setname_human] = useState();
    const [paternal_human, setpaternal_human] = useState();
    const [maternal_human, setmaternal_human] = useState();
    const [id_exam_temp_name, setId_exam_temp_name] = useState();
    const [enabledatagrid, setEnabledatagrid] = useState(false);
    const [enableDetermination, setDetermination] = useState(false);
    const [listsamples, setListsamples] = useState([]);
    const [check_cotizar, setcheck_cotizar] = useState(false);
    const [modalSpecialDiscounts, setModalSpecialDiscounts] = useState(false);
    //LABEL DE HOY
    const [updatedatesample, setUpdatedatesample] = useState(false);
    const [labelDateSample, setlabelDateSample] = useState("");
    //NORMAS
    const [listNom, setlistNom] = useState([]);
    // const [nomSelected, setNomSelected] = useState([]);
    // const [list_nom_det, setList_nom_det] = useState();
    // const [observacion_det, setobservacion_det] = useState("");

    // HOOKS PATIENT
    const [methodPatient, setMethodPatient] = useState("");
    const [listStates, setListStates] = useState([]);

    const [loadingPatient, setLoadingPatient] = useState(false);



    //SETEAR SELECT NOM
    //LISTA NORMA
    const handleGetNom = async () => {
        let listTmp = await handleRequest("GET", "CatNom/List", "Normas");

        if (listTmp !== null && listTmp.length > 0) {
            let arreglo = [];
            listTmp.forEach(obj => {
                arreglo.push(
                    { name: obj.name, code: obj.id_mic_nom },
                )
            });
            setlistNom(arreglo);


        }
    }
    const changenom = (i, id_exam, id_profile = null, index) => {
        let e = i.value;

        let array = listNom;
        let sectionfind = [];
        //console.log(array);
        //console.log(e);
        array.map(obj => {
            if (e.some(x => x.code === obj.code)) {
                sectionfind.push(
                    { name: obj.name, code: obj.code },
                )
            }
            return obj;
        });
        let list_type_sample_temp = list_type_samples;
        let find_type_sample = list_type_sample_temp[index];

        if (find_type_sample) {
            let findDeterminations = find_type_sample.list_determinations;
            findDeterminations.map(x => {
                if (id_exam !== null) {
                    if (x.id_exam == id_exam) {
                        x.list_nom = sectionfind;
                        //console.log(x.list_nom);
                    }

                } else {
                    if (x.id_profile == id_profile) {
                        x.list_nom = sectionfind;
                        //console.log(x.list_nom);
                    }
                }

                return x;
            })
        }
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });
    }
    const changeobservacionesinput = (i, id_exam, id_profile = null, id_type_sample) => {

        let e = i.target.value;
        let list_type_sample_temp = list_type_samples;
        let find_type_sample = list_type_sample_temp.find(x => x.id === parseInt(id_type_sample));
        if (find_type_sample) {
            let findDeterminations = find_type_sample.list_determinations;
            findDeterminations.map(x => {
                if (id_exam !== null) {
                    if (x.id_exam == id_exam) {
                        x.observations_det = e;
                    }

                } else {
                    if (x.id_profile == id_profile) {
                        x.observations_det = e;
                    }
                }

                return x;
            })
        }
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });
        //console.log(e);
    }
    //discount parti
    const changediscount_part = (i, id_exam, id_profile = null, index) => {

        let e = i.target.value;
        let list_type_sample_temp = list_type_samples;
        let find_type_sample = list_type_sample_temp[index];
     
        if (find_type_sample) {
            let findDeterminations = find_type_sample.list_determinations;
            findDeterminations.map(x => {
                if (id_exam !== null) {
                    if (x.id_exam == id_exam) {
                        x.discount_par = e;
                    }

                } else {
                    if (x.id_profile == id_profile) {
                     
                        x.discount_par = e;
                    }
                }

                return x;
            })
        }
        
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });
      
    }

    //REDIRECCIONAMIENTO
    const muestreo = (code) => {
        history.push("../sanitaria/muestreo/" + code);
        //window.location.href = `${process.env.PUBLIC_URL}/sanitaria/muestreo/` + code;
    }

    //FIND QUOTER
    const findQuoter = async (e) => {
        e.preventDefault();
        let code = formFindQuoter.id_quoter_find;
        let requestOptions = {
            method: 'GET'
        };
        const response = await sendRequest(requestOptions, `QuoterDeterminations/FindQuoter/${code}`);
        if (response.code === 200) {
            setupdate_quoter(true);
            let list_patient = response.data.patient;
            let list_company = response.data.company;
            let listtypesample = response.data.list_type_samples;
            //console.log(listtypesample);
            let others = response.data;
            //console.log(list_patient);
            if (list_patient != "[]" && list_patient.length > 0) {
                let tmpPatient = {
                    birthday: list_patient[0].birthday,
                    is_complete: list_patient[0].is_complete,
                    phone: list_patient[0].phone,
                    gender: list_patient[0].gender,
                    age: list_patient[0].age,
                    email: list_patient[0].email,
                    email_patient: list_patient[0].email,
                    curp: list_patient[0].curp,
                    membership: list_patient[0].membership,
                    name: list_patient[0].name,
                    paternal_surname: list_patient[0].paternal_surname,
                    maternal_surname: list_patient[0].maternal_surname,
                    cardnumber: list_patient[0].unique_uuid,
                    id_social_gender_patient: list_patient[0].id_social_gender_patient
                };
                setInformationPatient(tmpPatient);
                setSearchPatientState({
                    value: list_patient[0].id_patient,
                    label: list_patient[0].name_patient + " - " + list_patient[0].curp
                });
            }
            let e;
            if (others.id_company != 0 && others.id_company != null) {
                handleGetClients(others.id_type_client);
                setInformationCompany({
                    code: list_company[0].code,
                    phone: list_company[0].phone,
                    email: list_company[0].email
                });

                e = {
                    "value": others.id_company,
                    "label": list_company[0].code + ' ' + list_company[0].business_name
                };
                //console.log(e);
                handleSelectValuesWorkOrder(e, "id_company");
            }



            setlabelDateSample(others.date_sample);
            let datesample = moment(others.date_sample).format("DD/MM/YYYY hh:mm'");
            //console.log(datesample);
            handleUpdateValues({
                ...formValues,
                id_patient: others.id_patient,
                list_type_samples: [...listtypesample],
                id_company: e,
                id_type_client: others.id_type_client,
                date_sample: datesample,
                is_sampling: others.is_sampling,
                observations: others.observations,

            });
            setCheckTypeClient(others.id_type_client);

            handleUpdateValuesFind({
                ...formFindQuoter,
                id_quoter_find: "",
            });
            setModalFindQuoter(!modalFindQuoter);
            setIdQuoter(others.id_quoter);
            setCodeQuoter(code);
        }
        else if (response.code === 401) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");

        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);

        }
    }
    const habilitar_picket = async () => {

        if (updatedatesample == false) {
            setUpdatedatesample(true);
        } else {
            setUpdatedatesample(false);
        }
        //console.log(updatedatesample);

    }
    //Funcion para habilitar la determinacón
    const handleSelectDetermination = (e) => {
        //console.log(e);
        let id_type_sample_l = e.target.value;
        //console.log(id_type_sample_l)
        let name = e.target.name;
        //console.log(name);
        setType_sample_select(id_type_sample_l);
        let sample_name = "";
        let origin = 0;
        //console.log("newListTest");
        if (id_type_sample_l !== 0 && id_type_sample_l !== null) {

            listsamples.map(x => {

                if (id_type_sample_l !== null && x.id_mic_type_sample === parseInt(id_type_sample_l)) {
                    sample_name = x.name;
                    origin = x.origin;
                }
                return x;
            })
            let list_type_sample_temp = list_type_samples;
            let findDeterminations = list_type_sample_temp.find(x => x.id === parseInt(id_type_sample_l));
            if (findDeterminations) {
                setname_human(findDeterminations.name_human);
                setpaternal_human(findDeterminations.paternal_human);
                setmaternal_human(findDeterminations.maternal_human);
                list_type_sample_temp.map(x => {
                    if (x.id == findDeterminations.id) {
                        x = findDeterminations;
                    }
                    return x;
                })
                handleUpdateValues({
                    ...formValues,
                    list_type_samples: [...list_type_sample_temp]
                });
            } else {
                setname_human("");
                setpaternal_human("");
                setmaternal_human("");
            }

            setType_sample_select_name(sample_name);
            setOrigin(origin);

            setDetermination(true);
        } else {
            setDetermination(false);
            setOrigin();
        }

    }
    const handleInputChangeQ = ({ target }) => {


        if (target.name === 'name_human') {
            setname_human(target.value);

        } else if (target.name === 'paternal_human') {
            setpaternal_human(target.value);
        }
        else if (target.name === 'maternal_human') {
            setmaternal_human(target.value);
        }
    }
    //Funcion para elegir determinación
    const handleSelectExamChange = (e) => {
        let id_determinacion = e.target.value;
        let name = e.target.name;
        setId_exam_temp(id_determinacion);
        setId_exam_temp_name(name);
    }
    //Funcion para agregar tipos de muestra
    const AddNewSample = (e) => { 
        //console.log("estoy acá",type_sample_select);
        let id_type_sample = type_sample_select;
        let list = [];
        let list_type_sample_temp = list_type_samples;
        list_type_sample_temp.push({
            id: type_sample_select,
            list_determinations: list,
            name_sample: type_sample_select_name,
            id_type_client: 0,
            observations: "",
            price_sample: 0,
            cant: 1
        })
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });
        //console.log(list_type_samples);
    }
    //Funcion para agregar determinaciones
    const AddNewDetermination = (index) => {
        setEnabledatagrid(true);
        let examProfile = id_exam_temp;
        let id_type_sample = type_sample_select;

        if (examProfile !== 0 && examProfile !== null && examProfile !== undefined) {

            examProfile = examProfile.split('-');

            let idTest = examProfile[0];
            let typeTest = examProfile[1];
            let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";
            if (nameTypeTest === "Profile") {
                handleGetProfile(idTest, index);
            }
            else {
                handleGetExam(idTest, index);
            }
        } else {
            createSweet("success", "warning", "Debe elegir una determinación");
        }
    }
    //Función para traer determinaciones como perfiles

    const handleGetProfile = async (id_profile, index, valid_exist = -1) => {
        let mIdPatient = 0;
        let mIdClient = 0;
        let list_nom = [];
        let observations_det = "";
        let discount_par = 0;

        if (formValues.id_company !== undefined && formValues.id_company !== 0) {
            mIdPatient = 0;
            mIdClient = formValues.id_company.value;
        }
        else {
            mIdClient = 0;
            mIdPatient = formValues.id_patient;
        }
        let list = null;
        let list_type_sample_temp = list_type_samples;
        let findDeterminations = list_type_sample_temp[index];
        if (findDeterminations) {
            list_type_sample_temp.map((x, ind) => {
                if (ind == index) {
                    list = findDeterminations.list_determinations;
                }
                return x;
            });
        } else {
            list = [];
        }
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });
        let price_sample = 0;
        let listTemp = await handleRequest("GET", `Profiles/FindProfileIdMicro/${id_profile},${branch},${typeCommercialLine},${mIdPatient},${mIdClient}?validation_strict=true&is_membership=false`, "Buscar perfles")
        if (listTemp !== null) {
            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let validTest = validationTest(null, id_profile, listTemp.listExams, listTemp.name);
            price_sample += parseFloat(listTemp.price.toFixed(2));
            if (validTest !== -1 && validTest !== 2) {
                sweetConfirmRepeatExam("La determinación ya existe en el tipo de muestra", validationsTest.message_validation, "warning");
                price_sample -= parseFloat(listTemp.price.toFixed(2));
                return;
            }
            else if (validTest === 2 && valid_exist === -1) {
                toggleRepeatProfile();
                setModalTitle("Determinaciones duplicadas");
                return;
            }

            let array_specimens = [];

            listTemp.exams_specimens.forEach(specimen => {
                if (!array_specimens.find(x => x.id_specimen === specimen.id_specimen_origin)) {

                    let valorCheck = true;

                    list.forEach(item => {
                        if (item.id_specimen === parseInt(specimen.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                        }
                    });

                    array_specimens.push({
                        specimen_name: specimen.name_specimen_origin,
                        id_specimen: specimen.id_specimen_origin,
                        check_specimen: valorCheck,
                    });
                }
            });
            let porcentaje_descuento = 0;
            let descuento_cifra = 0;
            if (mIdClient > 0) {
                if (listTemp.agreement != null) {
                    if (listTemp.agreement.id_agreement > 0) {
                        let listAgrrementDiscounts = arrayAgreementDiscounts;
                        let convenio = listTemp.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number;
                        let des = (listTemp.price * porcentaje_descuento) / 100;
                        descuento_cifra = !isNaN(des) ? parseFloat(des.toFixed(2)) : 0;
                        price_sample = price_sample - descuento_cifra;
                        listAgrrementDiscounts.push({
                            percentage_discount: porcentaje_descuento,
                            id_agreement: id_agreement,
                            id_agreement_test_range: id_agreement_test_range,
                            contract_number: contract_number,
                            test: list.name
                        });
                        setArrayAgreementDiscounts(listAgrrementDiscounts);
                    }
                }
            }
            var new_determination = new Boolean(false);
            if (idQuoter > 0) {
                new_determination = true;
            }
            list.push({
                id_profile: listTemp.id_profile,
                id_exam: null,
                id_price: listTemp.id_price,
                id_profile_price: listTemp.id_price,
                price: listTemp.price,
                price_discount: listTemp.price - descuento_cifra,
                name: listTemp.name,
                code: listTemp.code,
                listIndications: listTemp.listExamIndications,
                is_urgent: false,
                is_curve: listTemp.is_curve,
                curves: listTemp.listCurves,
                abbreviation: listTemp.abbreviation,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                urgent_price: 0,
                delivery_time: "",
                id_profile_curve_price: 0,
                listExams: listTemp.listExams,
                id_agreement: id_agreement,
                new_determination: new_determination,
                discount: !isNaN(porcentaje_descuento) ? parseFloat(porcentaje_descuento.toFixed(2)) : 0,
                id_quoter_determination: null,
                list_nom: list_nom,
                observations_det: observations_det,
                discount_par:discount_par,
                listExams: listTemp.listExams,
            });
            let list_type_sample_temp = list_type_samples;
            let findDeterminations = list_type_sample_temp[index];
            if (findDeterminations) {
                ////console.log("update");
                findDeterminations.list_determinations = list;
                findDeterminations.price_sample += price_sample;
                list_type_sample_temp.map((x, inde) => {
                    if (inde == index) {
                        x = findDeterminations;
                    }
                    return x;
                });
            } else {
                //console.log(type_sample_select_name);
                list_type_sample_temp.push({
                    id: type_sample_select,
                    list_determinations: list,
                    name_sample: type_sample_select_name,
                    id_type_client: 0,
                    observations: "",
                    price_sample: price_sample,
                    name_human: name_human,
                    paternal_human: paternal_human,
                    maternal_human: maternal_human,
                    cant: 1
                })
            }
            handleUpdateValues({
                ...formValues,
                list_type_samples: [...list_type_sample_temp]
            });
            setExamProfileState({});
            setId_exam_temp();
            setId_exam_temp_name();
        }
    }
    //Función para traer determinaciones como examenes

    const handleGetExam = async (id_exam, index) => {
        let mIdPatient = 0;
        let mIdClient = 0;
        let list_nom = [];
        let observations_det = "";
        let discount_par=0;

        if (formValues.id_company !== undefined && formValues.id_company !== 0) {
            mIdPatient = 0;
            mIdClient = formValues.id_company.value;
        }
        else {
            mIdClient = 0;
            mIdPatient = formValues.id_patient;
        }
        let list = null;
        let list_type_sample_temp = list_type_samples;
        let findDeterminations = list_type_sample_temp[index];
        if (findDeterminations) {
            list_type_sample_temp.map((x, ind) => {
                if (ind == index) {
                    list = findDeterminations.list_determinations;
                }
                return x;
            });
        } else {
            list = [];
        }
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });
        let price_sample = 0;
        //let id_type_sample_temp = id_type_sample;
        let alternative_method = "";

        let listTemp = await handleRequest("GET", `Exam/GetPriceFromExamMicro/${id_exam},${branch},${typeCommercialLine},${mIdPatient},${mIdClient},0,true${alternative_method}`, "Exámenes");
        if (listTemp !== null) {
            let descuentoEspecial = null;
            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;


            let validationExam = validationTest(index, id_exam);
            price_sample += parseFloat(listTemp.price.toFixed(2));
            if (validationExam !== -1 && validationExam !== 2) {
                sweetConfirmRepeatExam("La determinación ya existe en el tipo de muestra", validationsTest.message_validation, "warning");
                price_sample -= parseFloat(listTemp.price.toFixed(2));
                return;
            }

            let valorCheck = true;

            list.forEach(item => {
                if (item.id_specimen === parseInt(listTemp.id_specimen)) {
                    valorCheck = item.check_specimen;
                }
            });
            let porcentaje_descuento = 0;
            let descuento_cifra = 0;
            if (mIdClient > 0) {
                if (listTemp.agreement.id_agreement != null) {
                    if (listTemp.agreement.id_agreement > 0) {
                        let listAgrrementDiscounts = arrayAgreementDiscounts;
                        let convenio = listTemp.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number;
                        let des = (listTemp.price * porcentaje_descuento) / 100;
                        descuento_cifra = !isNaN(des) ? parseFloat(des.toFixed(2)) : 0;
                        price_sample = price_sample - descuento_cifra;
                        listAgrrementDiscounts.push({
                            percentage_discount: porcentaje_descuento,
                            id_agreement: id_agreement,
                            id_agreement_test_range: id_agreement_test_range,
                            contract_number: contract_number,
                            test: list.name
                        });
                        setArrayAgreementDiscounts(listAgrrementDiscounts);
                    }
                }
            }
            var new_determination = new Boolean(false);
            if (update_quoter == true) {
                new_determination = true;
            }
            list.push({
                id_profile: null,
                id_exam: listTemp.id_exam,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_price: listTemp.id_price,
                price: listTemp.price,
                price_discount: listTemp.price - descuento_cifra,
                specimen_name: listTemp.name_specimen,
                id_specimen: listTemp.id_specimen,
                check_specimen: valorCheck,
                listIndications: listTemp.listIndications,
                is_urgent: false,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                listExams: null,
                urgent_price: 0,
                delivery_time: listTemp.delivery_time,
                id_agreement: id_agreement,
                discount: !isNaN(porcentaje_descuento) ? parseFloat(porcentaje_descuento.toFixed(2)) : 0,
                new_determination: new_determination,
                id_quoter_determination: null,
                list_nom: list_nom,
                observations_det: observations_det,
                discount_par:discount_par,
                listExams: listTemp.listExams,
            });
            let list_type_sample_temp = list_type_samples;
            let findDeterminations = list_type_sample_temp[index];
            if (findDeterminations) {
                //console.log("update");
                findDeterminations.list_determinations = list;
                findDeterminations.price_sample = price_sample + findDeterminations.price_sample;
                list_type_sample_temp.map((x, indexx) => {
                    if (index == indexx) {
                        x = findDeterminations;
                    }
                    return x;
                });
            } else {
                list_type_sample_temp.push({
                    id: type_sample_select,
                    list_determinations: list,
                    name_sample: type_sample_select_name,
                    id_type_client: 0,
                    observations: "",
                    price_sample: price_sample,
                    name_human: name_human,
                    paternal_human: paternal_human,
                    maternal_human: maternal_human,
                    cant: 1
                })
            }
            handleUpdateValues({
                ...formValues,
                list_type_samples: [...list_type_sample_temp]
            });
            ////console.log(list_type_samples);
            setExamProfileState({});
            setId_exam_temp();
            setId_exam_temp_name();
        }
    }
    //DELETES
    const deleteSample = (index) => {
        list_type_samples.splice(index, 1); // Elimina 1 elemento a partir del índice especificado
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_samples]
        });

    }
    const deleteExamProfile = (index, id_exam, id_profile = null) => {
        let posicion = parseInt(index);
        //console.log(posicion);
        let price_temp = 0;
        let findtypesample = list_type_samples[posicion];
        if (findtypesample !== null) {
            //console.log(findtypesample);
            let findTest = findtypesample.list_determinations.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

            if (findTest !== null) {
                let newListTest = findtypesample.list_determinations.filter(item => {
                    if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                        price_temp = item.price_discount;
                        return false;
                    }
                    else {
                        return true;
                    }
                });
                let list_type_sample_temp = list_type_samples;
                let findDeterminations = list_type_sample_temp[index];
                if (findDeterminations) {
                    //console.log("update");
                    findDeterminations.list_determinations = newListTest;
                    let remove_price = findDeterminations.price_sample - price_temp;
                    findDeterminations.price_sample = parseFloat(remove_price.toFixed(2));
                    list_type_sample_temp.map(x => {
                        if (x.id == findDeterminations.id) {

                            x = findDeterminations;
                        }
                        return x;
                    })
                }
                handleUpdateValues({
                    ...formValues,
                    list_type_samples: [...list_type_sample_temp]
                });
            }
        }

    }
    const removecant = (index) => {
        let list_type_sample_temp = list_type_samples;
        let findDeterminations = list_type_sample_temp[index];
        if (findDeterminations.cant > 1) {
            if (findDeterminations) {

                findDeterminations.cant = findDeterminations.cant - 1;
                list_type_sample_temp.map(x => {
                    if (x.id == findDeterminations.id) {

                        x = findDeterminations;
                    }
                    return x;
                })
            }
            handleUpdateValues({
                ...formValues,
                list_type_samples: [...list_type_sample_temp]
            });
        }

    }
    const addcant = (index) => {
        let list_type_sample_temp = list_type_samples;
        let findDeterminations = list_type_sample_temp[index];

        if (findDeterminations) {
            findDeterminations.cant = findDeterminations.cant + 1;
            list_type_sample_temp.map(x => {
                if (x.id == findDeterminations.id) {

                    x = findDeterminations;
                }
                return x;
            })
        }
        handleUpdateValues({
            ...formValues,
            list_type_samples: [...list_type_sample_temp]
        });


    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        getTimes();
        ListTypeSamples();
        handleGetNom();


    }, []);

    //#endregion

    //#region METHODS

    const getTimes = async () => {

        let listTmp = await handleRequest("GET", "Price/GetTimes", "");

        if (listTmp !== null && listTmp.length > 0) {
            setTimes(listTmp);
        }
    }
    const changeSelectSamplings = (e, id_profileAux) => {
        let allRequisitionExams = listQuoterTest;

        let id_profile_curve_price = parseInt(e.target.value);
        let findRequestExam = allRequisitionExams.find(r => r.id_profile === parseInt(id_profileAux));

        let curva_seleccionada = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {
                let curves_aux = [];

                r.price = curva_seleccionada.price;

                for (let index = 1; index <= curva_seleccionada.number_sampling; index++) {

                    curves_aux.push({
                        ...curva_seleccionada,
                        time: "",
                        number_sampling_aux: index
                    });
                }

                r.curves_aux = curves_aux;
            }

            return r;
        });

        handleUpdateValues({
            ...formValues,
            listQuoterTest: [...nuevos]
        });
    }

    const changeValueTimeSampling = (e, id_profileAux, id_profile_curve_price, number_sampling) => {
        let allRequisitionExams = listQuoterTest;

        let tiempo_axu = e;

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {
                let curves_aux = r.curves_aux;

                let nuevas_curvas = curves_aux.map(c => {
                    if (c.number_sampling_aux === number_sampling) {
                        c.time = tiempo_axu;
                    }

                    return c;
                });

                r.curves_aux = nuevas_curvas;
            }
            return r;
        });

        handleUpdateValues({
            ...formValues,
            listQuoterTest: [...nuevos]
        });
    }
    const handleViewExamsProfile = (index, id_profile) => {
        let findSample = list_type_samples[index];
        //console.log(findSample);
        if (findSample) {
            let findExams = findSample.list_determinations.find(x => x.id_profile === parseInt(id_profile));
            setViewExamsProfile(findExams.listExams);
            toggleModalViewExams();
        }
    }

    const handleCancellQuoter = () => {
        handleUpdateValues({
            observations: "",
            listQuoterTest: [],
            name: "",
            paternal_surname: "",
            maternal_surname: "",
            arrayCurves: [],
            email: ""
        });

        setCardExam(0);
        setCardProfile(0);
        setExamProfileState({});
    }
    const newquoter = () => {
        handleUpdateValues({
            id_company: 0,
            id_patient: 0,
            id_type_client: 0,
            date_sample: "",
            is_sampling: false,
            observations: "",
            list_type_samples: [],
            listQuoterTest: [],
            date_sample_up: moment().format("D/M/YYYY hh:mm")
        });
        setEnabledatagrid(false);
        setType_sample_select();
        setDetermination(false);
        setOrigin();
        setname_human();
        setpaternal_human();
        setmaternal_human();
        setIdQuoter();
        setCodeQuoter("");
        setViewButtons(true);
        setViewButtonsCreate(true);
        setLoading(false);
        setInformationPatient({
            birthday: null,
            is_complete: false,
            phone: null,
            gender: null,
            age: null,
            email: null,
            curp: null,
            membership: null,
            name: null,
            paternal_surname: null,
            maternal_surname: null,
            cardnumber: null,
        });
        setCheckTypeClient(1);
        setId_exam_temp();
        setId_exam_temp_name();
        setInformationCompany({
            code: undefined,
            phone: undefined,
            email: undefined
        });
        setSearchSampleState([]);
        setSearchPatientState([]);
        setupdate_quoter(false);
        setIdQuoter(0);
        setCodeQuoter("");
        setUpdatedatesample(false);
        setlabelDateSample("");
    }


    const saveCreate = async () => {
        setLoading(true);
        //console.log(formValues.date_sample);
        let body = {
            id_company: formValues.id_company.value,
            id_patient: formValues.id_patient,
            date_sample: formValues.date_sample,
            is_sampling: formValues.is_sampling,
            id_branch: branch,
            observations: formValues.observations,
            list_type_samples: formValues.list_type_samples,
            roleUser:roleUser


        };

        let listTemp = await handleRequest('POST', "QuoterDeterminations", "Cotización", body);

        if (listTemp !== null) {
            if (listTemp !== null) {

                let idQuoter = listTemp.id_quoter;
                let codeQuoter = listTemp.code;
                //console.log(idQuoter);
                if (idQuoter > 0) {
                    setIdQuoter(idQuoter);
                    setCodeQuoter(codeQuoter);
                    setViewButtons(false);
                    setViewButtonsCreate(false);
                }

                setLoading(false);
                createSweet("success", "success", "Se creo correctamente la  cotización");
            }

        }
    }
    const saveUpdate = async () => {
        let id_company = 0;
        if (formValues.id_company != undefined && formValues.id_company != null) {
            id_company = formValues.id_company.value;
        }
        //console.log(formValues.date_sample);
        let body = {
            id_company: id_company,
            id_patient: formValues.id_patient,
            date_sample: formValues.date_sample_up,
            is_sampling: formValues.is_sampling,
            id_branch: branch,
            observations: formValues.observations,
            list_type_samples: formValues.list_type_samples,
            id_quoter: idQuoter,
            updatedatesample: updatedatesample,


        };

        let listTemp = await handleRequest('PUT', "QuoterDeterminations/Update", "Cotización", body);

        if (listTemp !== null) {


            setViewButtons(false);
            setViewButtonsCreate(false);


            setLoading(false);
            createSweet("success", "success", "Se modifico la  cotización");


        }
    }
    //Abrir Modal
    const handleFindQuoter = () => {
        toggleModalFindQuoter();

    }


    //pasar form de PATIENT

    const [formValuesPatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient] = useForm({
        id_gender: 0,
        id_state: 0,
        id_municipality: 0,
        id_blood_type: 0,
        id_social_gender: 0,
        id_tax_regime: 0,
        id_use_cfdi: 0,
        rfc: "",
        type_person: 1,
        code: "",
        paternal_surname: "",
        maternal_surname: "",
        name: "",
        birthday: "",
        age: "",
        curp: "",
        address: "",
        cp: "",
        colony: "",
        phone: "",
        email: "",
        who_collects_exams: "",
        membership: "",
        cardnumber: "",
        new_social_gender: "",
        scan_ine_check: false,
        setFileINE: "",
        img64File: ""
    });

    const [validationsPatient, setValidationsPatient] = useState({
        id_gender_valid: false,
        paternal_surname_valid: false,
        maternal_surname_valid: true,
        name_valid: false,
        phone_patient_valid: false,
        email_patient_valid: false,
    });

    const validationsPatientForm = () => {
        let _statusValidation = false;
        let _newValidations = {};

        //console.log(formValuesPatient)
        if (formValuesPatient.name.length < 2) {
            _newValidations = {
                ..._newValidations,
                name_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                name_valid: false
            };
        }

        if (formValuesPatient.paternal_surname.length < 2) {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: false
            };
        }

        if (formValuesPatient.phone === "") {

            _newValidations = {
                ..._newValidations,
                phone_valid: true
            }

            _statusValidation = true;

        } else {
            _newValidations = {
                ..._newValidations,
                phone_valid: false
            }
        }
        if (formValuesPatient.email === "") {
            _newValidations = {
                ..._newValidations,
                phone_valid: true
            }
            _statusValidation = true;

        } else {
            _newValidations = {
                ..._newValidations,
                phone_valid: false
            }
        }

        setValidationsPatient(_newValidations);

        return _statusValidation;
    }

    const handleCreatePatient = async () => {
        setLoading(true);


            setValidationsPatient({
                id_gender_valid: false,
                paternal_surname_valid: false,
                maternal_surname_valid: true,
                name_valid: false,
                phone_patient_valid: false,
                email_patient_valid: false,
            });
           //console.log('Created')

        setMethod("create");
        setModalTitle("Crear Nuevo paciente");
        togglePatient();

    }

    const savePatient = async () => {
        if (validationsPatientForm()) {
            //console.log("YA ENTRÉ")
            setLoading(false);
            return; 
        }
        let _formData = {

            id_gender: formValuesPatient.id_gender,
            id_state_patient: formValuesPatient.id_state.value === undefined ? null : formValuesPatient.id_state.value,
            id_municipality_patient: formValuesPatient.id_municipality.value === undefined ? null : formValuesPatient.id_municipality.value,
            id_social_gender_patient: formValuesPatient.id_social_gender.value === undefined ? null : formValuesPatient.id_social_gender.value,
            id_blood_type: formValuesPatient.id_blood_type.value === undefined ? null : formValuesPatient.id_blood_type.value,
            id_tax_regime: formValuesPatient.id_tax_regime.value === undefined ? null : formValuesPatient.id_tax_regime.value,
            id_use_cfdi: formValuesPatient.id_use_cfdi.value === undefined ? null : formValuesPatient.id_use_cfdi.value,
            paternal_surname: formValuesPatient.paternal_surname,
            maternal_surname: formValuesPatient.maternal_surname,
            name: formValuesPatient.name,
            birthday: formValuesPatient.birthday || "01/01/2000",
            age: formValuesPatient.age,
            curp: formValuesPatient.curp || "CURPDEFAULT",
            address_patient: formValuesPatient.address || "DEFAULT",
            cp_patient: formValuesPatient.cp_patient || "00000",
            colony_patient: formValuesPatient.colony || "DEFAULT",
            phone: formValuesPatient.phone,
            email_patient: formValuesPatient.email,
            membership: formValuesPatient.membership || "0",
            cardnumber: formValuesPatient.cardnumber || "DEFAULT",
            rfc: formValuesPatient.rfc || "DEFAULT",
            type_person: formValuesPatient.type_person || "0",
            new_social_gender: formValuesPatient.new_social_gender,
            scan_ine_check: formValuesPatient.scan_ine_check || false,
            setFileINE: formValuesPatient.setFileINE || "DEFAULT"
        };

        //console.log('Llegando al _formData')
        //console.log('_formData',_formData)

        let _list = await _handleRequest("POST", "Patient", "Crear paciente", _formData);

        if (_list !== null) {

            createSweet("create", "success", "Exito!", "Paciente creado");
            setLoading(false);

            handleUpdateValues({
                id_gender: 0,
                id_state: 0,
                id_municipality: 0,
                id_blood_type: 0,
                id_social_gender: 0,
                id_tax_regime: 0,
                id_use_cfdi: 0,
                rfc: "DEFAULT",
                type_person: 1,
                code: "DEFAULT",
                paternal_surname: "",
                maternal_surname: "",
                name: "",
                birthday: "01/01/2000",
                age: "",
                curp: "CURPDEFAULT",
                address: "DEFAULT",
                cp: "0",
                colony: "DEFAULT",
                phone: "",
                email: "",
                membership: "0",
            });

        togglePatient();
        setLoading(false);
        window.location.reload();
        }
        // ----------
        setLoading(false);

    }




    //#Validación add new type_sample

    const printPdf = async (idQuoter = 0) => {
        let data = {
            id_quoter: idQuoter,
            print_result: true,
            send_email: false,
            email_client: ""
        };
        //console.log(data);
        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/QuoterDeterminations/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var iframe = document.createElement('iframe');
                iframe.className = 'pdfIframe'
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                        URL.revokeObjectURL(url)
                    }, 1);
                };
                iframe.src = url;
            })
    }

    const sentPdf = async (idQuoter = 0) => {
        let data = {
            id_quoter: idQuoter,
            print_result: false,
            send_email: true,
            email_client: formValues.email
        };

        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/QuoterDeterminations/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status === 200) {
                    createSweet("success", "success", "Correo enviado correctamente");
                }
                else {
                    createSweet("warning", "warning", "Hubo un error en el servidor");
                }
            })
    }

    const downdloadpdf = async (idQuoterr = 0) => {

        let data = {
            "id_quoter": idQuoter,
            "print_result": true,
            "send_email": false,
            "email_client": ""
        }


        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/QuoterDeterminations/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {

                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Cotizacion.pdf")
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    //#endregion

    useMemo(() => {
        let total = 0.0;
        let subtotal = 0.0;
        let total_iva = 0.0;

        let total_exams = 0;
        let total_profiles = 0;
        let with_discount = 0.0;
        let descuento = 0.0;
        let list_samples_tem = formValues.list_type_samples;
        if(list_samples_tem!=null){
           
            if (list_samples_tem.length > 0) {
                setcheck_cotizar(true);

                list_samples_tem.forEach(item => {
                    console.log("PRUEBAS UNITARIAS");
                    if (item.list_determinations != null) {
                        //console.log(item.list_determinations);
                        if (item.list_determinations.length > 0) {
                            item.list_determinations.forEach(data => {
//console.log("Use Memo");
                                if (data.id_exam !== null) {
                                    total_exams++;
                                }

                                if (data.id_profile !== null) {
                                    total_profiles++;
                                }

                                switch (data.config_iva) {
                                    case true:

                                        let price = 0.0;
                                        let value_urgent = parseInt(percentage_urgent_work_order);
                                        let price_disc_ci = data.price_discount;
                                        //REMOVEMOS EL IVA DEL PRECIO DE LA PRUEBA
                                        let value_iva = 1 + (data.value_iva / 100);

                                        price = (data.price / value_iva);

                                        if (data.is_urgent) {
                                            let with_urgent = (price * value_urgent) / 100;
                                            data.price_urgent = !isNaN(with_urgent) ? parseFloat(with_urgent.toFixed(2)) : 0;

                                            price = price + with_urgent;
                                        }
                                        else {
                                            data.price_urgent = 0;
                                        }

                                        // let with_iva = (price * data.value_iva) / 100;

                                        // subtotal += price;
                                        // total_iva += with_iva;
                                        if (data.discount_par > 0) {
                                            let price_samplings=price * item.cant;
                                            with_discount = price_samplings *(data.discount_par / 100);
                                            let price_discount_part=price_samplings-with_discount;
                                            let with_iva = (price_discount_part  * data.value_iva) / 100;
                                          
                                            subtotal += price_samplings;
                                            descuento += with_discount;
                                            total_iva += with_iva;
                                            
                                        }
                                        if (data.discount > 0) {
                                            with_discount = (price * item.cant)*(data.discount / 100);
                                            let with_iva = ((price_disc_ci * item.cant) * data.value_iva) / 100;
                                            subtotal += price * item.cant;
                                            descuento += with_discount;
                                            total_iva += with_iva;

                                        }else if(data.discount!=null && data.discount!=0){
                                            with_discount = (price * item.cant)*(Math.abs(data.discount / 100));
                                            let with_iva = ((price_disc_ci * item.cant) * data.value_iva) / 100;
                                            subtotal += price * item.cant;
                                            descuento += with_discount;
                                            total_iva += with_iva; 
                                        }  
                                        if (data.discount_par==0 && data.discount == 0) {
                                            let with_iva = ((price_disc_ci * item.cant) * data.value_iva) / 100;
                                            subtotal += price * item.cant;
                                            total_iva += with_iva;
                                        }  
                                       
                                        // 


                                        break;

                                    case false:

                                        let simple_price = data.price;
                                        let price_disc = data.price_discount;
                                        let value_urgent_2 = parseInt(percentage_urgent_work_order);

                                        if (data.is_urgent) {
                                            let urgent = ((simple_price * value_urgent_2) / 100);
                                            simple_price = simple_price + urgent;

                                            data.price_urgent = !isNaN(urgent) ? parseFloat(urgent.toFixed(2)) : 0;
                                        }
                                        else {
                                            data.price_urgent = 0;
                                        }
                                        if (data.discount_par > 0) {
                                            let price_samplings=simple_price * item.cant;
                                            with_discount = price_samplings *(data.discount_par / 100);
                                          
                                            let price_discount_part=price_samplings-with_discount;
                                           
                                            let iva = ((price_discount_part) * data.value_iva) / 100;
                                            
                                            descuento += with_discount;
                                            subtotal += price_samplings;
                                            total_iva += iva;
                                        }
                                        if (data.discount > 0) {

                                            let iva = ((price_disc * item.cant) * data.value_iva) / 100;
                                           
                                            with_discount = ((simple_price*item.cant) * (data.discount / 100));
                                            descuento += with_discount;
                                            subtotal += simple_price * item.cant;
                                            total_iva += iva;
                                        }
                                        else if(data.discount!=null && data.discount!=0){
                                            let iva = ((price_disc * item.cant) * data.value_iva) / 100;
                                           
                                            with_discount = ((simple_price*item.cant) * (data.discount / 100));
                                           descuento += with_discount;
                                            subtotal += simple_price * item.cant;
                                            total_iva += iva;
                                        } 
                                        if (data.discount_par==0 && data.discount == 0) {
                                            let iva = ((simple_price * item.cant) * data.value_iva) / 100;
                                            //console.log("iva2",iva);
                                            subtotal += simple_price * item.cant;
                                            total_iva += iva;
                                        }
                                        
                                        // let iva = (simple_price * data.value_iva) / 100;
                                        // subtotal += simple_price;
                                        // total_iva += iva;

                                        break;
                                }

                            });
                        }
                    }

                })
            } else {
                setcheck_cotizar(false);
            }
        }


        //total = subtotal + total_iva;
        total = (Math.round((subtotal) + total_iva)-(descuento));
        descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
        subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
        total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
        total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;

        setSubTotalW(subtotal);
        setTotalTaxesW(total_iva);
        setTotalW(total);
        setCardExam(total_exams);
        setCardProfile(total_profiles);
        setTotalDiscountW(descuento);
    }, [formValues], check_cotizar);
    //#endregion

    //#region ALL VALIDATIONS
    const validationTest = (index, id_exam, id_profile = null, listExams = [], name_profile = "") => {
        let statusValidation = -1;
        let newValidations = {};
        let valid_exist_test = {
            name_profile: "",
            repeat_profiles: [],
            repeat_exams: []
        };
        if (formValues.list_type_samples.length > 0) {

            let findTypeSample = formValues.list_type_samples[index];
            if (findTypeSample) {
                if (findTypeSample.list_determinations != null) {
                    if (findTypeSample.list_determinations.length > 0) {
                        let findTest = findTypeSample.list_determinations.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

                        if (findTest) {
                            newValidations = {
                                ...newValidations,
                                valid_repeat_test: true,
                                message_validation: (id_exam !== null ? `El examen: ${findTest.name}, ya existe` : `El perfil: ${findTest.name}, ya existe`)
                            };

                            statusValidation = 0;
                        }
                        else {
                            if (id_exam !== null) {
                                let listProfiles = findTypeSample.list_determinations.filter(x => x.id_profile !== null);

                                listProfiles.forEach(p => {
                                    if (p.listExams.find(x => x.id_exam === parseInt(id_exam))) {
                                        newValidations = {
                                            message_validation: "El examen seleccionado ya existe en el perfil: " + p.name
                                        };
                                        statusValidation = 1;

                                        return true;
                                    }
                                });
                            }
                            else if (id_exam === null && id_profile !== null) {
                                let filterProfiles = findTypeSample.list_determinations.filter(x => x.id_profile !== null);

                                filterProfiles.forEach(p => {
                                    let examsRepeat = [];
                                    let examsNotRepeat = [];

                                    p.listExams.forEach(e => {
                                        if (listExams.find(x => x.id_exam === e.id_exam)) {
                                            examsRepeat.push(e);
                                        }
                                    });

                                    let notExist = [];
                                    listExams.forEach(x => {
                                        if (!examsRepeat.find(r => r.id_exam === x.id_exam)) {
                                            notExist.push(x);
                                        }
                                    });

                                    examsNotRepeat = notExist;

                                    if (examsRepeat.length > 0) {
                                        valid_exist_test.repeat_profiles.push({
                                            name_profile: p.name,
                                            listExamsRepeat: examsRepeat,
                                            listExamsNotRepeat: examsNotRepeat
                                        });
                                    }

                                    if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                                        valid_exist_test.name_profile = name_profile;
                                        newValidations = {
                                            message_validation: "Hay exámenes o perfiles repetidos"
                                        };

                                        statusValidation = 2;
                                    }
                                });

                                if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                                    valid_exist_test.name_profile = name_profile;
                                    newValidations = {
                                        message_validation: "Hay exámenes o perfiles repetidos"
                                    };

                                    statusValidation = 2;
                                }

                                let listExamsW = findTypeSample.list_determinations.filter(x => x.id_exam !== null);

                                listExamsW.forEach(e => {
                                    if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {
                                        newValidations = {
                                            message_validation: `Tienes exámenes duplicados: ${e.name}, al seleccionar el perfil`
                                        };

                                        statusValidation = 3;

                                        return true;
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }
        setValidationsTest(newValidations);
        setValidationsRepeat({
            ...validationsRepeat,
            name_profile: valid_exist_test.name_profile,
            repeat_profiles: valid_exist_test.repeat_profiles,
            repeat_exams: valid_exist_test.repeat_exams
        });

        return statusValidation;
    }
    //#endregion

    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    //new methods
    const handleChangeTypeClient = (typeClient, validClient = -1) => {

        if (validClient === -1) {
            sweetConfirmationChangeClient("Al cambiar el tipo de ingreso, perderas los datos ingresados", "Estas seguro de realizar esta acción", "warning", typeClient, true, handleChangeTypeClient)
            return;
        }

        if (!validClient) {
            return;
        }
        else {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
            if (typeClient == 1) {
                handleUpdateValues({
                    ...formValues,
                    id_company: 0,
                    id_patient: 0,
                    id_type_client: 0,
                    date_sample: "",
                    is_sampling: false,
                    observations: "",
                    list_type_samples: [],
                });
                setType_sample_select();
                setId_exam_temp();
                setType_sample_select_name();
                setId_exam_temp_name();
                setInformationPatient({
                    birthday: null,
                    is_complete: false,
                    phone: null,
                    gender: null,
                    age: null,
                    email: null,
                    email_patient: null,
                    curp: null,
                    membership: null,
                    name: null,
                    paternal_surname: null,
                    maternal_surname: null,
                    cardnumber: null
                });

                setSearchPatientState([]);
            } else {
                handleUpdateValues({
                    ...formValues,
                    id_company: 0,
                    id_patient: 0,
                    id_type_client: 0,
                    date_sample: "",
                    is_sampling: false,
                    observations: "",
                    list_type_samples: [],
                });
                setType_sample_select();
                setId_exam_temp();
                setType_sample_select_name();
                setId_exam_temp_name();
                setInformationCompany({
                    code: undefined,
                    phone: undefined,
                    email: undefined
                });
            }


        }


    }
    const handleGetClients = async (typeClient) => {
        let listTmp = await handleRequest("GET", `Company/ListTypeCompaniesMicro/${typeClient}?is_micro=true`, '');

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    value: obj.id_company,
                    label: obj.code + ' ' + obj.name
                });
            });

            setListCompanies(list);
        }
    }
    const handleChangeCompany = (e, nameInput) => {
        setviewPatient(false);
        //console.log(e);
        handleSelectValuesWorkOrder(e, nameInput);

        if (e.value !== undefined) {
            let id_company = e.value;

            if (id_company > 0) {
                setSearchSampleState([]);
                handleGetInformationCompany(id_company);

            }
        }
    }
    const handleGetInformationCompany = async (id_company) => {
        let requestOptions = {
            method: 'GET'
        };

        const response = await sendRequest(requestOptions, `Company/GetInformationCompany/${id_company}`);

        if (response.code === 200) {

            if (response.data.id_company !== undefined) {
                setInformationCompany({
                    code: response.data.code,
                    phone: response.data.phone,
                    email: response.data.contact_email
                });
            }
            else {
                setInformationCompany({
                    code: "",
                    phone: "",
                    email: ""
                });
            }
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleGetInformationCompany);
            setLoading(false);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setLoading(false);
        }
    }
    //PACIENTE
    const handleChangePatient = (e) => {
        setviewCompany(false);
        handleInputChange(e);
        setSearchSampleState([]);
        let patient = e.target.value;

        if (patient !== 0 && patient !== null && patient !== '') {
            handleGetInformationPatient(patient);
            setviewPatient(true);

        }
        else {
            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
                cardnumber: null
            });

            handleUpdateValues({
                ...formValues,
                id_patient: 0
            });

            setviewPatient(false);

        }
    }
    const handleGetInformationPatient = async (id_patient, unique_uuid = 0) => {

        let alternative_methods = "";
        if (unique_uuid != 0) {
            alternative_methods = `?unique_uuid=${unique_uuid}`;
        }

        let listTmp = await handleRequest("GET", `Patient/FindPatientId/${id_patient}${alternative_methods}`, 'Paciente');
        if (listTmp != null) {
            let tmpPatient = {
                birthday: listTmp.birthday,
                is_complete: listTmp.is_complete,
                phone: listTmp.phone,
                gender: listTmp.gender,
                age: listTmp.age,
                email: listTmp.email,
                email_patient: listTmp.email,
                curp: listTmp.curp,
                membership: listTmp.membership,
                name: listTmp.name,
                paternal_surname: listTmp.paternal_surname,
                maternal_surname: listTmp.maternal_surname,
                cardnumber: listTmp.unique_uuid,
                id_social_gender_patient: listTmp.id_social_gender_patient
            };

            setInformationPatient(tmpPatient);

            // if (listTmp.membership !== null) {
            //     setIs_membership(true);
            // }

            if (unique_uuid !== 0) {
                setSearchPatientState({
                    value: listTmp.id_patient,
                    label: listTmp.name_patient + " - " + listTmp.curp
                });

                handleUpdateValues({
                    ...formValues,
                    id_patient: listTmp.id_patient
                });
                setviewPatient(true);
            }
        }
        else {
            setSearchPatientState([]);

            handleUpdateValues({
                ...formValues,
                id_patient: 0
            });

            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                cardnumber: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
            });
        }
    }

    //created patient POST

    const saveCreatePatient = async (e) => {

        e.preventDefault();
        setLoadingPatient(true);

        if (validationsPatientForm()) {
            setLoadingPatient(false);

            return;
        }

        let _arrBirthday = formValuesPatient.birthday.split('/');

        let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

        let mData = {
            id_gender: formValuesPatient.id_gender,
            id_state_patient: formValuesPatient.id_state.value === undefined ? null : formValuesPatient.id_state.value,
            id_municipality_patient: formValuesPatient.id_municipality.value === undefined ? null : formValuesPatient.id_municipality.value,
            id_social_gender_patient: formValuesPatient.id_social_gender.value === undefined ? null : formValuesPatient.id_social_gender.value,
            id_blood_type: formValuesPatient.id_blood_type.value === undefined ? null : formValuesPatient.id_blood_type.value,
            id_tax_regime: formValuesPatient.id_tax_regime.value === undefined ? null : formValuesPatient.id_tax_regime.value,
            id_use_cfdi: formValuesPatient.id_use_cfdi.value === undefined ? null : formValuesPatient.id_use_cfdi.value,
            paternal_surname: formValuesPatient.paternal_surname,
            maternal_surname: formValuesPatient.maternal_surname,
            name: formValuesPatient.name,
            birthday: _stringBirthday,
            age: formValuesPatient.age,
            curp: formValuesPatient.curp,
            address_patient: formValuesPatient.address,
            cp_patient: formValuesPatient.cp,
            colony_patient: formValuesPatient.colony,
            phone: formValuesPatient.phone,
            email_patient: formValuesPatient.email,
            membership: formValuesPatient.membership,
            cardnumber: formValuesPatient.cardnumber,
            rfc: formValuesPatient.rfc,
            type_person: formValuesPatient.type_person,
            new_social_gender: formValuesPatient.new_social_gender,
            scan_ine_check: formValuesPatient.scan_ine_check,
            setFileINE: formValuesPatient.setFileINE
        };
        ////console.log(mData);
        let listTmp = await handleRequest("POST", `Patient`, 'Paciente', mData);

        if (listTmp !== null) {
            setSearchPatientState({
                value: listTmp.id_patient,
                label: listTmp.name_patient
            });

            await handleGetInformationPatient(listTmp.id_patient);
            setLoadingPatient(false);

            createSweet("create", "success", "Paciente creado");
            togglePatient();
        }
        else {
            setLoading(false);
        }

        setLoading(false);
    }

    const saveUpdatePatient = async (e) => {
        e.preventDefault();
        //debugger;
        setLoadingPatient(true);

        var formData = new FormData();
        formData.append('id_patient', id_patient);
        formData.append('id_gender', formValuesPatient.id_gender);
        // formData.append('id_social_gender_patient', (formValuesPatient.id_social_gender === 0 || formValuesPatient.id_social_gender.value === undefined) ? 0 : formValuesPatient.id_social_gender.value);
        formData.append('paternal_surname', formValuesPatient.paternal_surname);
        formData.append('maternal_surname', (formValuesPatient.maternal_surname !== "" && formValuesPatient.maternal_surname !== null ? formValuesPatient.maternal_surname : null));
        formData.append('name', formValuesPatient.name);
        //formData.append('age', formValuesPatient.age);
        formData.append('curp', formValuesPatient.curp);
        formData.append('phone', formValuesPatient.phone);
        formData.append('email_patient', formValuesPatient.email.trim());
        //  formData.append('new_social_gender', formValuesPatient.new_social_gender);
        //  formData.append('scan_ine_check', formValuesPatient.scan_ine_check);
        //  formData.append('setFileINE', formValuesPatient.setFileINE);
        formData.append('cp', formValuesPatient.cp);
        // formData.append('id_blood_type', formValuesPatient.id_blood_type.value);
        formData.append('colony', formValuesPatient.colony);
        formData.append('address', formValuesPatient.address);
        // formData.append('id_state_patient', formValuesPatient.id_state.value);
        //   formData.append('id_municipality_patient', formValuesPatient.id_municipality.value);

        let name_patient = `${formValuesPatient.name} ${formValuesPatient.paternal_surname} ${formValuesPatient.maternal_surname} - ${formValuesPatient.curp}`;

        const listTmp = await handleRequest("PUT", `Patient/UpdateShort`, 'Paciente', formData, true);

        if (listTmp !== null) {
            setInformationPatient({
                value: id_patient,
                label: name_patient
            });

            await handleGetInformationPatient(id_patient);

            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "info", "Paciente actualizado");
        }
        else {
            setLoading(false);
        }

        setLoading(false);
    }


    //MUESTRA
    //#region SAMPLE MIC
    const [formValuesSampleMic, handleInputChangeSampleMic, resetSampleMic, handleUpdateValuesSampleMic] = useForm({
        name_sample_mic: ""
    });

    const { name_sample_mic } = formValuesSampleMic;

    const handleCreateSampleMic = () => {
        handleUpdateValuesSampleMic({
            name_sample_mic: ""
        });

        setMethodSampleMic("create");
        setModalTitle("Registrar nueva muestra");
        toggleSampleMic();
    }

    const saveCreateSampleMic = async (e) => {
        e.preventDefault();

        setLoading(true);

        let data = {
            name: formValuesSampleMic.name_sample_mic
        };

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(data)
        };

        const response = await sendRequest(requestOptions, "TypeSamples");

        if (response.code === 200) {

            setSearchSampleState({
                value: response.data.id_type_sample,
                label: response.data.name
            });

            handleUpdateValues({
                ...formValues,
                id_type_sample: response.data.id_mic_type_sample
            });

            createSweet("create", "success", "Tipo de muestra creada");
            setLoading(false);
            toggleSampleMic();
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, saveCreateSampleMic);
            setLoading();
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Ingreso", response.data.msg);
            setLoading(false);
        }
    }
    const ListTypeSamples = async () => {
        let listTmp = await handleRequest("GET", "TypeSamples/List", "Lista");

        if (listTmp !== null && listTmp.length > 0) {
            setListsamples(listTmp);
        }
    }


    // ----------PATIENT----------

    // const savePatient = async () => {

    //     setLoading(true);

    //     if (validationsPatientForm()) {
    //         setLoading(false);

    //         return;
    //     }

    //     let _arrBirthday = formValues.birthday.split('/');

    //     let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

    //     let _formData = {
    //         id_gender: formValues.id_gender,
    //         id_state_patient: formValues.id_state.value === undefined ? null : formValues.id_state.value,
    //         id_municipality_patient: formValues.id_municipality.value === undefined ? null : formValues.id_municipality.value,
    //         id_social_gender_patient: formValues.id_social_gender.value === undefined ? null : formValues.id_social_gender.value,
    //         id_blood_type: formValues.id_blood_type.value === undefined ? null : formValues.id_blood_type.value,
    //         id_tax_regime: formValues.id_tax_regime.value === undefined ? null : formValues.id_tax_regime.value,
    //         id_use_cfdi: formValues.id_use_cfdi.value === undefined ? null : formValues.id_use_cfdi.value,
    //         paternal_surname: formValues.paternal_surname,
    //         maternal_surname: formValues.maternal_surname,
    //         name: formValues.name,
    //         birthday: _stringBirthday,
    //         age: formValues,
    //         curp: formValues.curp,
    //         address_patient: formValues.address,
    //         cp_patient: formValues.cp,
    //         colony_patient: formValues.colony,
    //         phone: formValues.phone,
    //         email_patient: formValues.email,
    //         membership: formValues.membership,
    //         cardnumber: formValues.cardnumber,
    //         rfc: formValues.rfc,
    //         type_person: formValues.type_person,
    //         new_social_gender: formValues.new_social_gender
    //     };

    //     let _list = await _handleRequest("POST", "Patient", "Crear paciente", _formData);

    //     if (_list !== null) {

    //         createSweet("create", "success", "Exito!", "Paciente creado");
    //         setLoading(false);

    //         handleUpdateValues({
    //             id_gender: 0,
    //             id_state: 0,
    //             id_municipality: 0,
    //             id_blood_type: 0,
    //             id_social_gender: 0,
    //             id_tax_regime: 0,
    //             id_use_cfdi: 0,
    //             rfc: "",
    //             type_person: 1,
    //             code: "",
    //             paternal_surname: "",
    //             maternal_surname: "",
    //             name: "",
    //             birthday: "",
    //             age: "",
    //             curp: "",
    //             address: "",
    //             cp: "0",
    //             colony: "",
    //             phone: "",
    //             email: "",
    //             membership: "",
    //         });

    //         togglePatient();
    //     }
    // }

    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un problema al intentar la petición");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    // ---------------------------
    //#endregion
    //end

    return {
        //MODALS
        method, modalTitle, modal, modalRepeatProfile, modalViewExamsP, modalPatient,
        toggle, toggleModalViewExams, toggleRepeatProfile,

        //QUOTER VALUES
        observations, listQuoterTest,
        handleInputChange,
        cardExam, cardProfile, totalW, subTotalW, totalTaxesW,
        examProfileState, viewExamsProfile, times,
        viewButtons, viewButtonsCreate, validationsRepeat,


        //QUOTER METHODS
        handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
        deleteExamProfile,
        handleViewExamsProfile, handleCancellQuoter, saveCreate,
        printPdf, sentPdf, downdloadpdf,
        //new const
        checkTypeClient, handleChangeTypeClient, id_company, listCompanies, handleChangeCompany,
        informationCompany,
        //NEW patient
        id_patient, handleChangePatient, informationPatient, searchPatientState, viewPatient,
        handleCreateSampleMic,
        //sample
        name_sample_mic, methodSampleMic, viewCompany, searchSampleState,
        enabledatagrid,
        enableDetermination, handleSelectDetermination, type_sample_select, id_exam_temp,
        AddNewDetermination,
        type_sample_select_name, id_exam_temp_name, list_type_samples,
        deleteSample, check_cotizar, date_sample, is_sampling, arrayAgreementDiscounts,
        totalDiscountW, origin, name_human, paternal_human, maternal_human, handleInputChangeQ,
        newquoter, handlePickerValues, removecant, addcant, toggleModalFindQuoter,
        handleFindQuoter, modalFindQuoter,
        //QUOTER FIND
        id_quoter_find, findQuoter, handleInputChangeFind, saveUpdate, update_quoter, labelDateSample,
        habilitar_picket, updatedatesample,
        //NORMAS
        listNom, changenom, changeobservacionesinput, CodeQuoter, muestreo,
        //TIPO DE MUESTRA
        AddNewSample,
        //MODAL ABI
        handleCreatePatient, togglePatient, validationsPatient, methodPatient, saveCreatePatient,
        saveUpdatePatient, savePatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient,
        changediscount_part
    }
}

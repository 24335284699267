import { Select } from '@mui/material';
import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader,Col, Form, Row, Label, Input, FormFeedback, FormGroup } from 'reactstrap';

export const ModalUpdateMethod = (props) => {

    const {
        modalPreviewUpdate,toggleModalUpdate,modalTitle,
        user_method, user_password_method, id_pay_method_method,handleInputChangeMethod,
        saveUpdateMethod,loading,listPayMethod
    } = props;


    
    
    
    return (
        <Modal isOpen={modalPreviewUpdate} toggle={toggleModalUpdate} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleModalUpdate} className="bg-primary">
            {modalTitle}
            </ModalHeader>
            <Form className="form theme-form" role="form">
                <ModalBody>                
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Usuario: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="text"
                                    name="user_method"
                                    value={user_method}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChangeMethod}
                                    className="form-control form-control-sm input-air-primary"

                                />
                                <FormFeedback>Escribe el usuario</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Contraseña: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="password"
                                    name="user_password_method"
                                    value={user_password_method}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChangeMethod}
                                    className="form-control form-control-sm input-air-primary"

                                />
                                <FormFeedback>Escribe la contraseña</FormFeedback>
                            </FormGroup>
                            
                        </Col>
                        <Col sm="12">
                        <Label className="col-form-label">Metódo de Pago: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input type="select" name='id_pay_method_method' className='form-control form-control-sm input-air-primary p-1'  onChange={handleInputChangeMethod} value={id_pay_method_method} >
                            <option value={0}>Selecciona una opción</option>
                                {
                                    listPayMethod.map(_pMethod => {
                                        if (_pMethod.id_pay_method == 10) {
                                            return;                                               
                                        }                                                                                         
                                     return (
                                            <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                            )                                                                                       
                                         })
                                        }
                        </Input>                       
                        </Col>                      
                    </Row>                
                </ModalBody>
            </Form>
            <ModalFooter>
            <Button outline color="danger" type="button" onClick={toggleModalUpdate}>Cancelar</Button>

                      <Button
                            size="sm"
                            outline color="primary"
                            type="submit"
                            onClick={saveUpdateMethod}
                            className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            Autorizar
                    </Button>
            </ModalFooter>
        </Modal>
    )
}
